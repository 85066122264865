import React from "react";
import { Alert, Badge } from "reactstrap";

const styleAlertItem = {
  fontSize: "0.9em",
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: "15px",
  paddingRight: "15px"
}

export class ApplicationItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      process: null,
      certValid: this.props.certName ? this.props.certValid : null,
      certName: this.props.certName ? this.props.certName : null
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.process !== state.process) {
      if (props.process === "<*>") {
        state.process = "Access for any signed application";
        state.certValid = true;
        state.certName = "All signed applications";
        return state;
      }

      if (props.process === "*") {
        state.process = "Access for all applications";
        state.certValid = false;
        state.certName = "Not applicable";
        return state;
      }

      if (props.process === "-") {
        state.process = "Denied for all applications";
        state.certValid = false;
        state.certName = "Not applicable";
        return state;
      }

      if (props.process === "^") {
        state.process = "Any application inside the rule folder(s)";
        state.certValid = false;
        state.certName = "Not applicable";
        return state;
      }

      if (props.process === "^*") {
        state.process = "Any application inside the rule folder(s) or child folder(s)";
        state.certValid = false;
        state.certName = "Not applicable";
        return state;
      }

      if (props.process.indexOf("<") !== -1 && props.process.indexOf(">") !== -1) {
        state.process = "Applications signed by " + props.process.replace("<", "").replace(">", "");
        state.certValid = true;
        state.certName = props.process.replace("<", "").replace(">", "");
        return state;
      }

      state.process = props.process;
      state.certValid = null;
      state.certName = null;
      return state;
    }
    return null;
  }

  getFileDetails = () => {
    window.roFolderWall.GetFileDetails(this.state.process, (valid, fileDetails) => {
      this.setState({
        certValid: valid,
        certName: fileDetails.certName.value
      })
    }, (error) => {
      console.log(error)
    });
  }

  render = () => {
    if (this.state.certValid != null) {
      return (
        <Alert style={styleAlertItem} color={this.props.color} isOpen={this.props.isOpen} toggle={this.props.onDelete}>
          {this.state.process}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Badge color={this.state.certValid ? "success" : this.state.certName === "Not applicable" ? "secondary" : "danger"} style={{ width: "250px" }}>
            <span className={this.state.certValid ? "text-dark" : ""}>
              {this.state.certName ? this.state.certName : "No certificate"}
            </span>
          </Badge>
        </Alert>
      );
    } else {
      this.getFileDetails();
      return (
        <Alert style={styleAlertItem} color={this.props.color} isOpen={this.props.isOpen} toggle={this.props.onDelete}>{this.state.process}</Alert>
      );
    }
  }
}