import React from "react";
import { Button, Card, CardHeader, CardFooter, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { decodeRule, encodeRule, createGuid } from "config/utilities.js";
import { RuleText } from "components/home/rules/rule-text.jsx";
import Switch, { Case } from "react-switch-case";
import { Link } from "react-router-dom";
import { EditRuleName } from "components/common/edit-rule-name.jsx"
import { EditRuleAccess } from "components/common/edit-rule-access.jsx"
import { EditRuleApplication } from "components/common/edit-rule-application.jsx"
import { EditRuleFolder } from "components/common/edit-rule-folder.jsx"
import { Consts } from "config/consts";

export class DriveRulesManage extends React.Component {
  constructor(props) {
    super(props);

    // create obj array
    let rules = [];
    this.props.drive.rules.forEach((rule) => {
      rules.push(decodeRule(rule));
    })

    // set state
    this.state = {
      drive: { ...this.props.drive},
      rules: rules,
      editRule: null,
      editValue: null,
      editIndex: -1,
      modified: false,
      tested: false,
      testingMode: false,
      testProcesses: [],
      testFolders: [],
      testProcess: "",
      testFolder: "",
      appReloadContext: createGuid(),
      viewRunningProcesses: false
    }
  }

  getAllProcessesFromRules = () => {
    let rs = this.state.rules;
    let ps = [];
    rs.forEach((rule) => {
      rule.processes.forEach((process) => {
        let dup = false;
        ps.forEach((p) => {
          if (p === process || process === "*")
            dup = true;
        })
        if (!dup) {
          let paths = process.split("\\");
          ps.push(paths[paths.length - 1]);
        }
      });
    })
    ps.push("bad_process.exe");
    return ps;
  }

  getAllFoldersFromRules = () => {
    let rs = this.state.rules;
    let fs = [];
    rs.forEach((rule) => {
      rule.folders.forEach((folder) => {
        let dup = false;
        fs.forEach((f) => {
          if (f === folder || folder === "*")
            dup = true;
        })
        if (!dup)
          fs.push(folder);
      });
    })
    return fs;
  }

  arrayToSelectItems = (arr) => {
    let r = [];
    arr.forEach((a) => {
      r.push({ label: a, value: a });
    })
    return r;
  }

  closeDialog = (updatedRules) => {
    if (this.props.onClose) {
      if (updatedRules && this.state.modified) {
        // update drive (as we are passing drive back)
        let drive = this.state.drive;
        let rules = [];
        updatedRules.forEach((rule) => {
          rules.push(encodeRule(rule));
        });
        drive.rules = rules;
        this.props.onClose(drive);
      } else {
        this.props.onClose(null);
      }
    }
  }

  handleRuleLink = (e, rule) => {
    if (this.props.onClick) {
      var shouldProceed = this.props.onClick(e);
      if (shouldProceed === false) return;
    }

    // ignore canceled events, modified clicks, and right clicks.
    if (e.defaultPrevented) {
      return;
    }

    if (e.metaKey || e.ctrlKey || e.shiftKey) {
      return;
    }

    if (e.button !== 0) {
      return;
    }

    // get the <a> element.
    var el = e.target;
    while (el && el.nodeName !== 'A') {
      el = el.parentNode;
    }

    // ignore clicks from non-a elements.
    if (!el) {
      return;
    }

    // make sure we can edit
    if (this.state.editValue) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Must must finish editting the currect value!")
      return;
    }

    if (el.href.indexOf("#name") !== -1) {
      // process name
      this.setState({
        editRule: rule,
        editValue: "name",
      });
      return;
    }

    if (el.href.indexOf("#access") !== -1) {
      // process access
      this.setState({
        editRule: rule,
        editValue: "access",
      });
      return;
    }

    if (el.href.indexOf("#processes") !== -1) {
      // process processes
      if (el.href.indexOf("_") !== -1) {
        // index of process
        let n = el.href.substring(el.href.indexOf("_") + 1);
        this.setState({
          editRule: rule,
          editValue: "processes",
          editIndex: n
        });
      } else {
        // any folder
        this.setState({
          editRule: rule,
          editValue: "processes",
          editIndex: -1
        });
      }
      return;
    }

    if (el.href.indexOf("#folders") !== -1) {
      // process folders
      if (el.href.indexOf("_") !== -1) {
        // index of folder
        let n = el.href.substring(el.href.indexOf("_") + 1);
        this.setState({
          editRule: rule,
          editValue: "folders",
          editIndex: n
        });
      } else {
        // any folder
        this.setState({
          editRule: rule,
          editValue: "folders",
          editIndex: -1
        });
      }
      return;
    }
  }

  closeEditRule = (rule) => {
    let modified = rule ? true : false;
    this.setState({
      editRule: null,
      editValue: null,
      modified: this.state.modified ? true : modified
    })
  }

  addRule = () => {
    let rs = this.state.rules;
    rs.splice(0, 0, decodeRule(null));  // null will return default rule

    // make sure there are no conflicts
    for(let i = 0; i < rs.length -1; i++) {
      if (rs[i + 1].index <= rs[i].index) {
        rs[i + 1].index = rs[i].index + 1;
      }
    }

    this.setState({
      rules: rs,
      modified: true
    })
  }

  deleteRule = (rule) => {
    let rs = this.state.rules;
    for(let i = 0; i < rs.length; i++) {
      if (rs[i].index === rule.index) {
        rs.splice(i, 1);
        this.setState({
          rules: rs,
          modified: true
        });
        break;
      }
    }
  }

  moveRuleUp = (rule) => {
    let rs = this.state.rules;
    for (let i = 1; i < rs.length; i++) {
      if (rs[i].index === rule.index) {
        // swap objects
        let r = rs[i - 1];
        rs[i - 1] = rs[i];
        rs[i] = r;
        // swap indexes
        let ni = rs[i - 1].index;
        rs[i - 1].index = rs[i].index;
        rs[i].index = ni;
        break;
      }
    }
    console.log(rs);
    this.setState({
      rules: rs,
      modified: true
    });
  }

  moveRuleDown = (rule) => {
    let rs = this.state.rules;
    for (let i = 0; i < rs.length - 1; i++) {
      if (rs[i].index === rule.index) {
        // swap objects
        let r = rs[i + 1];
        rs[i + 1] = rs[i];
        rs[i] = r;
        // swap indexes
        let ni = rs[i + 1].index;
        rs[i + 1].index = rs[i].index;
        rs[i].index = ni;
        break;
      }
    }
    this.setState({
      rules: rs,
      modified: true
    });
  }

  setTestingMode = (mode) => {
    // this.setState({
    //   testingMode: mode,
    //   testProcesses: mode ? this.arrayToSelectItems(this.getAllProcessesFromRules()) : [],
    //   testFolders: mode ? this.arrayToSelectItems(this.getAllFoldersFromRules()) : []
    // })
    this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Testing mode not implemented")
  }

  toggleRunningProcesses = () => {
    this.setState({
      viewRunningProcesses: !this.state.viewRunningProcesses,
      appReloadContext: createGuid()
    }, () => {
      this.props.setGlobalState("runningProcessSelect", this.state.viewRunningProcesses);
    })
  }

  renderTestingMode = () => {
    return (
      <div>
        <Row>
          <Col xs={5}>
            {/* Process&nbsp;<SelectFilter readonly={true} options={this.state.testProcesses} value={this.state.testProcess} /> */}
          </Col>
          <Col xs={5}>
            {/* Folder&nbsp;<SelectFilter readonly={true} options={this.state.testFolders} value={this.state.testFolders} /> */}
          </Col>
        </Row>
      </div>
    );
  }

  renderRule = (rule, ruleIndex) => {
    return (
      <div>
        <Card style={{ margin: "0px" }}>
          <CardHeader>
            <Switch condition={this.state.editRule === rule}>
              <Case value={false}>
                <Row>
                  <Col xs={12}>
                    <div onClick={e => { this.handleRuleLink(e, rule) }} style={{ paddingTop: "5px" }}>
                      <a href="#name" style={{ textDecoration: "underline", color: "#FFFFFF" }}>{rule.name}</a>
                    </div>
                  </Col>
                </Row>
              </Case>
            </Switch>
          </CardHeader>
          <CardBody>
            <div onClick={(e) => {this.handleRuleLink(e, rule)}}>
              <Switch condition={this.state.editRule === rule}>
                <Case value={false}>
                  <div style={{ fontSize: "0.875rem", fontStyle: "normal", fontWeight: "400", color: "#f0f0f0" }}>
                    <RuleText type="links" rule={rule} color="#f6f9fc" listView={this.props.ruleListView} />
                  </div>
                </Case>
                <Case value={true}>
                  <Switch condition={this.state.editValue}>
                    <Case value="name">
                      <h5>Enter a new name</h5>
                      <EditRuleName rule={rule} onClose={(rule) => this.closeEditRule(rule)} />
                    </Case>
                    <Case value="access">
                      <h5>Select the access level</h5>
                      <EditRuleAccess rule={rule} onClose={(rule) => this.closeEditRule(rule)} />
                    </Case>
                    <Case value="processes">
                      <h5>Update or add a new process, type the name, or select required processes.</h5>
                      <Switch condition={this.state.viewRunningProcesses}>
                        <Case value={false}>
                          <h5 className="text-info">
                            Cannot find an application, start the specific application, then view <Link className="text-muted" style={{ textDecoration: "underline" }} to="#" onClick={(e) => { this.toggleRunningProcesses() }}>Running Processes</Link>
                          </h5>
                        </Case>
                        <Case value={true}>
                          <h5 className="text-info">
                            Showing running processes, switch to <Link className="text-muted" style={{ textDecoration: "underline" }} to="#" onClick={(e) => { this.toggleRunningProcesses() }}>Installed Applications</Link>
                          </h5>
                        </Case>
                      </Switch>
                      <EditRuleApplication rule={rule} processIndex={this.state.editIndex} onClose={(rule) => this.closeEditRule(rule)} runningProcesses={this.state.viewRunningProcesses} reloadContext={this.state.appReloadContext} />
                    </Case>
                    <Case value="folders">
                      <h5>Update or add a new folder, select the dropdown to see available folders</h5>
                      <EditRuleFolder rule={rule} folderIndex={this.state.editIndex} drive={this.state.drive} onClose={(rule) => this.closeEditRule(rule)} />
                    </Case>
                  </Switch>
                </Case>
              </Switch>
            </div>
          </CardBody>
          <CardFooter>
            <Col xs={12}>
              <Switch condition={this.state.editRule === rule}>
                <Case value={false}>
                  <div onClick={e => { this.handleRuleLink(e, rule) }}>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button className="btn" size="sm" color="info" href="#processes" style={{ width: "120px", fontSize: ".9rem" }}>Add process</Button>
                        <Button className="btn" size="sm" color="info" href="#folders" style={{ width: "120px", fontSize: ".9rem" }}>Add folder</Button>
                        <Button className="btn" size="sm" color="info" onClick={(e) => this.deleteRule(rule)} style={{ width: "120px", fontSize: ".9rem" }}>Delete rule</Button>&nbsp;&nbsp;
                              <Switch condition={ruleIndex !== 0}>
                          <Case value={true}>
                            <Link to="#" onClick={(e) => this.moveRuleUp(rule)}><span className="text-info"><i className="fa fa-chevron-up" style={{ fontSize: "20px" }} /></span></Link>&nbsp;&nbsp;
                                </Case>
                        </Switch>
                        <Switch condition={ruleIndex < this.state.rules.length - 1}>
                          <Case value={true}>
                            <Link to="#" onClick={(e) => this.moveRuleDown(rule)}><span className="text-info"><i className="fa fa-chevron-down" style={{ fontSize: "20px" }} /></span></Link>
                          </Case>
                        </Switch>
                      </Col>
                    </Row>
                  </div>
                </Case>
              </Switch>
            </Col>
          </CardFooter>
        </Card>
      </div>
    );
  }

  render = () => {
    const closeBtn = <button className="close" onClick={this.closeDialog}>&times;</button>;
    return (
      <Modal className="manage-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
        <ModalHeader close={closeBtn}>
          <span><h4 className="text-info">Manage rules for <strong>{this.props.drive.mountName} [{this.props.drive.volumeLabel}]</strong></h4></span>
        </ModalHeader>
        <ModalBody id="classic-modal-slide-description">
          <Row>
            <Col xs={12}>
              <h5 className="text-info-2">Manage rules for drive <strong>{this.props.drive.mountName} [{this.props.drive.volumeLabel}]</strong>. Rules are processed from top to bottom, once a match is found, the "Allowed Access" for that rule is used to access the target file. Important - This feature is for advanced use, and care should be used when updating rules.</h5>
            </Col>
          </Row>
          <Switch condition={this.state.editRule === null}>
            <Case value={true}>
              <Row>
                <Col xs={12}>
                  <Button color="info" size="sm" onClick={(e) => this.addRule()}>Add new rule</Button>
                  &nbsp;
                  <Button color="info" size="sm" onClick={(e) => this.setTestingMode(true)}>Testing mode</Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div>
                    {this.state.modified && !this.state.tested ?
                      <span className="text-danger">Important - These rules have not been tested!</span> : null
                    }
                  </div>
                </Col>
              </Row>
              <Switch condition={this.state.modified}>
                <Case value={false}>
                  <Row>
                    <Col xs={12}>
                      <Button color="secondary" onClick={() => this.closeDialog()}>Close</Button>
                    </Col>
                  </Row>
                </Case>
                <Case value={true}>
                  <Row>
                    <Col xs={6}>
                      <Button color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </Col>
                    <Col className="text-right" xs={6}>
                      <Button color="info" onClick={() => this.closeDialog(this.state.rules)}>Save changes</Button>
                    </Col>
                  </Row>
                </Case>
              </Switch>
            </Case>
          </Switch>
          <div>
            {this.state.testingMode ? this.renderTestingMode() : null}
            {this.state.rules.map((rule, i) => {
              return (
                <React.Fragment key={i}>
                  <Row style={{ padding: "0px" }}>
                    <Col xs={12}>
                      {this.renderRule(rule, i)}
                    </Col>
                  </Row>
                  <Switch condition={i < this.state.rules.length - 1}>
                    <Case value={true}>
                      <Row>
                        <Col xs={12}>
                          <div style={{ marginLeft: "30px",  marginTop: "25px", marginBottom: "25px" }}>
                            <span className="text-info"><i className="fa fa-arrow-down" style={{ fontSize: "24px", lineHeight: "1.2" }} /></span>
                            <span style={{ verticalAlign: "top" }}>&nbsp;&nbsp;if no match, move to the next rule</span>
                          </div>
                        </Col>
                      </Row>
                    </Case>
                  </Switch>
                </React.Fragment>
              );
            })}
          </div>
          <Switch condition={this.state.modified}>
            <Case value={false}>
              <Row>
                <Col xs={12}>
                  <Button color="secondary" onClick={() => this.closeDialog()}>Close</Button>
                </Col>
              </Row>
            </Case>
            <Case value={true}>
              <Row>
                <Col xs={6}>
                  <Button color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </Col>
                <Col className="text-right" xs={6}>
                  <Button color="info" onClick={() => this.closeDialog(this.state.rules)}>Save changes</Button>
                </Col>
              </Row>
            </Case>
          </Switch>
        </ModalBody>
        <ModalFooter>
          <div></div>
        </ModalFooter>
      </Modal>
    );
  }
}