import React from "react";
import classNames from "classnames";
//import { Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavItem, Nav, Container} from "reactstrap";
import { Collapse, NavbarBrand, Navbar, NavItem, Nav, Container } from "reactstrap";
import { NavLink } from 'react-router-dom'
import { AuthenticationContext } from "context/authentication";
import Switch, { Case } from "react-switch-case";
import { Consts } from "config/consts";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent"
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };

  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };

  displayNoFeature = () => {
    this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "ANVIL feature not implemented!")
  }

  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };

  render() {
    return (
      <React.Fragment>
        <AuthenticationContext.Consumer>
          {authContext =>
            <Navbar className={classNames("navbar-absolute", this.state.color)} expand="lg">
              <Container fluid>
                <div className="navbar-wrapper">
                  <div className={classNames("navbar-toggle d-inline", { toggled: this.props.sidebarOpened })}>
                    <button className="navbar-toggler" type="button" onClick={this.props.toggleSidebar}>
                      <span className="navbar-toggler-bar bar1" />
                      <span className="navbar-toggler-bar bar2" />
                      <span className="navbar-toggler-bar bar3" />
                    </button>
                  </div>
                  <NavbarBrand href="#pablo" onClick={e => e.preventDefault()} style={{ marginTop: "10px" }}>
                    <h4>
                      {this.props.brandText}
                    </h4>
                  </NavbarBrand>
                </div>
                <button aria-expanded={false} aria-label="Toggle navigation" className="navbar-toggler" data-target="#navigation" data-toggle="collapse" id="navigation" type="button" onClick={this.toggleCollapse}>
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                </button>
                <Collapse navbar isOpen={this.state.collapseOpen}>
                  <Nav className="ml-auto" navbar style={{ margin: "10px" }}>
                    <Switch condition={authContext.state.authenticated === true}>
                      <Case value={false}>
                        <NavItem>
                          <NavLink to="/user/register">
                            Register{" "}<i className="tim-icons icon-single-02" />
                          </NavLink>
                        </NavItem>
                        <NavItem active>
                          <NavLink to="/user/login">
                            Login{" "}<i className="tim-icons icon-single-02" />
                          </NavLink>
                        </NavItem>
                      </Case>
                      <Case value={true}>
                        {authContext.state.licenseUpgradeAction ?
                          <NavItem>
                            <NavLink to="#" onClick={(e) => authContext.state.licenseUpgradeAction.action()}>
                              {authContext.state.licenseUpgradeAction.title}{" "}<i className="tim-icons icon-cart" />
                            </NavLink>
                          </NavItem>
                          : null
                        }
                        <NavItem>
                          <a href="https://support.division-m.com/hc/en-us/sections/360000193695-Documentation" target="_blank" rel="noopener noreferrer">
                              Documentation{" "}<i className="tim-icons icon-paper" />
                          </a>
                        </NavItem>
                        <NavItem>
                          <a href="https://support.division-m.com" target="_blank" rel="noopener noreferrer">
                              Support{" "}<i className="tim-icons icon-chat-33" />
                          </a>
                        </NavItem>
                        <NavItem>
                          <NavLink to="/user/logout">
                            Logout{" "}<i className="tim-icons icon-user-run" />
                          </NavLink>
                        </NavItem>
                        {/* <UncontrolledDropdown nav>
                          <DropdownToggle caret color="default" data-toggle="dropdown" nav onClick={e => e.preventDefault()}>
                            <div className="photo">
                              <img alt="..." src={require("assets/img/anime3.png")} />
                            </div>
                            <b className="caret d-none d-lg-block d-xl-block" />
                            <p className="d-lg-none">Log out</p>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-navbar" right tag="ul">
                            <NavLink tag="li" to="#">
                              <DropdownItem onClick={(e) => this.displayNoFeature()} className="nav-item">Profile</DropdownItem>
                            </NavLink>
                            <NavLink tag="li" to="#">
                              <DropdownItem onClick={(e) => this.displayNoFeature()} className="nav-item">Settings</DropdownItem>
                            </NavLink>
                            <DropdownItem divider tag="li" />
                            <NavLink tag="li" to="/user/logout">
                              <DropdownItem className="nav-item">Logout</DropdownItem>
                            </NavLink>
                          </DropdownMenu>
                        </UncontrolledDropdown> */}
                      </Case>
                    </Switch>
                    <li className="separator d-lg-none" />
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
          }
        </AuthenticationContext.Consumer>
      </React.Fragment>
    );
  }
}

export default Header;
