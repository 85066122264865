import React from "react";
import { Firestore } from "config/firebase";
import { Button, Card, CardBody } from "reactstrap";
import Switch, { Case, Default } from "react-switch-case";
import { decodeRule } from "config/utilities"
import { DriveRulesManage } from "components/dialogs/drive-rules-manage";
import { Consts } from "config/consts";
import { rulesDeleteByGroupId } from "config/utilities";
import { Link } from 'react-router-dom'
import { NewRulesSelect } from "components/dialogs/new-rules-select";
import { NewFolderApp } from "components/dialogs/new-folder-app";
import { NewHomeFolder } from "components/dialogs/new-home-folder";
import { NewImmutableFolder } from "components/dialogs/new-immutable-folder";
import { NewCanaryFile } from "components/dialogs/new-canary-file";
import { NewEncryptedFolder } from "components/dialogs/new-encrypted-folder";
import { RuleRaw } from "components/home/rules/rule-raw";
import { RuleFixed } from "components/home/rules/rule-fixed";
import { RuleFolderApp } from "components/home/rules/rule-folder-app";
import { RuleLearning } from "components/home/rules/rule-learning";
import { RuleImmutable } from "components/home/rules/rule-immutable";
import { RuleHomeFolder } from "components/home/rules/rule-home-folder";
import { RuleCanaryFile } from "components/home/rules/rule-canary-file";
import { RuleEncryptedFolder } from "components/home/rules/rule-encrypted-folder";
import { RuleTheCyberCanary } from "components/home/rules/rule-the-cyber-canary";
import { NewTheCyberCanary } from "components/dialogs/new-the-cyber-canary";
import { ManageSnapshots } from "components/dialogs/manage-snapshots";

const dialogsHidden = 0;
const dialogNewRulesSelect = 1;
const dialogNewTheCyberCanary = 2;
const dialogNewFolderApp = 3;
const dialogNewHomeFolder = 4;
const dialogNewImmutableFolder = 5;
const dialogNewCanaryFile = 6;
const dialogNewEncryptedFolder = 7;
const dialogRulesManage = 8;
const dialogSnapshot = 9;

export class DriveRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: dialogsHidden,
      drive : this.props.drive,
      historyDrive: [],
      ruleListView: false,
      newRuleObj: null,
      editRuleSetObj: null,
      installKey: null,
      defaultSnapshotSignature: null
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.drive !== state.drive) {
      state.drive = props.drive;
      return state;
    }
    return null;
  }

  componentDidMount() {
    this.waitLicense();
  }

  waitLicense = () => {
    // grab license
    let lic = this.props.getGlobalState().license;

    // do we have license?
    if (!lic.validated) {
      this.waitOnEvent(this.waitLicense, 2000);
    } else {
      // determine install key
      Firestore.collection("users").doc(`${this.props.getAuthContext().state.uid}/licenses/${lic.key}`).get().then((doc) => {
        let licDetails = doc.data();
        this.setState({
          installKey: licDetails.link_install,
          defaultRulesSignature: licDetails.default_rules_signature })
      }).catch(function (error) {
        console.log("Error getting license details")
      });
    }
  }

  waitOnEvent = (method, timeout) => {
    // monitor...
    setTimeout(() => {
      method();
    }, timeout);
  }

  manageDriveRules = () => {
    this.setState({
      displayDialog: dialogRulesManage
    })
  }

  closeDriveRulesManage = (updatedDrive) => {
    if (updatedDrive) {
      let rules = updatedDrive.rules;
      let drive = this.state.drive;
      let historyDrive = this.state.historyDrive;
      let cloneDrive = { ...this.state.drive };
      historyDrive.push(cloneDrive);
      drive.rules = rules;
      this.setState({
        displayDialog: dialogsHidden,
        drive: drive,
        historyDrive: historyDrive
      })
    } else {
      this.setState({
        displayDialog: dialogsHidden
      });
    }
  }

  saveDriveRules = (noPrompt) => {
    if (!noPrompt) {
      this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Apply modified rules?", "Are you sure you want to apply the rule changes?", () => {
        // update client
        window.roFolderWall.AddUpdateMountPoint(this.state.drive.id, this.state.drive.rules, (updatedRules, stateValue) => {
          // update home state value
          console.log("Returned state value is %s", stateValue);
          if (this.props.onStateValueChange)
            this.props.onStateValueChange(stateValue);

          // update history
          this.setState({
            historyDrive: []
          }, () => {
            this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "ANVIL has been successfully updated!")
          });
        }, (error) => {
            this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
        });
      });
    } else {
      // update client
      window.roFolderWall.AddUpdateMountPoint(this.state.drive.id, this.state.drive.rules, (updatedRules, stateValue) => {
        // update home state value
        if (this.props.onStateValueChange)
          this.props.onStateValueChange(stateValue);

        // update
        this.setState({
          historyDrive: []
        }, () => {
          this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "ANVIL has been successfully updated!")
        });
      }, (msg) => {
        console.log("AddUpdateMountPoint Error!")
      });
    }
  }

  deleteDriveRule = (ruleObj) => {
    let drive = this.state.drive;
    let historyDrive = this.state.historyDrive;
    let cloneDrive = { ...this.state.drive };
    historyDrive.push(cloneDrive);
    // make sure we have a group id, other just delete the ruleObj
    if (ruleObj.metadata.GROUP_ID) {
      // delete group
      drive.rules = rulesDeleteByGroupId(drive.rules, ruleObj.metadata.GROUP_ID)
    } else {
      // just delete single
      for (let i = 0; i < drive.rules.length; i++) {
        if (decodeRule(drive.rules[i]).index === ruleObj.index) {
          drive.rules.splice(i, 1);
          break;
        }
      }
    }
    this.setState({
      drive: drive,
      historyDrive: historyDrive
    })
  }

  updateDriveRules = (updatedDriveRules, autoSave) => {
    if (updatedDriveRules) {
      let rules = updatedDriveRules;
      let drive = this.state.drive;
      let historyDrive = this.state.historyDrive;
      let cloneDrive = { ...this.state.drive };
      historyDrive.push(cloneDrive);
      drive.rules = rules;
      this.setState({
        displayDialog: dialogsHidden,
        drive: drive,
        historyDrive: autoSave ? [] : historyDrive,
      }, () => {
        if (autoSave)
          this.saveDriveRules(true);
      })
    } else {
      this.setState({
        displayDialog: dialogsHidden
      })
    }
  }

  rollBackDriveRules = () => {
    let historyDrive = this.state.historyDrive;
    let drive = this.state.drive;
    if (historyDrive.length > 0) {
      drive.rules = historyDrive[historyDrive.length - 1].rules;
      historyDrive.pop();
      this.setState({
        drive: drive,
        historyDrive: historyDrive
      })
    }
  }

  resetAllDriveRules = () => {
    this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Reset rule(s)?", "Are you sure you want to reset all rules for this drive?", () => {
      window.roFolderWall.ResetMountPointRules(this.state.drive.id, (updatedRules, stateValue) => {
        // update home state value
        if (this.props.onStateValueChange)
          this.props.onStateValueChange(stateValue);

        // update
        let drive = this.state.drive;
        drive.rules = updatedRules.items;
        this.setState({
          drive: drive,
          historyDrive: []
        }, () => {
          this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "The ANVIL drive has been successfully reset!")
        });
      }, (msg) => {
          console.log("ResetMountPointRules Error!")
      });
    });
  }

  killDriveAccess = () => {
    this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Disable rule(s)?", "Are you sure you want to disable all rules for this drive? You will need to reboot in order to re-enable these rules!", () => {
      window.roFolderWall.DeadmanSwitch(this.state.drive.id, () => {
        console.log("Drive: %s has been disabled!", this.state.drive.id);
      }, (error) => {
        console.log(error);
      })
    });
  }

  newRulesSelect = () => {
    // if we are TCC standard, go straight into TCC options
    if (this.props.getGlobalState().license.type === Consts.LICENSE.TYPE.THE_CYBER_CANARY) {
      this.newTheCyberCanary();
    } else {
      this.setState({
        displayDialog: dialogNewRulesSelect
      })
    }
  }

  newTheCyberCanary = () => {
    this.setState({
      displayDialog: dialogNewTheCyberCanary
    })
  }

  newFolderApp = () => {
    this.setState({
      displayDialog: dialogNewFolderApp
    })
  }

  newHomeFolder = () => {
    this.setState({
      displayDialog: dialogNewHomeFolder
    })
  }

  newImmutableFolder = () => {
    this.setState({
      displayDialog: dialogNewImmutableFolder
    })
  }

  newCanaryFile = () => {
    this.setState({
      displayDialog: dialogNewCanaryFile
    })
  }

  newEncryptedFolder = () => {
    this.setState({
      displayDialog: dialogNewEncryptedFolder
    })
  }

  closeNewProtection = (complete) => {
    this.setState({
      displayDialog: dialogsHidden
    })
  }

  closeSnapshot = (complete) => {
    this.setState({
      displayDialog: dialogsHidden
    })
  }

  render = () => {
    return (
      <React.Fragment>
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogNewRulesSelect}>
            <NewRulesSelect {...this.props} drive={this.state.drive} onClose={(complete) => this.closeNewProtection(complete)} onNewTheCyberCanary={this.newTheCyberCanary} onNewFolderApp={this.newFolderApp} onNewHomeFolder={this.newHomeFolder} onNewImmutableFolder={this.newImmutableFolder} onNewCanaryFile={this.newCanaryFile} onNewEncryptedFolder={this.newEncryptedFolder} />
          </Case>
          <Case value={dialogNewFolderApp}>
            <NewFolderApp {...this.props} drive={this.state.drive} onClose={(complete) => this.closeNewProtection(complete)} />
          </Case>
          <Case value={dialogNewHomeFolder}>
            <NewHomeFolder {...this.props} drive={this.state.drive} onClose={(complete) => this.closeNewProtection(complete)} onNewFolderApp={this.newFolderApp} />
          </Case>
          <Case value={dialogNewImmutableFolder}>
            <NewImmutableFolder {...this.props} drive={this.state.drive} onClose={(complete) => this.closeNewProtection(complete)} onNewImmutableFolder={this.newImmutableFolder} />
          </Case>
          <Case value={dialogNewCanaryFile}>
            <NewCanaryFile {...this.props} drive={this.state.drive} onClose={(complete) => this.closeNewProtection(complete)} onNewCanaryFile={this.newCanaryFile} />
          </Case>
          <Case value={dialogNewEncryptedFolder}>
            <NewEncryptedFolder {...this.props} drive={this.state.drive} onClose={(complete) => this.closeNewProtection(complete)} onNewEncryptedFolder={this.newEncryptedFolder} />
          </Case>
          <Case value={dialogNewTheCyberCanary}>
            <NewTheCyberCanary {...this.props} drive={this.state.drive} onClose={(complete) => this.closeNewProtection(complete)} onNewTheCyberCanary={this.newTheCyberCanary} />
          </Case>
          <Case value={dialogRulesManage}>
            <DriveRulesManage {...this.props} drive={this.state.drive} onClose={updatedDrive => this.closeDriveRulesManage(updatedDrive)} ruleListView={this.state.ruleListView} />
          </Case>
          <Case value={dialogSnapshot}>
            <ManageSnapshots {...this.props} drive={this.state.drive} onClose={(complete) => this.closeSnapshot(complete)} installKey={this.state.installKey} />
          </Case>
        </Switch>
        <h4 className="text-primary">
          <i className="far fa-hdd" />&nbsp;Drive {this.state.drive.mountName}&nbsp;&nbsp;{this.state.drive.volumeLabel ? "[" + this.state.drive.volumeLabel + "]" : ""}
        </h4>
        <Switch condition={this.state.historyDrive.length > 0}>
          <Case value={true}>
            <div style={{ marginTop: "10px" }}>
              <Button color="primary" style={{ minWidth: "270px" }} onClick={(e) => this.saveDriveRules()}>
                Save the changes
              </Button>
              <span className="h6 text-muted">&nbsp;&nbsp;or&nbsp;&nbsp;</span>
              <Button color="primary" style={{ minWidth: "270px" }} onClick={(e) => this.rollBackDriveRules()}>
                Roll back changes
              </Button>
            </div>
            <p className="h5 text-muted small">There have been {this.state.historyDrive.length} change(s) made to the rule. You need to save or roll back these changes.</p>
          </Case>
        </Switch>
        <h5 className="text-primary-2">
          <Switch condition={Consts.SYSTEM.PRODUCT_ID}>
            <Case value="anvil">
                Protection rules are processed from top to bottom, once a match is found, the "Allowed Access" for that rule is used to access the target file
            </Case>
            <Case value="tcc">
              {this.state.drive.rules.length === 1 ?
                <span className="text-danger">There are no The Cyber Canary rule(s) active.</span>
              :
                <React.Fragment>
                  The Cyber Canary is active and monitoring your system.
                </React.Fragment>
              }
            </Case>
          </Switch>
          &nbsp;
          {this.state.drive.rules.length === 1 &&
           this.state.drive.rules[0].indexOf("[L+R+W+]") !== -1 &&
           Consts.SYSTEM.PRODUCT_ID !== "tcc" ? <span className="text-danger">Important, this rule allows full access to the entire drive!</span> : null}
        </h5>
        {this.state.drive.rules.map((rule, i) => {
          if ((decodeRule(rule).metadata.TYPE !== Consts.RULE_TYPE.SUPPORT_RULE && Consts.SYSTEM.PRODUCT_ID !== "tcc") ||
              (decodeRule(rule).metadata.TYPE === Consts.RULE_TYPE.THE_CYBER_CANARY_RULE && Consts.SYSTEM.PRODUCT_ID === "tcc")) {
            return (
              <React.Fragment key={i}>
                <Card>
                  <CardBody>
                    <Switch condition={decodeRule(rule).metadata ? decodeRule(rule).metadata.TYPE : 0}>
                      <Case value={Consts.RULE_TYPE.WILDCARD_RULE}>
                        <RuleFixed {...this.props} rule={rule} drive={this.state.drive} />
                      </Case>
                      <Case value={Consts.RULE_TYPE.FOLDER_APPS_RULE}>
                        <RuleFolderApp {...this.props} rule={rule} drive={this.state.drive} onDeleteRule={this.deleteDriveRule} onUpdateDriveRules={this.updateDriveRules} />
                      </Case>
                      <Case value={Consts.RULE_TYPE.HOME_FOLDER_RULE}>
                        <RuleHomeFolder {...this.props} rule={rule} drive={this.state.drive} onDeleteRule={this.deleteDriveRule} onUpdateDriveRules={this.updateDriveRules} />
                      </Case>
                      <Case value={Consts.RULE_TYPE.IMMUTABLE_FOLDER_RULE}>
                        <RuleImmutable {...this.props} rule={rule} drive={this.state.drive} onDeleteRule={this.deleteDriveRule} />
                      </Case>
                      <Case value={Consts.RULE_TYPE.CANARY_FILE_RULE}>
                        <RuleCanaryFile {...this.props} rule={rule} drive={this.state.drive} onDeleteRule={this.deleteDriveRule} />
                      </Case>
                      <Case value={Consts.RULE_TYPE.ENCRYPTED_FOLDER_RULE}>
                        <RuleEncryptedFolder {...this.props} rule={rule} drive={this.state.drive} onDeleteRule={this.deleteDriveRule} onUpdateDriveRules={this.updateDriveRules} />
                      </Case>
                      <Case value={Consts.RULE_TYPE.THE_CYBER_CANARY_RULE}>
                        <RuleTheCyberCanary {...this.props} rule={rule} drive={this.state.drive} onDeleteRule={this.deleteDriveRule} onUpdateDriveRules={this.updateDriveRules} />
                      </Case>
                      <Case value={Consts.RULE_TYPE.LEARNING_RULE}>
                        <RuleLearning {...this.props} rule={rule} drive={this.state.drive} onDeleteRule={this.deleteDriveRule} />
                      </Case>
                      <Case value={Consts.RULE_TYPE.ORPHANED_SUPPORT_RULE}>
                        <RuleRaw {...this.props} rule={rule} drive={this.state.drive} onDeleteRule={this.deleteDriveRule} onUpdateDriveRules={this.updateDriveRules} error="This is an orphaned rule. This rule was part of a rule set that seems to have been deleted." />
                      </Case>
                      <Default>
                        <RuleRaw {...this.props} rule={rule} drive={this.state.drive} onDeleteRule={this.deleteDriveRule} onUpdateDriveRules={this.updateDriveRules} />
                      </Default>
                    </Switch>
                  </CardBody>
                </Card>
                <Switch condition={i < this.state.drive.rules.length - 1 && Consts.SYSTEM.PRODUCT_ID !== "tcc"}>
                  <Case value={true}>
                    <div style={{ paddingTop: "10px", paddingBottom: "30px" }}>
                      <span className="text-primary"><i className="fa fa-arrow-down" style={{ marginLeft: "100px", fontSize: "16px", lineHeight: "1.4" }} /></span>
                      <span className="text-dark" style={{ marginLeft: "10px", verticalAlign: "top" }}>If no match, move to the next rule</span>
                    </div>
                  </Case>
                </Switch>
              </React.Fragment>
            );
          } else {
            return null;
          }
        })}
        <div>
          <Button color="default" onClick={(e) => this.newRulesSelect()}>
            Add protection
          </Button>
          {this.state.installKey ?
            <span className="h3 text-primary" style={{float: "right"}}>
              <Link to="#" onClick={(e) => this.setState({ displayDialog: dialogSnapshot }) }>
                <i className="far fa-camera" />
              </Link>
            </span>: null }
        </div>
        {this.props.getGlobalState().advancedView ?
          <h5 className="h5 text-danger-2" style={{ marginTop: "20px" }}><Link className="text-danger" style={{ textDecoration: "underline" }} to="" onClick={(e) => { this.manageDriveRules() }}>Edit rules</Link> in raw mode (warning, advanced).</h5>
        : null}
        {this.props.getGlobalState().advancedView ?
          <h5 className="h5 text-danger-2" style={{ marginTop: "20px" }}>Reset the drive<Link className="text-danger" style={{ textDecoration: "underline" }} to="" onClick={(e) => { this.resetAllDriveRules() }}> to its initial open rule.</Link>.</h5>
          : null}
        {this.props.getGlobalState().advancedView ?
          <h5 className="h5 text-danger-2" style={{ marginTop: "20px" }}>Incase of emergency, <Link className="text-danger" style={{ textDecoration: "underline" }} to="" onClick={(e) => { this.killDriveAccess() }}>break glass</Link>! If you have an issue with drive access, hit the stop button.</h5>
          : null}
      </React.Fragment>
    );
  }
}