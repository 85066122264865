import React from "react";
import { Button, Card, CardHeader, CardBody, CardFooter, FormGroup, Form, Input, Row, Col, Label } from "reactstrap";
import { AuthenticationContext, Authentication } from "context/authentication";
import { Redirect } from "react-router-dom";
import { Consts } from "config/consts";
import { Link } from "react-router-dom";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.props.setCleanPage();
    this.state = {
      email: "",
      password: "",
      validated: false,
      allowAutoLogin: true,
      redirect: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // wait to see if auto login fires
    this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Checking for auto login, please wait...");
  }

  handleChange = (e) => {
    // update state
    this.setState({ [e.target.name]: e.target.value }, () => {
      // validate
      this.setState({ validated: (this.state.email.indexOf("@") !== -1 && this.state.email.indexOf(".") !== -1 && this.state.password.length > 0) });
    });
  }

  authWithEmailAndPassword = (e, authContext) => {
    e.preventDefault();
    this.props.setLoader(true);
    this.setState({ allowAutoLogin: false }, () => {
      authContext.loginUser(this.state.email, this.state.password).then((user) => {
        // successful
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Login successful, please wait...");
        setTimeout(() => {
          this.setState({ redirect: true });
        }, 2000);
      }).catch((error) => {
        // error
        console.log(error.toString());
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.toString());
        this.props.setLoader(false);
      });
    });
  }

  authWithOAuth = (oauth, e, authContext) => {
    e.preventDefault();
    this.props.setLoader(true);
    this.setState({ allowAutoLogin: false }, () => {
      authContext.authenticateOAuth(oauth).then((user) => {
        // successful
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Login successful, please wait...");
        setTimeout(() => {
          this.setState({ redirect: true });
        }, 2000);
      }).catch((error) => {
        // error
        console.log(error.toString());
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.toString());
        this.props.setLoader(false);
      })
    });
  }

  onAuthenticated = (authContext) => {
    if (this.state.allowAutoLogin) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Auto login processed, please wait...");
      this.setState({ pending: true });
      setTimeout(() => {
        this.setState({ redirect: true });
      }, 500);
    }
  }

  resetPassword = (e, authContext) => {
    e.preventDefault();
    this.props.setLoader(true);

    // validate email
    if (!this.state.email || this.state.email.indexOf("@") === -1) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Please enter a valid email address.");
      this.props.setLoader(false);
      return;
    }

    authContext.sendPasswordResetRequest(this.state.email).then(() => {
      // successful
      this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Reset email has been sent.");
      this.props.setLoader(false);
    }).catch((error) => {
      // error
      console.log(error.toString());
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.toString());
      this.props.setLoader(false);
    })
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to={Consts.USER.DEFAULT_HOME} />
      )
    }

    return (
      <React.Fragment>
        <Authentication onAuthenticate={this.onAuthenticated} />
        <div className="content-full-page">
            <div className="login">
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardHeader>
                      <h5 className="title">Login with email and password</h5>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <Label>Email</Label>
                              <Input name="email" placeholder="Required" type="email" onChange={(e) => this.handleChange(e)}/>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <Label>Password</Label>
                              <Input name="password" placeholder="Required" type="password" onChange={(e) => this.handleChange(e)} />
                              <AuthenticationContext.Consumer>
                                {authContext =>
                                  <small><Link to="#" onClick={(e) => this.resetPassword(e, authContext)}>Reset password</Link></small>
                                }
                              </AuthenticationContext.Consumer>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <AuthenticationContext.Consumer>
                        {authContext =>
                          <div className="text-right">
                            <Button className="btn-fill" color="primary" onClick={e => this.authWithEmailAndPassword(e, authContext)}>
                              Login
                            </Button>
                          </div>
                        }
                      </AuthenticationContext.Consumer>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <Row>
              <Col>
                <div className="text-center">
                  <h4>-OR-</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <h5 className="title">Login with</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="text-center">
                      <AuthenticationContext.Consumer>
                        {authContext =>
                          <Row>
                            <Col md={12}>
                              <Button className="btn-fill" color="primary" style={{ width: "100%" }} onClick={(e) => this.authWithOAuth(Consts.OAUTH_PROVIDERS.GOOGLE, e, authContext)}>
                                <i className="fab fa-google"/>&nbsp;&nbsp;Google
                              </Button>
                            </Col>
                          </Row>
                        }
                      </AuthenticationContext.Consumer>
                      <AuthenticationContext.Consumer>
                        {authContext =>
                          <Row>
                            <Col md={12}>
                              <Button className="btn-fill" color="primary" style={{ width: "100%" }} onClick={(e) => this.authWithOAuth(Consts.OAUTH_PROVIDERS.FACEBOOK, e, authContext)}>
                                <i className="fab fa-facebook"/>&nbsp;&nbsp;Facebook
                              </Button>
                            </Col>
                          </Row>
                        }
                      </AuthenticationContext.Consumer>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="text-center">
                  <small>Two factor authentication coming soon</small>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
