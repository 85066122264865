import React from "react";
import { FirebaseFuncts } from "config/firebase";
import { Label, FormGroup, Input, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import Loadable from "react-loading-overlay";
import Switch, { Case } from "react-switch-case";
import { Consts } from "config/consts";
import { getWebAppValue } from "config/utilities";
import { ExpandingText } from "components/common/expanding-text"

const modeWaiting = 0;
const modeExistingSnapshot = 1;
const modeNoSnapshot = 2;
const modeCreateSnapshot = 3;

export class ManageSnapshots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      manageMode: modeWaiting,
      lastManageMode: null,
      licenseKey: null,
      availableLicenses: null,
      defaultSnapshot: null,
      accessCode: null,
      dynamicSnapshot: false,
      errorMessage: null,
    }
  }

  componentDidMount() {
    this.getSnapshot();
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  closeDialog = () => {
    if (!this.props.onClose)
      return;
    this.props.onClose();
  }

  getSnapshot = () => {
    if (this.props.installKey) {
      this.setState({ loader: true });
      let linkInstall = FirebaseFuncts.httpsCallable("getLinkInstall");
      linkInstall({
        authenticatedUid: this.props.getAuthContext().state.uid,
        key: this.props.installKey,
        authType: "NONE",
        authentication: "NONE"
      }).then((result) => {
        // set installer params
        this.setState({
          licenseKey: result.data.licenseKey,
          availableLicenses: result.data.availableLicenses,
          defaultSnapshot: result.data.defaultSnapshot,
          accessCode: result.data.accessCode,
          manageMode: result.data.defaultSnapshot ? modeExistingSnapshot : modeNoSnapshot,
          loader: false
        })
      }).catch((error) => {
        console.log(error);
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message)
        this.props.setLoader(false);
        this.setState({
          errorMessage: error.message,
          loader: false
        })
      });
    }
  }

  snapshotInstallKey = (addUpdate) => {
    if (addUpdate) {
      // add update
      this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Snapshot existing rules", "Are you sure you want to snapshot the exist rules for the install key?", () => {
        this.setState({ loader: true });
        window.roFolderWall.SnapshotRulesForInstallKey(this.props.drive.id, this.props.installKey, this.state.dynamicSnapshot, () => {
          this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Snapshot rules has been requested.");
          setTimeout(() => {
            this.getSnapshot();
          }, 3000);
        }, (error) => {
          console.log(error);
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message)
          this.setState({ loader: false });
        })
      });
    } else {
      // delete
      this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Snapshot existing rules", "Are you sure you want to delete the existing snapshot?", () => {
        this.setState({ loader: true });
        window.roFolderWall.SnapshotRulesForInstallKey(this.props.drive.id, this.props.installKey + "_DELETE", false, () => {
          this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Deleting the snapshot rules has been requested.")
          setTimeout(() => {
            this.getSnapshot();
          }, 3000);
        }, (error) => {
          console.log(error);
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message)
          this.setState({ loader: false });
        })
      });
    }
  }

  renderExistingSnapshot = () => {
    return (
      <React.Fragment>
        <p className="text-success">We have a valid snapshot that will be deployed when installation link is used.</p>
        {this.state.defaultSnapshot ?
          <p className="text-warning">{this.props.drive.stateValue === this.state.defaultSnapshot.rules_signature ? "This snapshot is identical to the current rules" : "This snapshot is NOT identical to the current rules"}</p> : null }
        <div className="text-left">
          {this.state.defaultSnapshot && this.props.drive.stateValue !== this.state.defaultSnapshot.rules_signature ?
            <Button color="secondary" onClick={(e) => this.setState({ manageMode: modeCreateSnapshot, lastManageMode: modeExistingSnapshot })}>Replace this snapshot</Button> : null }
          <Button color="secondary" onClick={(e) => this.snapshotInstallKey(false)}>Delete this snapshot</Button>
        </div>
      </React.Fragment>
    )
  }

  renderNoSnapshot = () => {
    return (
      <React.Fragment>
        <p className="text-success">There is no snapshot for this installation</p>
        <div className="text-left">
          <Button color="secondary" onClick={(e) => this.setState({ manageMode: modeCreateSnapshot, lastManageMode: modeNoSnapshot })}>Take a new snapshot</Button>
        </div>
      </React.Fragment>
    )
  }

  renderCreateSnapshot = () => {
    return (
      <React.Fragment>
        <p className="text-success">There is no snapshot for this installation</p>
          <FormGroup check>
            <Label check>
              <Input name="dynamicSnapshot" type="checkbox" checked={this.state.dynamicSnapshot} onChange={(e) => this.handleChange(e)} />{' '}
              Make the snapshot dynamic (will be updated on every client restart)
              <span className="form-check-sign">
                <span className="check"></span>
              </span>
            </Label>
          </FormGroup>
        <div className="text-left">
          <Button color="secondary" onClick={(e) => this.snapshotInstallKey(true)}>Create snapshot</Button>
          <Button color="secondary" onClick={(e) => this.setState({ manageMode: this.state.lastManageMode })}>Cancel</Button>
        </div>
      </React.Fragment>
    )
  }

  render = () => {
    const closeBtn = <button className="close" onClick={this.closeDialog}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="installer-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <Loadable active={this.state.loader} spinner text={this.state.manageMode === modeWaiting ? "Getting snapshot details, please wait..." : "Updating snapshot, please wait..."}>
            <ModalHeader close={closeBtn}>
              <Row>
                  <Col xs={12}>
                    <h3 className="text-primary">
                      <i className="far fa-camera" />&nbsp;{getWebAppValue(Consts.WEB_APP_VALUE.NAME)} snapshot manager
                    </h3>
                    <h5 className="text-primary-2">
                      The {getWebAppValue(Consts.WEB_APP_VALUE.NAME)} snapshot manager is used to create and manage installation snapshots. To read more about install keys and snapshots, <a href="https://support.division-m.com/hc/en-us/articles/360003455616" className="text-info" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">click here</a>.
                    </h5>
                  </Col>
                </Row>
            </ModalHeader>
            <ModalBody id="classic-modal-slide-description">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12}>
                      <p className="text-light">
                        Install key&nbsp;
                          <span className="text-success">
                          <ExpandingText {...this.props} text={this.props.installKey} startLength="52" linkStyle="text-success" clipboard={true} />
                          </span>
                      </p>
                    </Col>
                  </Row>
                  {this.state.defaultSnapshot ?
                    <Row>
                      <Col xs={12}>
                        <p className="text-light">
                          Access code&nbsp;<span className="text-success">{this.state.accessCode}</span>
                        </p>
                      </Col>
                    </Row>: null}
                  <Row>
                    <Col xs={12}>
                      <p className="text-light">
                        Installation URL&nbsp;
                          <span className="text-success">
                          <ExpandingText {...this.props} text={getWebAppValue(Consts.WEB_APP_VALUE.URL) + "/install/" + this.props.installKey} startLength="52" linkStyle="text-success" clipboard={true} />
                          </span>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <p className="text-light">
                        Licensed key is&nbsp;<span className="text-success">{this.state.licenseKey}</span>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <p className="text-light">
                        Available licenses&nbsp;<span className="text-success">{this.state.availableLicenses}</span>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Switch condition={this.state.manageMode}>
                        <Case value={modeExistingSnapshot}>
                          {this.renderExistingSnapshot()}
                        </Case>
                        <Case value={modeNoSnapshot}>
                          {this.renderNoSnapshot()}
                        </Case>
                        <Case value={modeCreateSnapshot}>
                          {this.renderCreateSnapshot()}
                        </Case>
                      </Switch>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="text-right">
                <Button color="secondary" onClick={(e) => this.closeDialog()}>Close</Button>
              </div>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Loadable>
        </Modal>
      </React.Fragment>
    );
  }
}