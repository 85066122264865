import React from "react";
import { Link } from "react-router-dom";
import { Consts } from "config/consts";
import { arrayToFormattedStr } from "config/utilities";

export class ExpandingText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxItems: this.props.startItems,
      maxLength: this.props.startLength,
    }
  }

  copyToClickBoard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
    if (this.props.displayNotification)
      this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Value copied to clipboard.")
  }

  render = () => {
    if (this.props.text) {
      return (
        <React.Fragment>
          {this.props.text.substring(0, this.state.maxLength)}
          {(this.props.text.length > this.state.maxLength && this.state.maxLength !== 999) ?
            <Link className={this.props.linkStyle} style={{ textDecoration: "underline" }} to="#" onClick={(e) => this.setState({ maxLength: this.props.text.length })}>. . .</Link>: null }
          {this.props.clipboard ?
            <React.Fragment>
              &nbsp;&nbsp;<Link className={this.props.linkStyle} to="#" onClick={(e) => this.copyToClickBoard(this.props.text)}><i className="fal fa-copy" /></Link>
            </React.Fragment> : null}
        </React.Fragment>
      )
    }

    if (this.props.items) {
      return (
        <React.Fragment>
          {arrayToFormattedStr(this.props.items, this.state.maxItems, this.props.replaceArray)}
          {(this.props.items.length > this.state.maxItems && this.state.maxItems !== 999) ?
            <Link className="text-success" style={{ textDecoration: "underline" }} to="#" onClick={(e) => this.setState({ maxItems: this.props.items.length })}>. . .</Link>
            : null
          }
        </React.Fragment>
      );
    }

    return (
      null
    );
  }
}