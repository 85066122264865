import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, FormGroup, Input } from "reactstrap";
import { Consts } from "config/consts";
import { FirebaseFuncts } from "config/firebase";
import Loadable from "react-loading-overlay";

export class AddLicenseKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      key: ""
    }
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
        modified: true
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        modified: true
      });
    }
  }

  closeDialog = (addKey) => {
    if (addKey) {
      this.setState({ loader: true });
      let addLicenseKey = FirebaseFuncts.httpsCallable("addClientToExistingLicenseKey");
      addLicenseKey({
        machineKey: this.props.machineKey,
        machineName: this.props.machineName,
        licenseKey: this.state.key
      }).then((result) => {
        this.setState({ loader: false });
        console.log(result.data.text + " License key: " + result.data.licenseKey);
        this.props.displayDialog(Consts.MESSAGE_TYPE.INFO, "Machine " + this.props.machineName + " assigned to license key: " + result.data.licenseKey, () => {
          // nothing to do here
        });

        // done!
        this.props.onClose(this.state.key);
      }).catch((error) => {
        this.setState({ loader: false });
        this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "License key error", "There was an error assigning this machine to the key. " + error.message);
        console.log(error.message);
      });
    } else {
      this.props.onClose(null);
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="edit-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <Loadable active={this.state.loader} spinner text="Assigning machine to license key, please wait...">
            <ModalHeader close={closeBtn}>
              <Row>
                <Col xs={12}>
                  <h3 className="text-primary">
                    <i className="far fa-key" />&nbsp;Assign machine to existing license key
                  </h3>
                  <h5 className="text-primary-2">
                    Assign this machine ({this.props.machineName}) to an existing license key
                  </h5>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody id="classic-modal-slide-description">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12}>
                      <h5 className="text-success"><i className="fal fa-edit" />&nbsp;&nbsp;Enter license key<span className="text-success-2"></span></h5>
                      <FormGroup>
                        <Input name="key" type="text" value={this.state.key} onChange={(e) => this.handleChange(e)} />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="text-right">
                <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
                <Button color="default" onClick={(e) => this.closeDialog(true)}>Apply</Button>
              </div>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Loadable>
        </Modal>
      </React.Fragment>
    );
  }
}