import Register from "views/user/register.jsx";
import Login from "views/user/login.jsx";
import Logout from "views/user/logout.jsx";
import Install from "views/home/install.jsx";
import Home from "views/home/home.jsx";

var routes = [
  {
    path: "/user/register",
    name: "login",
    icon: "tim-icons icon-single-02",
    component: Register,
    layout: "/",
  },
  {
    path: "/user/login",
    name: "login",
    icon: "tim-icons icon-single-02",
    component: Login,
    layout: "/",
  },
  {
    path: "/user/logout",
    name: "logout",
    icon: "tim-icons icon-single-02",
    component: Logout,
    layout: "/",
  },
  {
    path: "/install",
    name: "install",
    icon: "tim-icons icon-single-02",
    component: Install,
    layout: "/",
  },
  {
    path: "/",
    name: "home",
    icon: "tim-icons icon-single-02",
    component: Home,
    layout: "/",
  }
];
export default routes;
