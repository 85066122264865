import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Form, FormGroup, Input, Label } from "reactstrap";
import { CanaryFileItem } from "components/common/canary-file-item";
import { Consts } from "config/consts";

export class EditCanaryFiles extends React.Component {
  constructor(props) {
    super(props);

    // do we have existing values
    let cfs = [];
    let tfs = [];
    let ot = false;
    if (this.props.existingFiles) {
      if (this.props.existingFiles.indexOf(";") === -1) {
        // no targets
        cfs = this.props.existingFiles.split(",");
      } else {
        // targets
        let g = this.props.existingFiles.split(";");
        for (let i = 0; i < g.length; i++) {
          cfs.push(g[i].split(",")[0]);
          tfs.push(g[i].split(",")[1]);
        }
        ot = true;
      }
    }

    this.state = {
      overrideTargets: ot,
      canaryFile: "",
      targetFile: "",
      canaryFiles: cfs,
      targetFiles: tfs
    }
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      if (e.target.name === "overrideTargets") {
        // make sure we can modify
        if (this.state.canaryFiles.length > 0) {
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You cannot modify this setting once you have added canary files, delete all canary files first.");
          return;
        }
      }
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  closeDialog = (save) => {
    if (!this.props.onUpdateCanaryFiles)
      return;

    if (!save) {
      this.props.onUpdateCanaryFiles(null);
      return;
    }

    // format output
    let result = "";
    if (this.state.overrideTargets) {
      // make sure cf and tf are ===
      if (this.state.canaryFiles.length !== this.state.targetFiles.length) {
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "There is an internal, please close the dialog and try again.");
        return;
      }

      // cf and tf
      for (let i = 0; i < this.state.canaryFiles.length; i++) {
        result = result + (result === "" ? this.state.canaryFiles[i] + "," + this.state.targetFiles[i] : ";" + this.state.canaryFiles[i] + "," + this.state.targetFiles[i]);
      }
    } else {
      // just cf
      for (let i = 0; i < this.state.canaryFiles.length; i++) {
        result = result + (result === "" ? this.state.canaryFiles[i] : "," + this.state.canaryFiles[i]);
      }
    }


    this.props.onUpdateCanaryFiles(result);
  }

  addCanaryFile = () => {
    let cf = this.state.canaryFile.toLowerCase();
    let tf = this.state.targetFile.toLowerCase();

    // validate
    if (!cf.endsWith(".txt") &&
        !cf.endsWith(".docx") &&
        !cf.endsWith(".xlsx") &&
        !cf.endsWith(".png") &&
        !cf.endsWith(".jpg")) {
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Invalid file extension for \"" + cf + "\"");
          return;
        }

    if (this.state.overrideTargets)
      if ((tf && tf.indexOf(":\\") === -1) || !tf) {
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "The target file format is incorrect for \"" + tf + "\"");
        return;
      }

    // all good, add
    let cfs = this.state.canaryFiles;
    let tfs = this.state.targetFiles;

    cfs.push(cf);
    if (tf)
      tfs.push(tf);

    this.setState({
      canaryFile: "",
      targetFile: "",
      canaryFiles: cfs,
      targetFiles: tfs
    })
  }

  deleteCanaryFile = (canaryFile) => {
    console.log(canaryFile)
    let cfs = this.state.canaryFiles;
    let tfs = this.state.targetFiles;

    for (let i = 0; i < cfs.length; i++) {
      if (cfs[i] === canaryFile) {
        console.log(i);
        console.log(cfs);
        cfs.splice(i, 1);
        if (tfs.length > i)
          tfs.splice(i, 1);
        console.log(cfs);

        // done
        this.setState({
          canaryFiles: cfs,
          targetFiles: tfs
        })
        return;
      }
    }
  }

  renderCanaryFiles = () => {
    return (
      <React.Fragment>
        {this.state.canaryFiles.map((cf, i) => {
          return (
            <CanaryFileItem canaryFile={cf} targetFile={this.state.targetFiles[i]} color="default" isOpen={true} onDelete={(e) => this.deleteCanaryFile(cf)} drive={this.props.drive} key={i} />
          )
        })}
      </React.Fragment>
    );
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="edit-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <ModalHeader close={closeBtn}>
            <Row>
              <Col xs={12}>
                <h3 className="text-primary">
                  <i className="far fa-browser" />&nbsp;Add canary files
                </h3>
                {this.state.fixedName ?
                  <h5 className="text-primary-2">
                    Add canary files the The Cyber Canary rule.
                  </h5>
                  : null
                }
                <h5 className="text-primary-2">
                  {/* <a href="https://support.division-m.com/hc/en-us/articles/" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">Click here</a> for details on how to add canary file to the rule being created. */}
                  There are two ways to override the default canary files, the first is to specify the canary file names only, using the inbuilt target files. The second is to override both the canary files and the target files.
                </h5>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col xs={12}>
                  <h5 className="text-primary">
                    <FormGroup check>
                      <Label check>
                        <Input name="overrideTargets" type="checkbox" checked={this.state.overrideTargets} onChange={(e) => this.handleChange(e)} />{' '}Override targets also?
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                        <div className="text-primary-2"><small>You will need to specify the target files which must exist on the file system when applying the rule.</small></div>
                      </Label>
                    </FormGroup>
                  </h5>
                </Col>
              </Row>
            </Form>
          </ModalHeader>
          <ModalBody id="classic-modal-slide-description">
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    {this.renderCanaryFiles()}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <hr style={{ borderColor: "#444444" }} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <h5 className="text-success"><i className="fal fa-plus-square" />&nbsp;&nbsp;Add canary files<span className="text-success-2"></span></h5>
                    <Form>
                      <Row>
                        <Col md={10}>
                          <FormGroup>
                            <Label>Canary file (File name only, do not add a path).
                              <Input name="canaryFile" placeholder="canary_file.txt" value={this.state.canaryFile} type="text" onChange={(e) => this.handleChange(e)}/>
                              {!this.state.overrideTargets ?
                                <div className="text-muted">Allowed file extensions are "txt", "docx", "xlsx", "png" and "jpg".</div> : null }
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      {this.state.overrideTargets ?
                        <Row>
                          <Col md={10}>
                            <FormGroup>
                              <Label>Target file (Include path, the file must exist)
                                <Input name="targetFile" placeholder="c:\folder\target_file.txt" value={this.state.targetFile} type="text" onChange={(e) => this.handleChange(e)}/>
                                <div className="text-muted">The same target file can be used for multiple canary files, and can exist on any accessible drive.</div>
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row> : null }
                    </Form>
                    <Row>
                      <Col md={10}>
                        <Button className="btn-link" color="success" onClick={(e) => this.addCanaryFile()} disabled={!this.state.canaryFile}>
                          Add canary file
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right">
              <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
              <Button color="default" onClick={(e) => this.closeDialog(true)} disabled={this.state.applicationSelected === null && this.state.processes.length === 0}>Apply</Button>
            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}