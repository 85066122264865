import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Consts } from "config/consts";
import { decodeRule, decodeAccess } from "config/utilities";

export class EditAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      access: this.props.rule ? decodeRule(this.props.rule).access : this.props.access,
      ruleName: decodeRule(this.props.rule).name,
      modified: false,
      listValue: "",
      readValue: "",
      writeValue: ""
    }
  }

  componentDidMount() {
    // decode access
    let list;
    let read;
    let write;

    if (decodeAccess(this.state.access, Consts.RULE_ACCESS.LIST_ALLOW)) {
      list = "L+";
    } else if (decodeAccess(this.state.access, Consts.RULE_ACCESS.LIST_DENIED)) {
      list = "L-";
    }

    if (decodeAccess(this.state.access, Consts.RULE_ACCESS.READ_ALLOW)) {
      read = "R+";
    } else if (decodeAccess(this.state.access, Consts.RULE_ACCESS.READ_DENIED)) {
      read = "R-";
    } else if (decodeAccess(this.state.access, Consts.RULE_ACCESS.READ_ENCRYPTED)) {
      read = "R*";
    }

    if (decodeAccess(this.state.access, Consts.RULE_ACCESS.WRITE_ALLOW)) {
      write = "W+";
    } else if (decodeAccess(this.state.access, Consts.RULE_ACCESS.WRITE_DENIED)) {
      write = "W-";
    } else if (decodeAccess(this.state.access, Consts.RULE_ACCESS.WRITE_REQUESTED)) {
      write = "W?";
    } else if (decodeAccess(this.state.access, Consts.RULE_ACCESS.WRITE_IMMUTABLE)) {
      write = "W1";
    } else if (decodeAccess(this.state.access, Consts.RULE_ACCESS.WRITE_ENCRYPTED)) {
      write = "W*";
    }

    this.setState({
      listValue: list,
      readValue: read,
      writeValue: write
    })
  }

  getAccessText = (type) => {
    if (type === "list") {
      return this.state.listValue.indexOf("+") !== -1 ? "Allow list" : "Deny list";
    }
    if (type === "read") {
      if (this.state.readValue.indexOf("+") !== -1)
        return "Allow read";
      if (this.state.readValue.indexOf("-") !== -1)
        return "Deny read";
      if (this.state.readValue.indexOf("*") !== -1)
        return "Decrypted read";
    }
    if (type === "write") {
      if (this.state.writeValue.indexOf("+") !== -1)
        return "Allow write";
      if (this.state.writeValue.indexOf("-") !== -1)
        return "Deny write";
      if (this.state.writeValue.indexOf("?") !== -1)
        return "Request write";
      if (this.state.writeValue.indexOf("1") !== -1)
        return "Immutable write";
      if (this.state.writeValue.indexOf("*") !== -1)
        return "Encrypted write";
    }

    return "???"
  }

  getValueIcon = (type) => {
    if (type === "list") {
      return this.state.listValue.indexOf("+") !== -1 ? "far fa-check-square" : "far fa-minus-square"
    }
    if (type === "read") {
      if (this.state.readValue.indexOf("+") !== -1)
        return "far fa-check-square";
      if (this.state.readValue.indexOf("-") !== -1)
        return "far fa-minus-square";
      if (this.state.readValue.indexOf("*") !== -1)
        return "far fa-lock-open";
    }
    if (type === "write") {
      if (this.state.writeValue.indexOf("+") !== -1)
        return "far fa-check-square";
      if (this.state.writeValue.indexOf("-") !== -1)
        return "far fa-minus-square";
      if (this.state.writeValue.indexOf("?") !== -1)
        return "far fa-question-square";
      if (this.state.writeValue.indexOf("1") !== -1)
        return "far fa-dice-one";
      if (this.state.writeValue.indexOf("*") !== -1)
        return "far fa-lock";
    }

    return "far fa-question-square";
  }

  setAccessValue = (type, value) => {
    let list = this.state.listValue;
    let read = this.state.readValue;
    let write = this.state.writeValue;
    if (type === "list") {
      list = value;
      if (list === "L-") {
        read = "R-";
        write = "W-"
      }
    }

    if (type === "read") {
      read = value;
      if (read === "R-") {
        write = "W-"
      }
    }

    if (type === "write") {
      write = value;
    }

    this.setState({
      listValue: list,
      readValue: read,
      writeValue: write,
      modified: true
    })
  }

  closeDialog = (save) => {
    if (!save) {
      if (this.props.rule) {
        this.props.onUpdateRule(null);
      } else {
        this.props.onUpdateAccess(null);
      }
    } else {
      if (this.props.rule) {
        // output rule
        let ro = decodeRule(this.props.rule);
        ro.access = "[" + this.state.listValue + this.state.readValue + this.state.writeValue + "]";
        this.props.onUpdateRule(this.state.modified ? ro : null);
      } else {
        // output rule
        let access = "[" + this.state.listValue + this.state.readValue + this.state.writeValue + "]";
        this.props.onUpdateAccess(this.state.modified ? access : null);
      }
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="edit-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <ModalHeader close={closeBtn}>
            <Row>
              <Col xs={12}>
                <h3 className="text-primary">
                  <i className="far fa-lock-open-alt" />&nbsp;Edit access
                </h3>
                <h5 className="text-primary-2">
                  Edit access for <span className="text-primary">{this.state.ruleName}</span><br/>
                  {/* <span className="h6 text-primary-2">
                    Access rule [{this.state.listValue + this.state.readValue + this.state.writeValue}]
                  </span> */}
                </h5>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody id="classic-modal-slide-description">
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <h5 className="text-success"><i className="fal fa-unlock" />&nbsp;&nbsp;Edit access<span className="text-success-2"></span></h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <UncontrolledDropdown group>
                      <DropdownToggle caret color="primary" data-toggle="dropdown" style={{ width: "220px" }}>
                        <i className={this.getValueIcon("list")} />&nbsp;{this.getAccessText("list")}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={(e) => this.setAccessValue("list", "L+")}><i className="far fa-check-square" />&nbsp;Allow list</DropdownItem>
                        <DropdownItem onClick={(e) => this.setAccessValue("list", "L-")}><i className="far fa-minus-square" />&nbsp;Deny list</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                  <Col xs={4}>
                    <UncontrolledDropdown group>
                      <DropdownToggle caret color="primary" data-toggle="dropdown" style={{ width: "220px" }}>
                        <i className={this.getValueIcon("read")} />&nbsp;{this.getAccessText("read")}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={(e) => this.setAccessValue("read", "R+")}><i className="far fa-check-square" />&nbsp;Allow read</DropdownItem>
                        <DropdownItem onClick={(e) => this.setAccessValue("read", "R-")}><i className="far fa-minus-square" />&nbsp;Deny read</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                  <Col xs={4}>
                    <UncontrolledDropdown group>
                      <DropdownToggle caret color="primary" data-toggle="dropdown" style={{ width: "220px" }}>
                        <i className={this.getValueIcon("write")} />&nbsp;{this.getAccessText("write")}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={(e) => this.setAccessValue("write", "W+")}><i className="far fa-check-square" />&nbsp;Allow write</DropdownItem>
                        <DropdownItem onClick={(e) => this.setAccessValue("write", "W-")}><i className="far fa-minus-square" />&nbsp;Deny write</DropdownItem>
                        <DropdownItem onClick={(e) => this.setAccessValue("write", "W?")}><i className="far fa-question-square" />&nbsp;Request write</DropdownItem>
                        <DropdownItem onClick={(e) => this.setAccessValue("write", "W1")}><i className="far fa-dice-one" />&nbsp;Immutable write</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right">
              <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
              <Button color="default" onClick={(e) => this.closeDialog(true)}>Apply</Button>
            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}