import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, FormGroup, Input } from "reactstrap";

export class EditEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email ? this.props.email : "",
      modified: false
    }
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
        modified: true
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        modified: true
      });
    }
  }

  closeDialog = (save) => {
    if (!save) {
        this.props.onUpdateEmail(null);
    } else {
        // validate


        // output name
        this.props.onUpdateEmail(this.state.modified ? this.state.email : null);
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="edit-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <ModalHeader close={closeBtn}>
            <Row>
              <Col xs={12}>
                <h3 className="text-primary">
                  <i className="far fa-edit" />&nbsp;Edit email
                </h3>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody id="classic-modal-slide-description">
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <h5 className="text-success">
                      <i className="fal fa-pen-square" />&nbsp;&nbsp;Edit email<span className="text-success-2"><br/><small>For multiple email addresses, separate with a ","</small></span>
                    </h5>
                    <FormGroup>
                      <Input name="email" type="text" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right">
              <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
              <Button color="default" onClick={(e) => this.closeDialog(true)}>Apply</Button>
            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}