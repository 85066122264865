import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { Consts } from "config/consts";
import { decodeRule, validateFolder } from "config/utilities";
import { FolderItem } from "components/common/folder-item";
import { EditRuleFolder } from "components/common/edit-rule-folder.jsx"

export class EditFolders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folders: this.props.rule ? decodeRule(this.props.rule).folders : this.props.folders,
      modified: false,
      folderSelected: this.props.drive.mountName,
      fixedName: this.props.rule ? decodeRule(this.props.rule).name : null
    }
  }

  deleteFolder = (folder) => {
    let f = this.state.folders;
    for (let i = 0; i < f.length; i++) {
      if (f[i] === folder) {
        f.splice(i, 1);
        break;
      }
    }
    this.setState({
      folders: f,
      modified: true,
    })
  }

  addFolder = () => {
    let f = this.state.folders;
    console.log(f);
    if (this.state.folderSelected.length === 2) {
      // root... throw an error
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You cannot add the drive root.");
      return;
    }

    // check we have not added it
    for (let i = 0; i < f.length; i++) {
      if (f[i] === validateFolder(this.state.folderSelected).toLowerCase()) {
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Folder already exists in the list.");
        return;
      }
    }

    // check for single, if so then clear before adding
    if (this.props.singleFolder)
      f = [];

    f.push(validateFolder(this.state.folderSelected).toLowerCase());
    this.setState({
      folders: f,
      modified: true,
      folderSelected: this.props.drive.mountName
    })
  }

  renderFolders = () => {
    return (
      <React.Fragment>
        {this.state.folders.map((folder, i) => {
          return(
            <FolderItem color="default" isOpen={true} onDelete={(e) => this.deleteFolder(folder)} folder={folder} drive={this.props.drive} key={i} />
          )
        })}
      </React.Fragment>
    );
  }

  folderSelected = (newFolder) => {
    this.setState({
      folderSelected: newFolder
    })
  }

  closeDialog = (save) => {
    if (!save) {
      if (this.props.rule) {
        this.props.onUpdateRule(null);
      } else {
        this.props.onUpdateFolders(null);
      }
    } else {
      if (this.props.rule) {
        // output rule
        let ro = decodeRule(this.props.rule);
        ro.folders = this.state.folders;
        let modified = this.state.modified;
        if (this.state.folderSelected.length > 2) {
          // check for single, if so then clear before adding
          if (this.props.singleFolder)
            ro.folders = [];
          ro.folders.push(validateFolder(this.state.folderSelected).toLowerCase());
          modified = true;
        }
        this.props.onUpdateRule(modified ? ro : null);
      } else {
        // output folder
        let f = this.state.folders;
        let modified = this.state.modified;
        if (this.state.folderSelected.length > 2) {
          // check for single, if so then clear before adding
          if (this.props.singleFolder)
            f = [];
          f.push(validateFolder(this.state.folderSelected).toLowerCase());
          modified = true;
        }
        this.props.onUpdateFolders(modified ? f : null);
      }
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="edit-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <ModalHeader close={closeBtn}>
            <Row>
              <Col xs={12}>
                <h3 className="text-primary">
                  <i className="far fa-folders" />&nbsp;Edit {this.props.singleFolder ? "folder" : "folders"}
                </h3>
                {this.state.fixedName ?
                  <h5 className="text-primary-2">
                    Edit folder for <span className="text-primary">{this.state.fixedName}</span>
                  </h5>
                  : null
                }
                <a href="https://support.division-m.com/hc/en-us/articles/360001053275" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">Click here</a> for details on how to add or remove folder.
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody id="classic-modal-slide-description">
            <Card>
              <CardBody>
                {this.state.folders.length > 0 && !this.props.singleFolder ?
                  <React.Fragment>
                    <Row>
                      <Col xs={12}>
                        <h5 className="text-success"><i className="fal fa-folder-times" />&nbsp;&nbsp;Remove existing {this.props.singleFolder ? "folder" : "folders"}<span className="text-success-2"></span></h5>
                        {this.renderFolders()}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <hr style={{ borderColor: "#444444" }} />
                      </Col>
                    </Row>
                    </React.Fragment>
                : null}
                <Row>
                  <Col xs={12}>
                    <h5 className="text-success"><i className="fal fa-folder-plus" />&nbsp;&nbsp;Add a new folder<span className="text-success-2">&nbsp;{this.props.singleFolder ? "- This rule only allows a single folder" : null}</span></h5>
                    <EditRuleFolder folder={this.state.folderSelected} drive={this.props.drive} onChange={(newFolder) => { this.folderSelected(newFolder) }} placeHolder="Select a folder" />
                    {!this.props.singleFolder ?
                      <div className="text-right">
                        <Button className="btn-link" color="success" onClick={(e) => this.addFolder()} disabled={!this.state.folderSelected}>
                          Add this folder
                        </Button>
                      </div>
                    : null
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right">
              <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
              <Button color="default" onClick={(e) => this.closeDialog(true)} disabled={this.state.folderSelected.length <= 2 && this.state.folders.length === 0}>Apply</Button>
            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}