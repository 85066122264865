import React from "react";

export class ValidateRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rule: null,
      ruleValidationResult: null,
      ruleSecurityLevel: null,
    }

    this.validating = false;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.rule !== state.rule) {
      state.rule = props.rule;
      state.ruleValidationResult = "Checking rule validation...";
      state.ruleSecurityLevel = -1;
      return state;
    }
    return null;
  }

  componentDidMount() {
    if (this.state.ruleSecurityLevel === -1 && !this.validating) {
      this.updateRuleValidation(null);
    }
  }

  componentDidUpdate() {
    if (this.state.ruleSecurityLevel === -1 && !this.validating) {
      this.updateRuleValidation(null);
    }
  }

  updateRuleValidation = (rule) => {
    this.validating = true;
    window.roFolderWall.RuleValidation(this.props.mountPointId, this.props.ruleId, "", "", "", (blockingRule, securityLevel, validationResult) => {
      this.setState({
        ruleValidationResult: validationResult,
        ruleSecurityLevel: securityLevel,
      }, () => this.validating = false
      )
    }, (error) => {
      console.log(error);
    })
  }

  render = () => {
    if (this.state.ruleSecurityLevel !== -1) {
      if (this.state.ruleSecurityLevel >= 9) {
        return (
          <h5>
            <small className="text-success"><i className="far fa-check-circle" />&nbsp;{this.state.ruleValidationResult}&nbsp;&nbsp;Security score {this.state.ruleSecurityLevel}/10</small>
          </h5>
        )
      } else if (this.state.ruleSecurityLevel === 0) {
        return (
          <h5>
            <small className="text-danger"><i className="far fa-skull-crossbones" />&nbsp;{this.state.ruleValidationResult}&nbsp;&nbsp;Security score {this.state.ruleSecurityLevel}/10</small>
          </h5>
        );
      } else {
        return (
          <h5>
            <small className="text-warning"><i className="far fa-exclamation-circle" />&nbsp;{this.state.ruleValidationResult}&nbsp;&nbsp;Security score {this.state.ruleSecurityLevel}/10</small>
          </h5>
        );
      }
    } else {
      return (
        null
      );
    }
  }
}