import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, FormGroup, Input, Label } from "reactstrap";
import { Consts, LicenseAgreementText } from "config/consts";
import { getWebAppValue } from "config/utilities";

import anvil from "assets/img/anvil_action_dark_100x100.png";

export class LicenseAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agree: false
    }
  }

  closeDialog = (agree) => {
    if (!this.props.onClose)
      return;
    this.props.onClose(agree);
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="installer-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <ModalHeader close={closeBtn}>
            <Row>
              <Col xs={2}>
                <img src={anvil} alt={getWebAppValue(Consts.WEB_APP_VALUE.NAME)} />
              </Col>
              <Col xs={10}>
                <h3 className="text-info" style={{ marginTop: "15px" }}>{getWebAppValue(Consts.WEB_APP_VALUE.FULL_NAME)} license agreement</h3>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody id="classic-modal-slide-description">
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label>Agreement</Label>
                      <Input type="textarea" value={LicenseAgreementText} readOnly={true} style={{ minHeight: "300px", color: "#EEEEEE" }} />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right">
              <Button color="danger" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
              <Button color="default" onClick={(e) => { this.closeDialog(true) }}>Accept agreement</Button>
            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}