import React from "react";
import { Alert, Badge } from "reactstrap";

const styleAlertItem = {
  fontSize: "0.9em",
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: "15px",
  paddingRight: "15px"
}

export class FolderItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folderValid: null
    }
  }

  componentDidMount() {
    if (this.props.drive) {
      window.roFolderWall.GetFolderDetails(this.props.drive.mountName + this.props.folder, false, (valid, folderDetails) => {
        this.setState({
          folderValid: valid
        })
      }, (error) => {
        console.log(error)
      })
    }
  }

  render = () => {
    if (this.props.drive && this.state.folderValid != null) {
      return (
        <Alert style={styleAlertItem} color={this.props.color} isOpen={this.props.isOpen} toggle={this.props.onDelete}>
          <i className="fal fa-folder" />
          &nbsp;&nbsp;
          {this.props.folder}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Badge color={this.state.folderValid ? "success" : "danger"} style={{ width: "150px" }}>
            <span className={this.state.folderValid ? "text-dark" : ""}>
              {this.state.folderValid ? "Folder is valid" : "Folder is missing"}
            </span>
          </Badge>
        </Alert>
      );
    } else {
      return (
        <Alert style={styleAlertItem} color={this.props.color} isOpen={this.props.isOpen} toggle={this.props.onDelete}>
          <i className="fal fa-folder" />&nbsp;&nbsp;{this.props.folder}
        </Alert>
      );
    }
  }
}