import React from "react";
import { Button, Row, Col } from "reactstrap";
import { SelectFilter } from "components/common/select-filter.jsx";
import Switch, { Case } from "react-switch-case";
import { encodeRuleArray, validateFolder } from "config/utilities.js";

const moreFolders = "... more folders";
const backUpFolder = ".. back up folder";

export class EditRuleFolder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reload: false,
      folder: null,
      initialValue: null,
      modified: false,
      folderList: [],
      closed: false,
      confirm: null
    }
    this.getMoreFolders = false;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.folder !== state.initialValue) {
      if (props.rule !== undefined) {
        // use rule
        if (props.folderIndex >= 0 && props.rule.folders[props.folderIndex] !== "*") {
          state.folder = validateFolder(props.drive.mountName + props.rule.folders[props.folderIndex], true, true);
        } else {
          state.folder = validateFolder(props.drive.mountName, true, true);
        }
      } else {
        // use folder
        if (props.folder) {
          state.folder = validateFolder(props.folder, true, true);
        } else {
          state.folder = validateFolder(props.drive.mountName, true, true);
        }
      }

      state.initialValue = props.folder;
      state.reload = true;
      state.folderList = [];
      return state;
    }

    return null;
  }

  componentDidMount() {
    if (this.state.reload) {
      this.setState({
        reload: false
      }, () => {
          this.getMoreFolders = true;
          this.getDriveFolders(this.state.folder + "*.*");
      })
    }
  }

  componentDidUpdate() {
    if (this.state.reload) {
      this.setState({
        reload: false
      }, () => {
        this.getMoreFolders = true;
        this.getDriveFolders(this.state.folder + "*.*");
      })
    }
  }

  handleChanged = (newValue) => {
    let t = newValue;

    // more folders??
    if (t === moreFolders) {
      this.getMoreFolders = true;
      this.getDriveFolders(this.state.folder + "*.*");
      return;
    }

    // up folder??
    if (t === backUpFolder) {
      let s = this.state.folder.split("\\")
      //console.log(s);
      t = "";
      for (var i = 0; i < s.length - 2; i++)
        t = validateFolder(t + s[i], true, true);

      this.setState({
        folder: t,
        folderList: [],
        folderListContextId: null,
        modified: true,
      }, () => {
        this.getMoreFolders = true;
        this.getDriveFolders(this.state.folder + "*.*");
          if (this.props.onChange)
            this.props.onChange(validateFolder(this.state.folder, true));
      });
      return;
    }

    // simple selection
    this.setState({
      folder: validateFolder(this.state.folder + t, true, true),
      folderList: [],
      folderListContextId: null,
      modified: true
    }, () => {
      this.getMoreFolders = true
      this.getDriveFolders(this.state.folder + "*.*");
      if (this.props.onChange)
        this.props.onChange(validateFolder(this.state.folder, true));
    });
  };

  updateRule = (save) => {
    if (save) {
      let rule = this.props.rule;
      let folder = this.state.folder === "<DELETED>" ? "" : this.state.folder.substring(2, this.state.folder.length - 1).toLowerCase();

      // if no folder, then remove if multple folders, otherwise set to wildcard
      if (!folder) {
        if (rule.folders.length > 1) {
          // remove
          rule.folders.splice(this.props.folderIndex, 1);
        } else {
          // wildcard
          folder = "*";
          rule.folders[this.props.folderIndex] = folder;
        }
      } else {
        // new item?
        if (this.props.folderIndex === -1) {
          // yes, is there just 1 item?
          if (rule.folders.length === 1) {
            // if existing wildcard, update
            if (rule.folders[0] === "*") {
              rule.folders[0] = folder;
            } else {
              // add new
              rule.folders.push(folder);
            }
          } else {
            // add new
            rule.folders.push(folder);
          }
        } else {
          // no
          rule.folders[this.props.folderIndex] = folder;
        }
      }

      // make sure encoded folderstr correct
      rule.foldersStr = encodeRuleArray(rule.folders);

      // done
      this.setState({
        rule: rule,
        closed: true
      }, () => {
        this.props.onClose(rule);
      })
    } else {
      this.props.onClose(null);
    }
  }

  getDriveFolders = (filter) => {
    this.getMoreFolders = false;
    this.state.folderListContextId ? console.log("Appending folders for: %s", filter) : console.log("Getting folders for: %s", filter)
    window.roFolderWall.GetFolders(filter, 100, this.state.folderListContextId, (count, folders, moreItemsAvailable, contextId) => {
      let f = this.state.folderList;

      // remove more folders
      if (f.length > 0 && f[f.length - 1] === moreFolders)
        f.splice(-1, 1);

      // add up folder if not root (aka c:\*.*)
      if (f.length === 0 && filter.length > 6) {
        let o = { label: backUpFolder, value: backUpFolder }
        f.push(o);
      }

      // add new items
      folders.items.forEach((folder) => {
        let o = { label: folder, value: folder }
        f.push(o);
      })

      // if more available, add entry
      if (moreItemsAvailable) {
        let o = { label: moreFolders, value: moreFolders }
        f.push(o);
      }

      // done...
      this.setState({
        folderList: f,
        folderListContextId: moreItemsAvailable ? contextId : ""
      }, () => {
        console.log("The folder %s has loaded", filter);
    })
    }, (msg) => {
      console.log("GetFolders Error!")
    });
  }

  deleteFolder = () => {
    this.setState({
      confirm: "Are you sure you want to delete this folder?"
    })
  }

  confirmDeleteFolder = () => {
    this.setState({
      confirm: null,
      folder: "<DELETED>",
      modified: true
    })
  }

  cancelDeleteFolder = () => {
    this.setState({
      confirm: null
    })
  }

  render = () => {
    if (this.state.closed)  // we were getting a setstate error from parent when it was hidding this... lets do it here just before... issue seems to be resolved
      return null;

    return (
      <div>
        {this.state.confirm ?
          <h4>
            <span className="text-danger">{this.state.confirm}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button color="info" size="sm" style={{ height: "30px", lineHeight: "0.6" }} onClick={() => { this.confirmDeleteFolder() }}>Y</Button>
            <Button color="info" size="sm" style={{ height: "30px", lineHeight: "0.6" }} onClick={() => { this.cancelDeleteFolder() }}>N</Button>
          </h4> : null}
        {this.state.folder === "<DELETED>" ? <span className="text-danger">{"<DELETED>"}</span> :
          <Row>
            <Col xs={12}>
              <div className="text-light" style={{ float: "left", lineHeight: "2.7"}}>
                {this.state.folder}&nbsp;
              </div>
              <div style={{ float: "none" }}>
                <SelectFilter readonly={false} options={this.state.folderList} clearOnSelect={true} onChange={(newValue) => { this.handleChanged(newValue) }} placeHolder={this.props.placeHolder} />
              </div>
            </Col>
          </Row>
        }
        <Switch condition={this.props.rule !== undefined}>
          <Case value={true}>
            <Row>
              <Col xs={6}>
                <Button color="danger" size="sm" onClick={() => { this.deleteFolder() }}>Delete</Button>
              </Col>
              <Col xs={6}>
                <div className="text-right">
                  <Switch condition={this.state.modified}>
                    <Case value={true}>
                      <Button color="secondary" size="sm" onClick={() => { this.updateRule(false) }}>Cancel</Button>
                      <Button color="rose" size="sm" onClick={() => { this.updateRule(true) }}>Update rule</Button>
                    </Case>
                    <Case value={false}>
                      <Button color="secondary" size="sm" onClick={() => { this.updateRule(false) }}>Close</Button>
                    </Case>
                  </Switch>
                </div>
              </Col>
            </Row>
          </Case>
        </Switch>
      </div>
    );
  }
}