import React from "react";
import { Link } from "react-router-dom";
import { decodeRule, encodeRule, arrayToFormattedStr, mergeRuleObj, createRuleSet } from "config/utilities";
//import { Consts } from "config/consts";
import Switch, { Case } from "react-switch-case";
import { Consts } from "config/consts";
import { EditName } from "components/dialogs/edit-name";
import { EditFolders } from "components/dialogs/edit-folders";
import { EditApplications } from "components/dialogs/edit-applications";
import { ValidateRule } from "components/common/validate-rule";
import { ExpandingText } from "components/common/expanding-text"

const dialogsHidden = 0;
const dialogEditName = 1;
const dialogEditFolders = 2;
const dialogEditApplications = 3;

export class RuleEncryptedFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rule: null,
      ruleObj: null,
      blockingRule: null,
      displayDialog: dialogsHidden,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.rule !== state.rule) {
      state.rule = props.rule;
      state.ruleObj = decodeRule(props.rule);

      // get rule set to get blocking rule
      state.ruleSetObj = createRuleSet(decodeRule(props.rule), props.drive.rules);
      state.ruleSetObj.ruleObjs.forEach((ro) => {
        if (ro.id !== state.ruleObj.id) {
          // blocking rule (last one in list)
          state.blockingRule = ro;
        }
      });
      return state;
    }
    return null;
  }

  editName = () => {
    this.setState({
      displayDialog: dialogEditName
    })
  }

  editFolders = () => {
    // root... throw an error
    this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You cannot edit an encrypted folder.");
  }

  editApplications = () => {
    this.setState({
      displayDialog: dialogEditApplications
    })
  }

  deleteRule = (ruleObj) => {
    this.props.onDeleteRule(ruleObj);
  }

  updateRule = (ruleObj) => {
    this.setState({
      displayDialog: dialogsHidden
    })
    if (ruleObj) {
      let rules = mergeRuleObj(ruleObj, this.props.drive.rules);
      this.props.onUpdateDriveRules(rules, false);
    }
  }

  booleanToStr = (value) => {
    if (value)
      return "Yes";
    return "No";
  }

  render = () => {
    return (
      <React.Fragment>
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogEditName}>
            <EditName {...this.props} rule={this.props.rule} drive={this.props.drive} onUpdateRule={this.updateRule} />
          </Case>
          <Case value={dialogEditFolders}>
            <EditFolders {...this.props} rule={this.props.rule} drive={this.props.drive} onUpdateRule={this.updateRule} singleFolderOnly="true" />
          </Case>
          <Case value={dialogEditApplications}>
            <EditApplications {...this.props} rule={this.props.rule} drive={this.props.drive} onUpdateRule={this.updateRule} />
          </Case>
        </Switch>
        <h4 className="text-warning">BETA FEATURE</h4>
        <h3 className="text-primary">
          <i className="far fa-key" />&nbsp;&nbsp;<span className="h4 text-primary">{this.state.ruleObj.name}</span>
          &nbsp;
          <sup className="h5">
            <Link to="#" onClick={(e) => this.editName()} style={{ color: "#e14eca" }}>
              <i className="far fa-edit" />
            </Link>
          </sup>
        </h3>
        <h5 className="text-muted">
          <ExpandingText text={this.state.ruleObj.metadata.DESC} startLength="165" linkStyle="text-muted" />
        </h5>
        <h5>
          <span className="text-success-2">
            Encrypted folder
            &nbsp;&nbsp;
            <span className="text-success">{arrayToFormattedStr(this.state.ruleObj.folders)}</span>
            &nbsp;&nbsp;
            <Link to="#" onClick={(e) => this.editFolders()}>
              <span className="text-success-2">
                <i className="far fa-edit" />
              </span>
            </Link>
          </span>
        </h5>
        <h5>
          <span className="text-success-2">
            Accessible application(s)
            &nbsp;&nbsp;
            <span className="text-success">
              <ExpandingText items={this.state.ruleObj.processes} startItems="2" linkStyle="text-success" replaceArray={Consts.PROCESS_REPLACE_ARRAY} />
            </span>
            &nbsp;&nbsp;
            <Link to="#" onClick={(e) => this.editApplications()}>
              <span className="text-success-2">
                <i className="far fa-edit" />
              </span>
            </Link>
          </span>
        </h5>
        <ValidateRule mountPointId={this.props.drive.rootPath} rule={encodeRule(this.state.ruleObj)} ruleId={this.state.ruleObj.id} />
        <div className="text-right" style={{ marginTop: "20px" }}>
          <Link to="#">
            <span className="text-primary" style={{ marginRight: "20px" }} onClick={(e) => this.deleteRule(this.state.ruleObj)}>
              <i className="far fa-trash-alt" />
            </span>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}