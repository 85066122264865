import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { Consts } from "config/consts";
import { Firestore } from "config/firebase";
import { FirebaseFuncts } from "config/firebase";
import Loadable from "react-loading-overlay";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { getWebAppValue } from "config/utilities";

const actionSave = -1;
const actionDialogHidden = 0;         // as per caller
const actionDialogAddLicenseKey = 5;  // as per caller

export class ManageLicenses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      licenses: [],
      machines: [],
      selected: null,
      pendingDeactivateMachines: [],
      pendingDeleteMachines: []
    }
  }

  componentDidMount() {
    this.setState({ loader: true });
    this.getLicenses();
    this.getMachines();
  }

  getLicenses = () => {
    // get all licenses
    let licenses = [];
    Firestore.collection("users").doc(this.props.getAuthContext().state.uid).collection("licenses").get().then((querySnapshot) => {
      for(let i = 0; i < querySnapshot.docs.length; i++) {
        let doc = querySnapshot.docs[i];
        let license = doc.data();
        if (license.license_type.indexOf("trial") === -1 &&
           ((Consts.SYSTEM.PRODUCT_ID !== "tcc" && license.license_type.indexOf("anvil") !== -1) || (Consts.SYSTEM.PRODUCT_ID === "tcc" && license.license_type.indexOf("tcc") !== -1))) {
          license.license_key = doc.id;
          try {
            // not sure why, but converting this cause an exception after the iteration????
            let d = new Date(license.renewal_date.seconds * 1000);
            d.setDate(d.getDate() + license.license_days + 1);
            license.expires = d.toDateString();
          } catch(e) {};
          let option = {
            label: doc.id,
            value: license
          }

          if (license.license_key === this.props.licenseKey) {
            this.setState({
              selected: option
            });
          }
          licenses.push(option);
        }
      }

      this.setState({
        loader: this.state.licenses.length > 0 && this.state.machines.length > 0,
        licenses: licenses
      })
    }).catch(function (error) {
      console.log(error);
    });
  }

  getMachines = () => {
    // get machines
    let machines = [];
    Firestore.collection("users").doc(this.props.getAuthContext().state.uid).collection("machines").get().then((querySnapshot) => {
      for(let i = 0; i < querySnapshot.docs.length; i++) {
        let doc = querySnapshot.docs[i];
        let machine = doc.data();
        machine.machine_key = doc.id;
        //console.log(machine);
        machines.push(machine);
      }
      this.setState({
        loader: this.state.licenses.length > 0 && this.state.machines.length > 0,
        machines: machines
      })
    }).catch(function (error) {
      console.log(error);
    });
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
        modified: true
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        modified: true
      });
    }
  }

  handleSelect = (e) => {
    this.setState({
      selected: e,
      pendingDeactivateMachines: [],
      pendingDeleteMachines: []
    })
  };

  closeDialog = (action) => {
    if (action === actionSave) {
      this.setState({ loader: true });

      // record how many calls we need to make
      let calls = this.state.pendingDeactivateMachines.length + this.state.pendingDeleteMachines.length;

      // save deactivated
      let dm = this.state.pendingDeactivateMachines;
      dm.forEach((key) => {
        let deactivateLicenseKey = FirebaseFuncts.httpsCallable("removeClientFromExistingLicenseKey");
        deactivateLicenseKey({
          machineKey: key,
          licenseKey: this.state.selected.value.license_key
        }).then((result) => {
          console.log(result.data.text + " License key: " + result.data.licenseKey);
          this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Machine " + result.data.machineName + " with license key: " + result.data.licenseKey + " has been deactivated");
          calls--;

          // done?
          if (calls === 0) {
            // reset
            this.setState({
              licenses: [],
              machines: [],
              selected: null,
              pendingDeactivateMachines: [],
              pendingDeleteMachines: []
            }, () => {
              this.getLicenses();
              this.getMachines();
            })
          }
        });
      });

      // save deactivated
      dm = this.state.pendingDeleteMachines;
      dm.forEach((key) => {
        let deleteLicenseKey = FirebaseFuncts.httpsCallable("deleteClientFromExistingLicenseKey");
        deleteLicenseKey({
          machineKey: key,
          licenseKey: this.state.selected.value.license_key
        }).then((result) => {
          console.log(result.data.text + " License key: " + result.data.licenseKey);
          this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Machine " + result.data.machineName + " with license key: " + result.data.licenseKey + " has been deleted");
          calls--;

          // done?
          if (calls === 0) {
            // reset
            this.setState({
              licenses: [],
              machines: [],
              selected: null,
              pendingDeactivateMachines: [],
              pendingDeleteMachines: []
            }, () => {
              this.getLicenses();
              this.getMachines();
            })
          }
        });
      });
    } else {
      this.props.onClose(action);
    }
  }

  deactivateLicenseKey = (key) => {
    let pm = this.state.pendingDeactivateMachines;
    pm.push(key);
    this.setState({
      pendingDeactivateMachines: pm
    });
  }

  deleteLicenseKey = (key) => {
    let pm = this.state.pendingDeleteMachines;
    pm.push(key);
    this.setState({
      pendingDeleteMachines: pm
    });
  }

  isMachineLicensed = (machine) => {
    for (let i = 0; i < this.state.selected.value.machines.length; i++) {
      let m = this.state.selected.value.machines[i];
      if (m === machine)
        return(true)
    }
    return false;
  }

  getLicenseType = (type) => {
    if (type === "anvil_trial")
      return "Anvil trial";
    if (type === "anvil_standard")
      return "Anvil standard";
    if (type === "anvil_premium")
      return "Anvil premium";
    if (type === "anvil_enterprise")
      return "Anvil enterprise";
    if (type === "tcc_trial")
      return "The Cyber Canary trial";
    if (type === "tcc_standard")
      return "The Cyber Canary standard";
    if (type === "tcc_premium")
      return "The Cyber Canary premium";
      if (type === "tcc_enterprise")
      return "The Cyber Canary enterprise";
  }

  renderMachineKey = (machine) => {
    // resolve key
    let machineRender = {
      machine_key: "N/A",
      machine_name: "N/A",
      last_accessed: "N/A",
      deactivated: false,
      pendingDeactivate: false,
      pendingDelete: false
    };

    // get machine details
    machineRender.machine_key = machine.machine_key;
    machineRender.machine_name = machine.machine_name;
    machineRender.last_accessed = machine.last_check_date ? machine.last_check_date.toDate().toDateString() : machineRender.last_accessed;
    machineRender.deactivated = machine.deactivated ? machine.deactivated : machineRender.deactivated;

    // check for pending deactivate
    for (let i = 0; i < this.state.pendingDeactivateMachines.length; i++) {
      if (this.state.pendingDeactivateMachines[i] === machineRender.machine_key) {
        machineRender.pendingDeactivate = true;
        break;
      }
    }

    // check for pending delete
    for (let i = 0; i < this.state.pendingDeleteMachines.length; i++) {
      if (this.state.pendingDeleteMachines[i] === machineRender.machine_key) {
        machineRender.pendingDelete = true;
        break;
      }
    }

    let activeState = "Machine is active";
    if (machineRender.pendingDeactivate)
      activeState = "Machine pending deactivation (cancel to abort)";
    if (machineRender.deactivated)
      activeState = "Machine is deactivated";
    if (machineRender.pendingDelete)
      activeState = "Machine pending deletion (cancel to abort)";

    return(
      <React.Fragment>
        <Row>
          <Col xs={2}>
            <h5 className="text-primary-2">
              {machineRender.machine_name}
            </h5>
          </Col>
          <Col xs={9}>
            <h5 className="text-success-2" style={{ fontSize: "14px" }}>
              {machineRender.machine_key}
              <div style={{height: "2px"}}><br/></div>
              <span className="text-success">{activeState}</span>
              {!machineRender.pendingDeactivate && !machineRender.deactivated ?
                <span className="text-success-2">, last accessed - {machineRender.last_accessed}</span> : null
              }
            </h5>
          </Col>
          <Col xs={1}>
            {!machineRender.deactivated && !machineRender.pendingDeactivate ?
              <h5 className="text-primary">
                <Link to="" className="text-primary" style={{ textDecoration: "underline" }} onClick={(e) => this.deactivateLicenseKey(machineRender.machine_key)}><i className="far fa-minus-circle" /></Link>
              </h5>
              : null
            }
            {machineRender.deactivated && !machineRender.pendingDelete ?
              <h5 className="text-primary">
                <Link to="" className="text-primary" style={{ textDecoration: "underline" }} onClick={(e) => this.deleteLicenseKey(machineRender.machine_key)}><i className="far fa-trash" /></Link>
              </h5>
              : null
            }
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="edit-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <Loadable active={this.state.loader} spinner text="Getting license details, please wait...">
            <ModalHeader close={closeBtn}>
              <Row>
                <Col xs={12}>
                  <h3 className="text-primary">
                    <i className="far fa-key" />&nbsp;Manage {getWebAppValue(Consts.WEB_APP_VALUE.NAME)} license key(s)
                  </h3>
                  <h5 className="text-primary-2">
                    <Link to="" className="text-primary-2" style={{ textDecoration: "underline" }} onClick={(e) => this.closeDialog(actionDialogAddLicenseKey)}>Assign a new license key</Link> to this machine ({this.props.machineName})
                  </h5>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody id="classic-modal-slide-description">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12}>
                      <div className="text-light" style={{ float: "left", lineHeight: "2.7"}}>
                          License key&nbsp;&nbsp;
                      </div>
                      <div style={{ float: "none" }}>
                        <Select
                          value={this.state.selected}
                          options={this.state.licenses}
                          onChange={e => this.handleSelect(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <br/>
                  {this.state.selected ?
                    <React.Fragment>
                      <Row>
                        <Col xs={12}>
                          <p className="text-light">
                            Licensed key is <span className="text-success">{this.state.selected.value.license_key}</span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <p className="text-light">
                            Licensed type is <span className="text-success">{this.getLicenseType(this.state.selected.value.license_type)}</span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          {this.state.selected.value.license_days === -1 ?
                            <p className="text-light">
                              Licensed for <span className="text-success">life-time</span> usage
                            </p>
                          :
                            <p className="text-light">
                              Licensed for <span className="text-success">{this.state.selected.value.license_days}</span> days<br/>
                              License expires <span className="text-success">{this.state.selected.value.expires}</span>
                            </p>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <p className="text-light">
                            Licensed for <span className="text-success">{this.state.selected.value.max_clients === -1 ? "unlimited number of" : this.state.selected.value.max_clients}</span> machine(s)
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={2}>
                          <p className="text-success">
                            Name
                          </p>
                        </Col>
                        <Col xs={9}>
                          <p className="text-success">
                            Key
                          </p>
                        </Col>
                      </Row>
                      {this.state.machines.map((machine, i) => {
                        if (machine.license_key === undefined || machine.license_key === this.state.selected.value.license_key) {
                          return (
                            <React.Fragment key={i}>
                              {this.renderMachineKey(machine)}
                            </React.Fragment>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </React.Fragment>
                  : null}
                </CardBody>
              </Card>
              <div className="text-right">
                <Button color="default" onClick={(e) => this.closeDialog(actionDialogHidden)}>{this.state.pendingDeactivateMachines.length > 0 || this.state.pendingDeleteMachines.length > 0 ? "Cancel" : "Close"}</Button>
                {this.state.pendingDeactivateMachines.length > 0 || this.state.pendingDeleteMachines.length > 0 ?
                  <Button color="default" onClick={(e) => this.closeDialog(actionSave)}>Apply</Button> : null
                }
              </div>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Loadable>
        </Modal>
      </React.Fragment>
    );
  }
}