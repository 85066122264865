import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Form, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import Switch, { Case } from "react-switch-case";
import Loadable from "react-loading-overlay";
import { Consts } from "config/consts";
import { arrayToFormattedStr, encodeRuleArray } from "config/utilities";
import { EditName } from "components/dialogs/edit-name";
import { EditFolders } from "components/dialogs/edit-folders";
import { EditApplications } from "components/dialogs/edit-applications";

const ruleSetTypeId = "encrypted_folder";
const dialogsHidden = 0;
const dialogEditName = 1;
const dialogEditFolder = 2;
const dialogEditApplications = 3;

const ROGroupWindowsScan = 3;

export class NewEncryptedFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      ruleSet: null,
      params: {
        BETA_TESTING: false,
        TITLE: "Encrypted folder",
        APPS: [],
        FOLDER: [],
        PASSPHRASE: "",
        PASSPHRASE_CONFIRM: "",
        USING_AUTO_KEY: false,
        AUTO_KEY_LOC: "",
        ADD_EXPLORER_LOCATION: false,
        EXPLORER_LOCATION: null
      },
      maxProcessesToShow: 4,
      displayDialog: dialogsHidden
    }
  }

  componentDidMount() {
    window.roFolderWall.GetAllRuleSets(this.props.drive.id, (rs) => {
      try {
        let ruleSets = JSON.parse(rs);
        let rso = ruleSets["RULE_SETS"];
        for (let key in rso) {
          if (rso.hasOwnProperty(key)) {
            if (rso[key].TYPE_ID === ruleSetTypeId) {
              this.setState({
                loader: false,
                params: { ...this.state.params, TITLE: rso[key].DETAILS.NAME }
              });
              break;
            }
          }
        }
      } catch (err) {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "There was an error loading the required content! (error code 104)");
        console.log(err)
        console.log(rs);
      }
    }, (error) => {
      this.setState({ loader: false });
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
    });

    // get explorer details
    window.roFolderWall.GetApplications(ROGroupWindowsScan, "", false, 1000, "", (count, processes, moreItemsAvailable, contextId) => {
      processes.items.forEach((process) => {
        if (process.path.value.indexOf('explorer.exe') > 0) {
          this.setState({ params: { ...this.state.params, EXPLORER_LOCATION: process.path.value } });
        }
      })
    }, (error) => {

    });
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ params: { ...this.state.params, [e.target.name]: e.target.checked } });
    } else {
      this.setState({ params: { ...this.state.params, [e.target.name]: e.target.value } });
    }
  }

  editName = () => {
    this.setState({
      displayDialog: dialogEditName
    })
  }

  editFolder = () => {
    this.setState({
      displayDialog: dialogEditFolder
    })
  }

  editApplications = () => {
    this.setState({
      displayDialog: dialogEditApplications
    })
  }

  updateName = (newName) => {
    this.setState({
      params: newName ? { ...this.state.params, TITLE: newName } : this.state.params,
      displayDialog: dialogsHidden,
      nameModified: true
    })
  }

  updateFolders = (newFolder) => {
    let p = this.state.params;
    p.FOLDER = newFolder ? newFolder : p.FOLDER;

    // update name??
    if (newFolder && !this.state.nameModified && newFolder.length === 1)
      p.TITLE = this.state.params.TITLE + newFolder[0];

    this.setState({
      params: p,
      nameModified: true,
      displayDialog: dialogsHidden
    })
  }

  updateProcesses = (newProcesses) => {
    this.setState({
      processes: newProcesses ? newProcesses : this.state.processes,
      displayDialog: dialogsHidden
    })
  }


  closeDialog = (complete) => {
    if (!this.props.onClose)
      return;

    if (complete) {
      // check beta flag
      if (!this.state.params.BETA_TESTING) {
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You must acknowledge the beta feature.");
        return;
      }

      // check folders
      if (this.state.params.FOLDER.length !== 1) {
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You need to select a single folder.");
        return;
      }

      // check folders
      if (this.state.params.APPS.length === 0) {
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You need to select one or more processes that will be allowed to encrypt and decrypt files.");
        return;
      }

      // check passphrases
      if (this.state.params.PASSPHRASE !== this.state.params.PASSPHRASE_CONFIRM) {
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "The passphrases do not match, please ensure both the passphrase and confirmation passphrases match.");
        return;
      }

      this.setState({ loader: true });

      // folders and apps must be sent as string not array
      let p = {...this.state.params};
      p.APPS = encodeRuleArray(p.APPS).replace("[", "").replace("]", "");
      p.FOLDER = encodeRuleArray(p.FOLDER).replace("[", "").replace("]", "");

      // add explorer?
      if (p.APPS.indexOf("explorer.exe") === -1 && p.ADD_EXPLORER_LOCATION) {
        p.APPS = p.APPS + "," + p.EXPLORER_LOCATION;
      }

      window.roFolderWall.AddUpdateRuleSet(this.props.drive.id, ruleSetTypeId, JSON.stringify(p), true, (updatedRules, stateValue) => {
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Encrypted folder applied!");
        this.props.onClose(true);
      }, (error) => {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
      })
    } else {
      this.props.onClose(false);
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        {this.state.displayDialog === dialogsHidden ?
          <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
          : null
        }
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogEditName}>
            <EditName {...this.props} name={this.state.params.TITLE} drive={this.props.drive} onUpdateName={this.updateName} />
          </Case>
          <Case value={dialogEditFolder}>
            <EditFolders {...this.props} folders={this.state.params.FOLDER} drive={this.props.drive} onUpdateFolders={this.updateFolders} singleFolder={true} />
          </Case>
          <Case value={dialogEditApplications}>
            <EditApplications {...this.props} processes={this.state.params.APPS} drive={this.props.drive} onUpdateProcesses={this.updateProcesses} />
          </Case>
        </Switch>
        <Modal isOpen={true} size="lg" centered={true} backdrop={true}>
        <Loadable active={this.state.loader || this.state.displayDialog !== dialogsHidden} spinner text="Working, please wait...">
            <ModalHeader close={closeBtn}>
              <Row>
                <Col xs={12}>
                  <h3 className="text-primary">
                    <i className="far fa-key"></i>&nbsp;New encrypted folder
                  </h3>
                  <h5 className="text-primary-2">
                    The encrypted folder wizard will create a rule that will encrypts any file written to that folder.&nbsp;
                    <a href="https://support.division-m.com/hc/en-us/articles/360001415576" style={{ textDecoration: "underline", color: "#8a337b" }} target="_blank" rel="noopener noreferrer">More details</a>
                  </h5>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody id="classic-modal-slide-description">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12}>
                      <h5 className="primary">
                        We recommend you specify only those applications that will be explicitly reading and writing to this folder. Adding too many applications, or a wildcard, can result in background applications triggering a passphrase prompt.
                      </h5>
                    </Col>
                  </Row>
                  <Form>
                    {/* for beta test */}
                    <Row>
                      <Col xs={12}>
                        <FormGroup check>
                          <Label check>
                            <Input name="BETA_TESTING" type="checkbox" checked={this.state.params.BETA_TESTING} onChange={(e) => this.handleChange(e)} />{' '}Acknowledge this is a BETA feature
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                            <div className="text-muted">This feature is under Beta testing, and you must understand the risk associated with using this feature. <a href="https://support.division-m.com/hc/en-us/articles/360001435416" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">Read more</a> on Beta features.</div>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            Rule name
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editName()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.params.TITLE}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            Encrypted folder
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editFolder()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.params.FOLDER.length !== 0 ? arrayToFormattedStr(this.state.params.FOLDER) : <span className="h6 text-danger">No folder selected</span>}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            Allowed applications
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editApplications()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.params.APPS.length !== 0 ? arrayToFormattedStr(this.state.params.APPS, this.state.maxProcessesToShow, Consts.PROCESS_REPLACE_ARRAY) : <span className="h6 text-danger">No applications selected</span>}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <Label>Passphrase</Label>
                          <Input name="PASSPHRASE" type="password" value={this.state.params.PASSPHRASE} onChange={(e) => this.handleChange(e)} />
                        </FormGroup>
                      </Col>
                      <Col xs={6}>
                        <FormGroup>
                          <Label>Passphrase confirm</Label>
                          <Input name="PASSPHRASE_CONFIRM" type="password" value={this.state.PASSPHRASE_CONFIRM} onChange={(e) => this.handleChange(e)} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup check>
                          <Label check>
                            <Input name="USING_AUTO_KEY" type="checkbox" checked={this.state.params.USING_AUTO_KEY} onChange={(e) => this.handleChange(e)} />{' '}Using Auto-key?
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                            <div className="text-muted">This will save the passphrase to a location (as specified) on your machine. Ensure you understand the <a href="https://support.division-m.com/hc/en-us/articles/360001415576" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">Auto-key feature</a> before enabling it</div>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {this.state.params.USING_AUTO_KEY ?
                      <Row>
                        <Col xs={12}>
                          <FormGroup>
                            <Label>
                              Auto-key locations (separate file system location using a ",")
                            </Label>
                            <Input name="AUTO_KEY_LOC" type="text" value={this.state.params.AUTO_KEY_LOC} onChange={(e) => this.handleChange(e)} />
                          </FormGroup>
                        </Col>
                      </Row>
                    : null }
                    {this.state.params.EXPLORER_LOCATION ?
                      <Row>
                        <Col xs={12}>
                          <FormGroup check>
                            <Label check>
                              <Input name="ADD_EXPLORER_LOCATION" type="checkbox" checked={this.state.params.ADD_EXPLORER_LOCATION} onChange={(e) => this.handleChange(e)} />{' '}Allow Windows Explorer access?
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                              <div className="text-muted">Once a passphrase has been provided, this option will allow Windows Explorer to decrypt/encrypt files as they are moved or copied in and out of the folder. Ensure you understand the <a href="https://support.division-m.com/hc/en-us/articles/360001415576" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">Explorer feature</a> before enabling it</div>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    : null }
                  </Form>
                </CardBody>
              </Card>
              <div className="text-right">
                <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
                <Button color="default" onClick={(e) => this.closeDialog(true)}>Apply</Button>
              </div>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Loadable>
        </Modal>
      </React.Fragment>
    );
  }
}