import React from "react";
import { Link } from "react-router-dom";
import { decodeRule, encodeRule, arrayToFormattedStr, mergeRuleObj } from "config/utilities";
import Switch, { Case } from "react-switch-case";
import { EditName } from "components/dialogs/edit-name";
import { ValidateRule } from "components/common/validate-rule";
import { ExpandingText } from "components/common/expanding-text"

const dialogsHidden = 0;
const dialogEditName = 1;
const dialogEditFolder = 2;

export class RuleCanaryFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rule: null,
      ruleObj: null,
      displayDialog: dialogsHidden
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.rule !== state.rule) {
      state.rule = props.rule;
      state.ruleObj = decodeRule(props.rule);
      return state;
    }
    return null;
  }

  editName = () => {
    this.setState({
      displayDialog: dialogEditName
    })
  }

  editFolder = () => {
    this.setState({
      displayDialog: dialogEditFolder
    })
  }

  deleteRule = (ruleObj) => {
    this.props.onDeleteRule(ruleObj);
  }

  updateRule = (ruleObj) => {
    this.setState({
      displayDialog: dialogsHidden
    })
    if (ruleObj) {
      let rules = mergeRuleObj(ruleObj, this.props.drive.rules);
      this.props.onUpdateDriveRules(rules, false);
    }
  }

  booleanToStr = (value) => {
    if (value)
      return "Yes";
    return "No";
  }

  render = () => {
    return (
      <React.Fragment>
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogEditName}>
            <EditName {...this.props} rule={this.props.rule} drive={this.props.drive} onUpdateRule={this.updateRule} />
          </Case>
        </Switch>
        <h3 className="text-primary">
        <i className="far fa-crow" />&nbsp;&nbsp;<span className="h4 text-primary">{this.state.ruleObj.name}</span>
          &nbsp;
          <sup className="h5">
            <Link to="#" onClick={(e) => this.editName()} style={{ color: "#e14eca" }}>
              <i className="far fa-edit" />
            </Link>
          </sup>
        </h3>
        <h5 className="text-muted">
          <ExpandingText text={this.state.ruleObj.metadata.DESC} startLength="165" linkStyle="text-muted" />
        </h5>
        <h5>
          <span className="text-success-2">
            Canary file name
            &nbsp;&nbsp;
            <span className="text-success">{arrayToFormattedStr(this.state.ruleObj.folders)}</span>
          </span>
        </h5>
        <ValidateRule mountPointId={this.props.drive.rootPath} rule={encodeRule(this.state.ruleObj)} ruleId={this.state.ruleObj.id} />
        <div className="text-right" style={{ marginTop: "20px" }}>
          <Link to="#">
            <span className="text-primary" style={{ marginRight: "20px" }} onClick={(e) => this.deleteRule(this.state.ruleObj)}>
              <i className="far fa-trash-alt" />
            </span>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}