import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Form, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import Switch, { Case } from "react-switch-case";
import { arrayToFormattedStr, encodeRuleArray } from "config/utilities";
import Loadable from "react-loading-overlay";
import { Consts } from "config/consts";
import { EditName } from "components/dialogs/edit-name";
import { EditFolders } from "components/dialogs/edit-folders";
import { EditApplications } from "components/dialogs/edit-applications";

const ruleSetTypeId = "folders_apps";
const dialogsHidden = 0;
const dialogEditName = 1;
const dialogEditFolders = 2;
const dialogEditApplications = 3;

export class NewFolderApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      ruleSet: null,
      params: {
        TITLE: "",
        APPS: "",
        FOLDERS: [],
        OPTIONS: "none",
        DISPLAY_ALERT: true,
        KILL_PROCESS: true,
        EMAIL_ADDRESSES: this.props.getAuthContext().state.email
      },
      folders: [],
      processes: ["<*>"],
      nameModified: false,
      maxProcessesToShow: 4,
      displayDialog: dialogsHidden
    }
  }

  componentDidMount() {
    window.roFolderWall.GetAllRuleSets(this.props.drive.id, (rs) => {
      try {
        let ruleSets = JSON.parse(rs);
        let rso = ruleSets["RULE_SETS"];
        for (let key in rso) {
          if (rso.hasOwnProperty(key)) {
            if (rso[key].TYPE_ID === ruleSetTypeId) {
              this.setState({
                loader: false,
                params: { ...this.state.params, TITLE: rso[key].DETAILS.NAME }
              });
              break;
            }
          }
        }
      } catch (err) {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "There was an error loading the required content!  (error code 102)");
        console.log(err)
        console.log(rs);
      }
    }, (error) => {
      this.setState({ loader: false });
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
    })
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      if (e.target.name.indexOf("OPTIONS|") === 0) {
        if (e.target.checked) {
          this.setState({ params: { ...this.state.params, "OPTIONS": e.target.name.toLowerCase().split("|")[1] }});
        } else {
          this.setState({ params: { ...this.state.params, "OPTIONS": "none" }});
        }
      } else {
        this.setState({ params: { ...this.state.params, [e.target.name]: e.target.checked }});
      }
    } else {
      this.setState({ params: { ...this.state.params, [e.target.name]: e.target.value }});
    }
  }

  editName = () => {
    this.setState({
      displayDialog: dialogEditName
    })
  }

  editFolders = () => {
    this.setState({
      displayDialog: dialogEditFolders
    })
  }

  editApplications = () => {
    this.setState({
      displayDialog: dialogEditApplications
    })
  }

  updateName = (newName) => {
    this.setState({
      params: newName ? { ...this.state.params, TITLE: newName } : this.state.params,
      displayDialog: dialogsHidden,
      nameModified: true
    })
  }

  updateFolders = (newFolders) => {
    let name = null;
    if (newFolders && !this.state.nameModified && newFolders.length === 1)
      name = this.state.params.TITLE + newFolders[0];

    if (newFolders && !this.state.nameModified && newFolders.length > 1)
      name = this.state.params.TITLE + newFolders[0] + "...";

    this.setState({
      folders: newFolders ? newFolders : this.state.folders,
      params: { ...this.state.params, TITLE: name ? name : this.state.params.TITLE },
      displayDialog: dialogsHidden
    })
  }

  updateProcesses = (newProcesses) => {
    this.setState({
      processes: newProcesses ? newProcesses : this.state.processes,
      displayDialog: dialogsHidden
    })
  }

  closeDialog = (complete) => {
    if (!this.props.onClose)
      return;

    if (complete) {

      // validate
      if (this.state.params.OPTIONS === "it_detect") {
        // check for email address
        if (!this.state.params.EMAIL_ADDRESSES || this.state.params.EMAIL_ADDRESSES.indexOf("@") === -1) {
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You need to supply one or more email addresses");
          return;
        }
      }

      // all good
      this.setState({ loader: true });

      // folders and apps must be sent as string not array
      let p = {...this.state.params};
      p.APPS = encodeRuleArray(this.state.processes).replace("[", "").replace("]", "");
      p.FOLDERS = encodeRuleArray(this.state.folders).replace("[", "").replace("]", "");

      // do it
      window.roFolderWall.AddUpdateRuleSet(this.props.drive.id, ruleSetTypeId, JSON.stringify(p), true, (updatedRules, stateValue) => {
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Folder protection applied!");
        this.props.onClose(true);
      }, (error) => {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
      })
    } else {
      this.props.onClose(false);
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
        <React.Fragment>
        {this.state.displayDialog === dialogsHidden ?
          <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
          : null
        }
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogEditName}>
            <EditName {...this.props} name={this.state.params.TITLE} drive={this.props.drive} onUpdateName={this.updateName} />
          </Case>
          <Case value={dialogEditFolders}>
            <EditFolders {...this.props} folders={this.state.folders} drive={this.props.drive} onUpdateFolders={this.updateFolders} />
          </Case>
          <Case value={dialogEditApplications}>
            <EditApplications {...this.props} processes={this.state.processes} drive={this.props.drive} onUpdateProcesses={this.updateProcesses} />
          </Case>
        </Switch>
        <Modal isOpen={true} size="lg" centered={true} backdrop={true}>
          <Loadable active={this.state.loader || this.state.displayDialog !== dialogsHidden} spinner text="Working, please wait...">
            <ModalHeader close={closeBtn}>
              <Row>
                <Col xs={12}>
                  <h3 className="text-primary">
                    <i className="far fa-shield-check"></i>&nbsp;Adding folder protection
                  </h3>
                  <h5 className="text-primary-2">
                    The folder protection wizard will create rules that will protect specific folders and it's files from unauthorized modifications. Allowed applications can include all signed, specifically signed by a group or corporation, or explicit application entries.&nbsp;
                    <a href="https://support.division-m.com/hc/en-us/articles/360001134476" style={{ textDecoration: "underline", color: "#8a337b" }} target="_blank" rel="noopener noreferrer">More details</a>
                  </h5>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody id="classic-modal-slide-description">
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            Rule name
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editName()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.params.TITLE}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            Protecting folder(s)
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editFolders()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.folders.length !== 0 ? arrayToFormattedStr(this.state.folders) : <span className="h6 text-danger">No folders selected</span>}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            Whitelisted applications
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editApplications()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.processes.length !== 0 ? arrayToFormattedStr(this.state.processes, this.state.maxProcessesToShow, Consts.PROCESS_REPLACE_ARRAY) : <span className="h6 text-danger">No applications selected</span>}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup check>
                          <Label check>
                            <Input name="OPTIONS|PROMPT" type="checkbox" checked={this.state.params.OPTIONS === "prompt"} onChange={(e) => this.handleChange(e)} />{' '}Enable request mode?&nbsp;
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                            <div className="text-muted">If an unauthorized application attempts to write to any protected folder, a full screen confirmation will be displayed to allow or deny access.&nbsp;
                            <a href="https://support.division-m.com/hc/en-us/articles/360001126955" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">More details</a></div>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        &nbsp;&nbsp;<span className="text-primary">-OR-</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup check>
                          <Label check>
                            <Input name="OPTIONS|IT_DETECT" type="checkbox" checked={this.state.params.OPTIONS === "it_detect"} onChange={(e) => this.handleChange(e)} />{' '}Enable intrusion detection?
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                            <div className="text-muted">If an unauthorized application attempts to write to any protected folder, intrusion detection will trigger.&nbsp;
                            <a href="https://support.division-m.com/hc/en-us/articles/360001130776" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">More details</a></div>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {this.state.params.OPTIONS === "it_detect" ?
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Label>Notification email address(es) (multiple addresses can be separated with a ",")</Label>
                                <Input name="EMAIL_ADDRESSES" type="text" value={this.state.params.EMAIL_ADDRESSES} onChange={(e) => this.handleChange(e)} />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup check>
                                <Label check>
                                  <Input name="DISPLAY_ALERT" type="checkbox" checked={this.state.params.DISPLAY_ALERT} onChange={(e) => this.handleChange(e)} />{' '}Display full screen alert (recommended)?
                                  <span className="form-check-sign">
                                    <span className="check"></span>
                                  </span>
                                  <div className="text-muted">If intrusion detection is triggered, this will display a full screen alert.</div>
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup check>
                                <Label check>
                                  <Input name="KILL_PROCESS" type="checkbox" checked={this.state.params.KILL_PROCESS} onChange={(e) => this.handleChange(e)} />{' '}Terminate the unauthorized process (recommended)?
                                  <span className="form-check-sign">
                                    <span className="check"></span>
                                  </span>
                                  <div className="text-muted">If intrusion detection is triggered, the process that caused the trigger will be terminated.</div>
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      : null
                    }
                  </Form>
                </CardBody>
              </Card>
              <div className="text-right">
                <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
                <Button color="default" onClick={(e) => this.closeDialog(true)}>Apply</Button>
              </div>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Loadable>
        </Modal>
      </React.Fragment>
    );
  }
}