import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Form, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import Switch, { Case } from "react-switch-case";
import Loadable from "react-loading-overlay";
import { Consts } from "config/consts";
import { arrayToFormattedStr, encodeRuleArray } from "config/utilities";
import { EditName } from "components/dialogs/edit-name";
import { EditFolders } from "components/dialogs/edit-folders";
import { EditApplications } from "components/dialogs/edit-applications";
import { EditCanaryFiles } from "components/dialogs/edit-canary-files";

const ruleSetTypeId = "the_cyber_canary";
const dialogsHidden = 0;
const dialogEditName = 1;
const dialogEditFolders = 2;
const dialogEditApplications = 3;
const dialogEditCanaryFiles = 4;

const ROGroupWindowsScan = 3;

export class NewTheCyberCanary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      ruleSet: null,
      params: {
        BETA_TESTING: false,
        TITLE: "The Cyber Canary protection",
        EMAIL_ADDRESSES: this.props.getAuthContext().state.email,
        INTRUSION_DETECTION: false,
        INTRUSION_DETECTION_READ_PERCENT: 0,
        DISPLAY_ALERT: true,
        KILL_PROCESS: true,
        SHUTDOWN_SERVER: false,
        HIDE_FROM_EXPLORER: true,
        PROTECT_ALL_USERS: this.props.drive.systemDrive,
        USER_DEFINE_FOLDERS: [],
        USE_SOHO_FILENAME: false,
        CANARY_FILES_OVERRIDE : ""
      },
      maxProcessesToShow: 4,
      displayDialog: dialogsHidden,
      intrusionDetectionAdd: true,
      shutdownServerAdd: true,
      advanceOptionsAdd: true,
      showAdvancedOptions: false
    }
  }

  componentDidMount() {
    // determine options we can display
    let lic = this.props.getGlobalState().license;

    let id = false;
    let ss = false;
    let ao = false;

    if (lic.type === Consts.LICENSE.TYPE.THE_CYBER_CANARY) {
      if (lic.state >= Consts.LICENSE.STATE.PREMIUM) {
        id = true;
        ao = true;
      }

      if (lic.state === Consts.LICENSE.STATE.ENTERPRISE_SERVER)
        ss = true;

    } else {
      id = true;
      ss = true;
      ao = true;
    }

    this.setState({
      intrusionDetectionAdd: id,
      shutdownServerAdd: ss,
      advanceOptionsAdd: ao
    })

    window.roFolderWall.GetAllRuleSets(this.props.drive.id, (rs) => {
      try {
        let ruleSets = JSON.parse(rs);
        let rso = ruleSets["RULE_SETS"];
        for (let key in rso) {
          if (rso.hasOwnProperty(key)) {
            if (rso[key].TYPE_ID === ruleSetTypeId) {
              this.setState({
                loader: false,
                params: { ...this.state.params, TITLE: rso[key].DETAILS.NAME }
              });
              break;
            }
          }
        }
      } catch (err) {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "There was an error loading the required content! (error code 104)");
        console.log(err)
        console.log(rs);
      }
    }, (error) => {
      this.setState({ loader: false });
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
    });

    // get explorer details
    window.roFolderWall.GetApplications(ROGroupWindowsScan, "", false, 1000, "", (count, processes, moreItemsAvailable, contextId) => {
      processes.items.forEach((process) => {
        if (process.path.value.indexOf('explorer.exe') > 0) {
          this.setState({ params: { ...this.state.params, EXPLORER_LOCATION: process.path.value } });
        }
      })
    }, (error) => {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
    });
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ params: { ...this.state.params, [e.target.name]: e.target.checked } });
    } else {
      this.setState({ params: { ...this.state.params, [e.target.name]: e.target.value } });
    }
  }

  editName = () => {
    this.setState({
      displayDialog: dialogEditName
    })
  }

  editFolders = () => {
    this.setState({
      displayDialog: dialogEditFolders
    })
  }

  editApplications = () => {
    this.setState({
      displayDialog: dialogEditApplications
    })
  }

  editCanaryFiles = () => {
    this.setState({
      displayDialog: dialogEditCanaryFiles
    })
  }

  updateName = (newName) => {
    this.setState({
      params: newName ? { ...this.state.params, TITLE: newName } : this.state.params,
      displayDialog: dialogsHidden,
      nameModified: true
    })
  }

  updateFolders = (newFolder) => {
    let p = this.state.params;
    p.USER_DEFINE_FOLDERS = newFolder ? newFolder : p.USER_DEFINE_FOLDERS;

    // update name??
    if (newFolder && !this.state.nameModified && newFolder.length === 1)
      p.TITLE = this.state.params.TITLE + " for - " + newFolder[0];

    this.setState({
      params: p,
      nameModified: true,
      displayDialog: dialogsHidden
    })
  }

  updateProcesses = (newProcesses) => {
    this.setState({
      processes: newProcesses ? newProcesses : this.state.processes,
      displayDialog: dialogsHidden
    })
  }

  updateCanaryFiles = (newCanaryFiles) => {
    this.setState({
      params: newCanaryFiles ? { ...this.state.params, CANARY_FILES_OVERRIDE: newCanaryFiles } : this.state.params,
      displayDialog: dialogsHidden
    })
  }


  closeDialog = (complete) => {
    if (!this.props.onClose)
      return;

    if (complete) {
      // check folders
      if (this.state.params.USER_DEFINE_FOLDERS.length !== 1 && !this.state.params.PROTECT_ALL_USERS) {
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You need to select one or more folders to protect.");
        return;
      }

      this.setState({ loader: true });

      // folders and apps must be sent as string not array
      let p = {...this.state.params};
      p.USER_DEFINE_FOLDERS = encodeRuleArray(p.USER_DEFINE_FOLDERS).replace("[", "").replace("]", "");

      // intrusion detection must pass a % of file read
      if (p.INTRUSION_DETECTION)
        p.INTRUSION_DETECTION_READ_PERCENT = 80;

      // set...
      window.roFolderWall.AddUpdateRuleSet(this.props.drive.id, ruleSetTypeId, JSON.stringify(p), true, (updatedRules, stateValue) => {
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "The Cyber Canary has been applied!");
        this.props.onClose(true);
      }, (error) => {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
      })
    } else {
      this.props.onClose(false);
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        {this.state.displayDialog === dialogsHidden ?
          <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
          : null
        }
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogEditName}>
            <EditName {...this.props} name={this.state.params.TITLE} drive={this.props.drive} onUpdateName={this.updateName} />
          </Case>
          <Case value={dialogEditFolders}>
            <EditFolders {...this.props} folders={this.state.params.USER_DEFINE_FOLDERS} drive={this.props.drive} onUpdateFolders={this.updateFolders} />
          </Case>
          <Case value={dialogEditApplications}>
            <EditApplications {...this.props} processes={this.state.params.APPS} drive={this.props.drive} onUpdateProcesses={this.updateProcesses} />
          </Case>
          <Case value={dialogEditCanaryFiles}>
            <EditCanaryFiles {...this.props} existingFiles={this.state.params.CANARY_FILES_OVERRIDE} onUpdateCanaryFiles={this.updateCanaryFiles} />
          </Case>
        </Switch>
        <Modal isOpen={true} size="lg" centered={true} backdrop={true}>
        <Loadable active={this.state.loader || this.state.displayDialog !== dialogsHidden} spinner text="Working, please wait...">
            <ModalHeader close={closeBtn}>
              <Row>
                <Col xs={12}>
                  <h3 className="text-primary">
                    <i className="far fa-shield"></i>&nbsp;The Cyber Canary
                  </h3>
                  <h5 className="text-primary-2">
                    The Cyber Canary provides fully automated, set and forget ransomware and malware protection.&nbsp;
                    <a href="https://support.division-m.com/hc/en-us/articles/360001690036" style={{ textDecoration: "underline", color: "#8a337b" }} target="_blank" rel="noopener noreferrer">More details</a>
                  </h5>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody id="classic-modal-slide-description">
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            Rule name
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editName()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.params.TITLE}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>Notification email address (multiple addresses can be separated with a ",")</Label>
                          <Input name="EMAIL_ADDRESSES" type="text" value={this.state.params.EMAIL_ADDRESSES} onChange={(e) => this.handleChange(e)} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        {this.state.params.PROTECT_ALL_USERS ?
                          <FormGroup check>
                            <Label check>
                              <Input name="PROTECT_ALL_USERS" type="checkbox" checked={this.state.params.PROTECT_ALL_USERS} onChange={(e) => this.handleChange(e)} />{' '}Monitor all users folders?
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                              <div className="text-muted">If enabled, all users folders are monitor. If disabled, you need to specify the folder(s) to be monitored.</div>
                            </Label>
                          </FormGroup>
                        :
                        <FormGroup>
                          <Label>
                            Monitor folder(s)
                              {this.props.drive.systemDrive ?
                                <React.Fragment>&nbsp;(click <Link to="#" style={{ textDecoration: "underline", color: "#8a337b" }} onClick={(e) => this.setState({ params: { ...this.state.params, PROTECT_ALL_USERS: true } })}>here</Link> to monitor user folders)</React.Fragment> : null}
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editFolders()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.params.USER_DEFINE_FOLDERS.length !== 0 ? arrayToFormattedStr(this.state.params.USER_DEFINE_FOLDERS) : <span className="h6 text-danger">No folder selected</span>}</span>
                          </h5>
                        </FormGroup>
                        }
                      </Col>
                    </Row>
                    {this.state.intrusionDetectionAdd ?
                      <Row>
                        <Col xs={12}>
                          <FormGroup check>
                            <Label check>
                              <Input name="INTRUSION_DETECTION" type="checkbox" checked={this.state.params.INTRUSION_DETECTION} onChange={(e) => this.handleChange(e)} />{' '}Intrusion detection?
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                              <div className="text-muted">This will detect canary file delete or read access and trigger. Important, this option may cause false positives if canary files are heavily index, see the <a href="https://support.division-m.com/hc/en-us/articles/360001690036" style={{ textDecoration: "underline", color: "#8a337b" }} target="_blank" rel="noopener noreferrer">intrusion option for further details</a>.</div>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    : null }
                    {this.state.shutdownServerAdd ?
                      <Row>
                        <Col xs={12}>
                          <FormGroup check>
                            <Label check>
                              <Input name="SHUTDOWN_SERVER" type="checkbox" checked={this.state.params.SHUTDOWN_SERVER} onChange={(e) => this.handleChange(e)} />{' '}Shutdown the machine?
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                              <div className="text-muted">If triggered, in addition to killing the process, the machine will also be shutdown.</div>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    : null }
                    <Row>
                      <Col xs={12}>
                        <FormGroup check>
                          <Label check>
                            <Input name="HIDE_FROM_EXPLORER" type="checkbox" checked={this.state.params.HIDE_FROM_EXPLORER} onChange={(e) => this.handleChange(e)} />{' '}Hide canary files from Windows Explorer?
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                            <div className="text-muted">If enabled, all canary files will be physically hidden from Windows Explorer. You can add your own applications to this list once the created.</div>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {this.state.advanceOptionsAdd ?
                      <React.Fragment>
                        {this.state.showAdvancedOptions ?
                          <React.Fragment>
                            <Row>
                              <Col xs={12}>
                                <h5 className="text-primary">Advanced options</h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <FormGroup check>
                                  <Label check>
                                    <Input name="USE_SOHO_FILENAME" type="checkbox" checked={this.state.params.USE_SOHO_FILENAME} onChange={(e) => this.handleChange(e)} />{' '}Use business style file names?
                                    <span className="form-check-sign">
                                      <span className="check"></span>
                                    </span>
                                    <div className="text-muted">By default, the files names used for canary files are random words. Use this option to use more business focused words.</div>
                                    <div className="text-muted">-OR-</div>
                                    <div className="text-muted"><Link to="#" style={{ textDecoration: "underline" }} onClick={(e) => this.editCanaryFiles()}>Define your own canary file names</Link> and targets</div>
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                        </React.Fragment>
                      :
                        <React.Fragment>
                          <h5 className="text-primary"><Link to="#" onClick={(e) => this.setState({ showAdvancedOptions: true })}>Show advanced options</Link>.</h5>
                        </React.Fragment>
                      }
                      </React.Fragment>
                    : null }
                  </Form>
                </CardBody>
              </Card>
              <div className="text-right">
                <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
                <Button color="default" onClick={(e) => this.closeDialog(true)}>Apply</Button>
              </div>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Loadable>
        </Modal>
      </React.Fragment>
    );
  }
}