import React from "react";
import { Link } from 'react-router-dom'
import { Consts } from "config/consts";
import { decodeRule, mergeRuleObj, getRuleById } from "config/utilities";
import Switch, { Case } from "react-switch-case";
import { RuleText } from "components/home/rules/rule-text";
import { EditName } from "components/dialogs/edit-name";
import { EditAccess } from "components/dialogs/edit-access";
import { EditFolders } from "components/dialogs/edit-folders";
import { EditApplications } from "components/dialogs/edit-applications";

const dialogsHidden = 0;
const dialogEditName = 1;
const dialogEditAccess = 2;
const dialogEditFolders = 3;
const dialogEditApplications = 4;


export class RuleRaw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rule: null,
      ruleObj: null,
      ruleValidationResult: null,
      ruleSecurityLevel: null,
      maxProcessesToShow: 4,
      displayDialog: dialogsHidden,
      ruleListView: false
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.rule !== state.rule) {
      state.rule = props.rule;
      state.ruleObj = decodeRule(props.rule);
      state.ruleValidationResult = "Checking rule validation...";
      state.ruleSecurityLevel = -1;
      return state;
    }
    return null;
  }

  componentDidMount() {
    if (this.state.ruleSecurityLevel === -1 && !this.validating) {
      this.updateRuleValidation(null);
    }
  }

  componentDidUpdate() {
    if (this.state.ruleSecurityLevel === -1 && !this.validating) {
      this.updateRuleValidation(null);
    }
  }

  updateRuleValidation = (rule) => {
    this.validating = true;
    window.roFolderWall.RuleValidation(this.props.drive.rootPath, rule ? rule : this.state.ruleObj.id, "", "", "", (blockingRule, securityLevel, validationResult) => {
      this.setState({
        ruleValidationResult: validationResult,
        ruleSecurityLevel: securityLevel,
      }, () => this.validating = false
      )
    }, (error) => {
      console.log(error);
    })
  }

  editName = () => {
    this.setState({
      displayDialog: dialogEditName
    })
  }

  editAccess = () => {
    this.setState({
      displayDialog: dialogEditAccess
    })
  }

  editFolders = () => {
    this.setState({
      displayDialog: dialogEditFolders
    })
  }

  editApplications = () => {
    this.setState({
      displayDialog: dialogEditApplications
    })
  }

  deleteRule = (ruleObj) => {
    this.props.onDeleteRule(ruleObj);
  }

  toggleRuleListView = () => {
    this.setState({
      ruleListView: !this.state.ruleListView
    }, () => {
      this.state.ruleListView ?
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Rules being displayed in list mode.") :
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Rules being displayed in line mode.")
    })
  }

  updateRule = (ruleObj) => {
    this.setState({
      displayDialog: dialogsHidden
    })
    if (ruleObj) {
      let rules = mergeRuleObj(ruleObj, this.props.drive.rules);
      this.props.onUpdateDriveRules(rules, false);
      this.updateRuleValidation(getRuleById(ruleObj.id, rules));
    }
  }

  handleRuleLink = (e) => {
    if (this.props.onClick) {
      var shouldProceed = this.props.onClick(e);
      if (shouldProceed === false) return;
    }

    // ignore canceled events, modified clicks, and right clicks.
    if (e.defaultPrevented) {
      return;
    }

    if (e.metaKey || e.ctrlKey || e.shiftKey) {
      return;
    }

    if (e.button !== 0) {
      return;
    }

    // get the <a> element.
    var el = e.target;
    while (el && el.nodeName !== 'A') {
      el = el.parentNode;
    }

    // ignore clicks from non-a elements.
    if (!el) {
      return;
    }

    if (el.href.indexOf("#name") !== -1) {
      // name
      this.editName();
      return;
    }

    if (el.href.indexOf("#access") !== -1) {
      // process access
      this.editAccess();
      return;
    }

    if (el.href.indexOf("#processes") !== -1) {
      // process processes
      this.editApplications();
      return;
    }

    if (el.href.indexOf("#folders") !== -1) {
      // process processes
      this.editFolders();
      return;
    }
  }

  render = () => {
    return (
      <React.Fragment>
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogEditName}>
            <EditName {...this.props} rule={this.props.rule} drive={this.props.drive} onUpdateRule={this.updateRule} />
          </Case>
          <Case value={dialogEditAccess}>
            <EditAccess {...this.props} rule={this.props.rule} drive={this.props.drive} onUpdateRule={this.updateRule} />
          </Case>
          <Case value={dialogEditFolders}>
            <EditFolders {...this.props} rule={this.props.rule} drive={this.props.drive} onUpdateRule={this.updateRule} />
          </Case>
          <Case value={dialogEditApplications}>
            <EditApplications {...this.props} rule={this.props.rule} drive={this.props.drive} onUpdateRule={this.updateRule} />
          </Case>
        </Switch>
        {this.props.error ? <p className="h6 text-danger">{this.props.error}</p> : null}
        <div onClick={(e) => { this.handleRuleLink(e) }}>
          <RuleText type="links" rule={this.props.rule} color="#C0C0C0" listView={this.state.ruleListView} />
        </div>
        {this.state.ruleSecurityLevel !== -1 ?
          <h5>
            {this.state.ruleSecurityLevel === 10 ?
              <small className="text-success"><i className="far fa-check-circle" />&nbsp;{this.state.ruleValidationResult}</small>
              :
              <small className="text-warning"><i className="far fa-exclamation-circle" />&nbsp;{this.state.ruleValidationResult}</small>
            }
          </h5> : null
        }
        <div className="text-right" style={{ marginTop: "20px" }}>
          <Link to="#">
            <span className="text-primary" style={{ marginRight: "20px" }} onClick={(e) => this.deleteRule(this.state.ruleObj)}>
              <i className="far fa-trash-alt" />
            </span>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}