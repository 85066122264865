import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, FormGroup, Label } from "reactstrap";
import { decodeRule } from "config/utilities"
import { EditRuleName } from "components/common/edit-rule-name.jsx"
import { EditRuleAccess } from "components/common/edit-rule-access.jsx"

import anvil from "assets/img/anvil_action_dark_100x100.png";

export class RuleEditFixed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleObj: decodeRule(this.props.rule),
      modified: false
    }
  }

  closeDialog = (updatedRule) => {
    if (!this.props.onClose)
      return;

    // all good
    if (updatedRule === true) {
      this.props.onClose(this.state.ruleObj);
    } else {
      this.props.onClose(null);
      return;
    }
  }

  updateName = (newValue) => {
    let ro = this.state.ruleObj;
    ro.name = newValue;
    this.setState({
      ruleObj: ro,
      modified: true
    });
  }

  updateAccess = (newValue) => {
    let ro = this.state.ruleObj;
    ro.access = newValue;
    this.setState({
      ruleObj: ro,
      modified: true
    });
  }

  render = () => {
    const closeBtn = <button className="close" onClick={this.closeDialog}>&times;</button>;
    return (
      <Modal className="manage-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
        <ModalHeader close={closeBtn}>
          <Row>
            <Col xs={2}>
              <img src={anvil} alt="ANVIL" />
            </Col>
            <Col xs={10}>
              <h3 className="text-info" style={{ marginTop: "15px" }}>Modify base protection for the drive {this.props.drive.mountName} [{this.props.drive.volumeLabel}]</h3>
              <h5 className="text-danger">Changing the base protection should be done with care, limiting default drive access can cause system issues. We recommend adding specific rules to achieve the desired results.</h5>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody id="classic-modal-slide-description">
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>Name</Label>
                    <EditRuleName rule={this.state.ruleObj} onChange={(newValue) => this.updateName(newValue)} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>Access</Label>
                    <EditRuleAccess rule={this.state.ruleObj} onChange={(newValue) => this.updateAccess(newValue)} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="text-right">
                    <Button color="secondary" onClick={this.closeDialog}>Cancel</Button>
                    <Button color="info" onClick={(e) => { this.closeDialog(true) }} disabled={!this.state.modified}>Update</Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>
    );
  }
}