import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Redirect } from "react-router-dom"
import { AuthenticationContext } from "context/authentication";
import { Consts } from "config/consts";

class LoggingOut extends React.Component {
  constructor(props) {
    super(props);
    this.props.setCleanPage();
    this.state = {
      redirect: false
    };
  }

  componentDidMount = () => {
    this.props.authContext.logoutUser().then(() => {
      this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Signed out, please wait...");
      this.props.setLoader(true);
      setTimeout(() => {
        this.props.setLoader(false);
        this.setState({ redirect: true });
      }, 2000);
    })
  }

  render = () => {

    if (this.state.redirect) {
      return (
        <Redirect to="/user/login" />
      )
    }

    return (
      <React.Fragment>
        <div className="content-full-page">
          <div className="login">
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <h1>Bye!</h1>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <AuthenticationContext.Consumer>
        {authContext =>
          <LoggingOut authContext={authContext} {...this.props} />
        }
      </AuthenticationContext.Consumer>
    );
  }
}

export default Logout;