import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

export class SelectFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      options: [],
      loading: true
    }
  }

  static getDerivedStateFromProps(props, state) {

    if (props.defaultValue && !state.value) {
      state.value = props.defaultValue;

      if (props.options.length > 0) {
        state.options = props.options;
        state.loading = false;
      }
      return state;
    }

    if (props.options.length === 0) {
      state.loading = true;
      return state;
    }

    if (props.options.length > 0) {
      state.options = props.options;
      state.loading = false;
      return state;
    }

    return null;
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
    });

    // send the value
    if (this.props.onChange) {
      this.props.onChange(value.value);
    }

    // send the object
    if (this.props.onChangeObject) {
      this.props.onChangeObject(value);
    }

    if (this.props.clearOnSelect) {
      this.setState({
        value: { label: "", value: "" }
      })
    }
  };

  renderImage = (data, name) => {
    return <img alt={name} src={`data:image/jpeg;base64,${data}`} />
  }

  renderLabel = (label) => {
    if (this.props.truncateLength) {
      if (label.length > this.props.truncateLength)
        return "... " + label.substring(label.length - this.props.truncateLength);
    }
    return label;
  }

  getLabel = ({ label, image64 }) => {
    return (
      <div>
        {image64 ?
          <div>
            {this.renderImage(image64, label)}&nbsp;<span>{this.renderLabel(label)}</span>
          </div>
        :
          <span>{label}</span>
        }
      </div>
    );
  }

  render() {
    if (this.props.readonly === true) {
      return (
        <Select
          getOptionLabel={this.getLabel}
          options={this.state.options}
          value={this.state.value}
          onChange={this.handleChange("value")}
          placeholder={this.state.loading ? (this.props.placeHolder ? "Loading, please wait..." : "") : this.props.placeHolder}
          isLoading={this.state.loading}
          isSearchable={true}
        />
      );
    } else {
      return (
        <CreatableSelect
          getOptionLabel={this.getLabel}
          options={this.state.options}
          value={this.state.value}
          onChange={this.handleChange("value")}
          placeholder={this.state.loading ? (this.props.placeHolder ? "Loading, please wait..." : "") : this.props.placeHolder}
          isLoading={this.state.loading}
          isSearchable={true}
        />
      );
    }
  }
}