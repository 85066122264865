import React from "react";
import { Button, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { decodeRule, encodeRule, arrayToFormattedStr, createGuid, validateFolder } from "config/utilities";
import { Consts } from "config/consts";
import Switch, { Case } from "react-switch-case";
import { ApplicationItem } from "components/common/application-item"
import { RuleFolderApp } from "components/home/rules/rule-folder-app";

export class RuleLearning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxProcessesToShow: 4,
      learningBuffer: {
        items: []
      },
      ruleObj: decodeRule(this.props.rule),
      learningMode: (decodeRule(this.props.rule).access.indexOf("W>") !== -1)
    }

    this.bufferInterval = null;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.rule.access !== state.ruleObj.access) {
      state.ruleObj = decodeRule(props.rule);
      state.learningMode = (decodeRule(props.rule).access.indexOf("W>") !== -1)
      return state;
    }
    return null;
  }

  componentDidMount() {
    window.roFolderWall.GetLearningModeBuffer(this.props.drive.id, this.state.ruleObj.id, (buffer) => {
      this.setState({ learningBuffer: buffer });
    })

    if (this.state.learningMode && !this.bufferInterval) {
      this.bufferInterval = setInterval(() => {
        // get buffer from server
        window.roFolderWall.GetLearningModeBuffer(this.props.drive.id, this.state.ruleObj.id, (buffer) => {
          //console.log(buffer)
          this.setState({ learningBuffer: buffer });
        })
      }, 2500);
    }
  }

  componentDidUpdate() {
    if (this.state.learningMode && !this.bufferInterval) {
      this.bufferInterval = setInterval(() => {
        // get buffer from server
        window.roFolderWall.GetLearningModeBuffer(this.props.drive.id, this.state.ruleObj.id, (buffer) => {
          //console.log(buffer)
          this.setState({ learningBuffer: buffer });
        })
      }, 2500);
    }
  }

  editRule = (rule) => {
    this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "ANVIL error", "You need to end learning mode before you can edit or delete this rule.");
  }

  deleteRule = (rule) => {
    this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "ANVIL error", "You need to end learning mode before you can edit or delete this rule.");
  }

  deleteProcess = (app) => {
    // remove it from buffer
    let buffer = this.state.learningBuffer;
    for(let i = 0; i < buffer.items.length; i++) {
      if (buffer.items[i] === app) {
        buffer.items.splice(i, 1);
        window.roFolderWall.UpdateLearningModeBuffer(this.props.drive.id, this.state.ruleObj.id, buffer, () => {
          //console.log("Buffer updated!")
        }, (error) => {
          console.log(error)
        });
        this.setState({ learningBuffer: buffer });
        return;
      }
    }
  }

  endLearningMode = () => {
    // kill refresh time
    if (this.bufferInterval) {
      clearInterval(this.bufferInterval);
      this.bufferInterval = null;
    }

    this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Apply modified rules?", "Are you sure you want to end learning mode?", () => {
      window.roFolderWall.MergeLearningMode(this.props.drive.id, this.state.ruleObj.id, (updatedRules, stateValue) => {
        console.log("Learning mode merged for rule id " + this.state.ruleObj.id);

        // update rules...
        if (this.props.onUpdateDriveRules)
          this.props.onUpdateDriveRules(updatedRules.items);

        this.setState({ learningMode: false });
      }, (error) => {
        console.log(error)
      });
    });
  }

  booleanToStr = (value) => {
    if (value)
      return "Yes";
    return "No";
  }

  render = () => {
    if (!this.state.learningMode) {
      return (
        <RuleFolderApp {...this.props} />
      );
    }

    return (
      <React.Fragment>
        <h3 className="text-primary">{this.state.ruleObj.name}</h3>
        <h5>
          {this.state.ruleObj.metadata ? <span className="text-muted">{this.state.ruleObj.metadata.DESC}<br /><br /></span> : null}
          <span className="text-success-2">Learning mode for folder&nbsp;&nbsp;</span><span className="text-success">{arrayToFormattedStr(this.state.ruleObj.folders)}</span><br />
          <br/>
          <Button color="info" onClick={(e) => { this.endLearningMode() }}>End learning mode</Button>
          <br /><br />
          {this.state.learningBuffer.items.map((app, i) => {
            return (
              <Row key={i}>
                <Col xs={8}>
                  <ApplicationItem name={app.path.value} color="default" certValid={app.certValid.value} certName={app.certName.value} onClose={(e) => this.deleteProcess(app)} />
                </Col>
              </Row>
            );
          })}
          <Switch condition={this.state.ruleObj.options !== null && this.state.ruleObj.metadata.SCRIPT !== null}>
            <Case value={true}>
              <br />
              <span className="text-success-2">Limit read access&nbsp;&nbsp;</span><span className="text-success">{this.booleanToStr(this.state.ruleObj.metadata.SCRIPT.options.limitAccessRead)}</span>
              &nbsp;&nbsp;&nbsp;&nbsp;<span className="text-muted">|</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="text-success-2">Limit list access&nbsp;&nbsp;</span><span className="text-success">{this.booleanToStr(this.state.ruleObj.metadata.SCRIPT.options.limitAccessList)}</span><br />
            </Case>
          </Switch>
        </h5>
        <div className="text-right" style={{ marginTop: "20px" }}>
          <Link to="#">
            <span className="text-primary" style={{ marginRight: "20px" }} onClick={(e) => this.editRule(this.state.ruleObj)}>
              <i className="fa fa-edit" />
            </span>
          </Link>
          <Link to="#">
            <span className="text-primary" style={{ marginRight: "20px" }} onClick={(e) => this.deleteRule(this.state.ruleObj)}>
              <i className="fa fa-trash-alt" />
            </span>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export function RuleLearning_CreateRuleSet(ruleScript) {
  let result = [];

  // group id
  let gid = createGuid();

  // rule
  let r = decodeRule(null);
  r.name = ruleScript.name;
  r.folders = [];
  ruleScript.folders.forEach((f) => {
    r.folders.push(validateFolder(f));
  })
  r.processes = [];
  r.processes.push("*");
  r.access = "[L+R+W>]";
  r.metadata = {
    TYPE: Consts.RULE_TYPE.LEARNING_RULE,
    DESC: ruleScript.options.description ? ruleScript.options.description : "Folder protection for " + arrayToFormattedStr(r.folders, 1),
    GROUP_ID: gid,
    AUTO_GENERATED: true,
    SCRIPT: ruleScript
  }
  result.push(encodeRule(r));

  // do blocking rules
  let rb = decodeRule(null);
  rb.name = ruleScript.name + " (Blocking rule)";
  rb.folders = [];
  ruleScript.folders.forEach((f) => {
    rb.folders.push(validateFolder(f));
  })
  rb.processes = [];
  rb.processes.push("*");

  // determine access for other processes
  let acs = ""
  if (ruleScript.options.limitAccessList) {
    acs = "[L-R-";
  } else if (ruleScript.options.limitAccessRead) {
    acs = "[L+R-";
  } else {
    acs = "[L+R+";
  }
  acs = acs + "W-]";

  rb.access = acs;
  rb.metadata = {
    TYPE: Consts.RULE_TYPE.SUPPORT_RULE,
    DESC: "Blocking rule for " + arrayToFormattedStr(rb.folders, 1) + " (group id: " + gid + ")",
    GROUP_ID: gid,
    AUTO_GENERATED: true,
    SCRIPT: null
  }
  result.push(encodeRule(rb));

  return result;
}