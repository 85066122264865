import React from "react";
import { Link } from "react-router-dom";
import { decodeRule, encodeRule, arrayToFormattedStr, mergeRuleObj, createRuleSet } from "config/utilities";
//import { Consts } from "config/consts";
import Switch, { Case } from "react-switch-case";
import { Consts } from "config/consts";
import { EditName } from "components/dialogs/edit-name";
import { EditFolders } from "components/dialogs/edit-folders";
import { EditApplications } from "components/dialogs/edit-applications";
import { EditEmail } from "components/dialogs/edit-email";
import { ValidateRule } from "components/common/validate-rule";
import { ExpandingText } from "components/common/expanding-text"

const dialogsHidden = 0;
const dialogEditName = 1;
const dialogEditFolders = 2;
const dialogEditApplications = 3;
const dialogEditEmail = 4;

export class RuleTheCyberCanary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rule: null,
      ruleObj: null,
      blockingRule: null,
      displayDialog: dialogsHidden,
      emailAddressesEdit: true,
      intrusionDetectionEdit: true,
      displayAlertEdit: true,
      terminateProcessEdit: true,
      shutdownServerEdit: true,
      emailAddresses: null,
      intrusionDetection: null,
      displayAlert: null,
      terminateProcess: null,
      shutdownServer: null,
      showVirtualFiles: false
    }

    this.virtualFiles = [];
  }

  static getDerivedStateFromProps(props, state) {
    if (props.rule !== state.rule) {
      state.rule = props.rule;
      state.ruleObj = decodeRule(props.rule);

      // get rule set to get blocking rule
      state.ruleSetObj = createRuleSet(decodeRule(props.rule), props.drive.rules);
      state.ruleSetObj.ruleObjs.forEach((ro) => {
        if (ro.id !== state.ruleObj.id) {
          // blocking rule (last one in list)
          state.blockingRule = ro;
        }
      });
      return state;
    }
    return null;
  }

  componentDidMount() {
    // determine options we can display
    let lic = this.props.getGlobalState().license;

    if (lic.type === Consts.LICENSE.TYPE.THE_CYBER_CANARY) {
      let id = false;
      let ss = false;

      if (lic.state >= Consts.LICENSE.STATE.PREMIUM)
        id = true;
      if (lic.state === Consts.LICENSE.STATE.ENTERPRISE_SERVER)
        ss = true;

      this.setState({
        intrusionDetectionEdit: id,
        shutdownServerEdit: ss
      })
    }

    // get virtual files
    window.roFolderWall.GetVirtualFiles(this.state.ruleObj.id, (virtualFiles) => {
      virtualFiles.items.forEach((vf) => {
        this.virtualFiles.push(vf);
      });
    });

    window.roFolderWall.GetRuleFeature(this.state.ruleObj.id, (feature) => {
      //console.log(this.state.ruleObj);
      //console.log(feature.parameters.value);
      const f = JSON.parse(feature.parameters.value);

      let params = {
        emailAddresses: null,
        intrusionDetection: null,
        displayAlert: null,
        terminateProcess: null,
        shutdownServer: null
      }

      for (let i = 0; i < f.length; i++) {
        let e = f[i];

        if (e.NAME === "EMAIL_ADDRESSES")
          params.emailAddresses = e.VALUE;

        if (e.NAME === "INTRUSION_DETECTION_READ_PERCENT")
          params.intrusionDetection = e.VALUE;

        if (e.NAME === "DISPLAY_ALERT")
          params.displayAlert = e.VALUE;

        if (e.NAME === "KILL_PROCESS")
          params.terminateProcess = e.VALUE;

        if (e.NAME === "SHUTDOWN_SERVER")
          params.shutdownServer = e.VALUE;

        if (params.emailAddresses !== null &&
            params.intrusionDetection !== null &&
            params.displayAlert !== null &&
            params.terminateProcess !== null &&
            params.shutdownServer !== null) {
          //console.log(params)
          this.setState({
            emailAddresses: params.emailAddresses,
            intrusionDetection: params.intrusionDetection,
            displayAlert: params.displayAlert,
            terminateProcess: params.terminateProcess,
            shutdownServer: params.shutdownServer
          });
          return;
        }
      }
    })
  }

  editName = () => {
    this.setState({
      displayDialog: dialogEditName
    })
  }

  editFolders = () => {
    this.setState({
      displayDialog: dialogEditFolders
    })
  }

  editApplications = () => {
    this.setState({
      displayDialog: dialogEditApplications
    })
  }

  editEmailAddresses = () => {
    if (this.state.intrusionDetection === null) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Waiting on server to update, try again in a few seconds!");
      return;
    }

    this.setState({
      displayDialog: dialogEditEmail
    })
  }

  deleteRule = (ruleObj) => {
    this.props.onDeleteRule(ruleObj);
  }

  updateRule = (ruleObj) => {
    if (ruleObj) {
      let rules = mergeRuleObj(ruleObj, this.props.drive.rules);
      this.props.onUpdateDriveRules(rules, false);

      this.setState({
        displayDialog: dialogsHidden,
        blockingRule: ruleObj
      })
    } else {
      this.setState({
        displayDialog: dialogsHidden
      })
    }
  }

  updateEmailAddresses = (email) => {
    if (email) {
      this.setState({
        displayDialog: dialogsHidden,
        emailAddresses: null
      }, () => {
        let o = JSON.stringify({
          EMAIL_ADDRESSES: email
        });
        window.roFolderWall.AddUpdateFeature(this.props.drive.id, "ghost_file_monitor", this.state.ruleObj.id, o, (updatedFeature, stateValue) => {
          this.setState({
            emailAddresses: email
          })
        }, (error) => {
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
        })
      })
    } else {
      this.setState({
        displayDialog: dialogsHidden
      })
    }
  }

  toggleIntrusionDetection = () => {
    if (this.state.intrusionDetection === null) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Waiting on server to update, try again in a few seconds!");
      return;
    }

    this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Modify setting?", "Are you sure you want to change the intrusion detection?", () => {
      let id = this.state.intrusionDetection;
      this.setState({
        intrusionDetection: null
      }, () => {
        let o = JSON.stringify({
          INTRUSION_DETECTION_READ_PERCENT: id === 0 ? 100 : 0
        });
        window.roFolderWall.AddUpdateFeature(this.props.drive.id, "ghost_file_monitor", this.state.ruleObj.id, o, (updatedFeature, stateValue) => {
          this.setState({
            intrusionDetection: id === 0 ? 100 : 0
          })
        }, (error) => {
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
        })
      });
    });
  }

  toggleDisplayAlert = () => {
    if (this.state.shutdownServer === null) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Waiting on server to update, try again in a few seconds!");
      return;
    }

    this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Modify setting?", "Are you sure you want to change the display alert state?", () => {
      let da = !this.state.displayAlert;
      this.setState({
        displayAlert: null
      }, () => {
        let o = JSON.stringify({
          DISPLAY_ALERT: da
        });
        window.roFolderWall.AddUpdateFeature(this.props.drive.id, "ghost_file_monitor", this.state.ruleObj.id, o, (updatedFeature, stateValue) => {
          this.setState({
            displayAlert: da
          })
        }, (error) => {
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
        })
      });
    });
  }

  toggleTerminateProcess = () => {
    if (this.state.shutdownServer === null) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Waiting on server to update, try again in a few seconds!");
      return;
    }

    this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Modify setting?", "Are you sure you want to change the terminate process state?", () => {
      let kp = !this.state.terminateProcess;
      this.setState({
        terminateProcess: null
      }, () => {
        let o = JSON.stringify({
          KILL_PROCESS: kp
        });
        window.roFolderWall.AddUpdateFeature(this.props.drive.id, "ghost_file_monitor", this.state.ruleObj.id, o, (updatedFeature, stateValue) => {
          this.setState({
            terminateProcess: kp
          })
        }, (error) => {
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
        })
      });
    });
  }

  toggleServerShutdown = () => {
    if (this.state.shutdownServer === null) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Waiting on server to update, try again in a few seconds!");
      return;
    }

    this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Modify setting?", "Are you sure you want to change the shutdown server state?", () => {
      let ss = !this.state.shutdownServer;
      this.setState({
        shutdownServer: null
      }, () => {
        let o = JSON.stringify({
          SHUTDOWN_SERVER: ss
        });
        window.roFolderWall.AddUpdateFeature(this.props.drive.id, "ghost_file_monitor", this.state.ruleObj.id, o, (updatedFeature, stateValue) => {
          this.setState({
            shutdownServer: ss
          })
        }, (error) => {
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
        })
      });
    });
  }

  booleanToStr = (value) => {
    if (value)
      return "Yes";
    return "No";
  }

  sendTestMessage = () => {
    window.roFolderWall.SendClientMessage(99, "TEST MESSAGE - The Cyber Canary", "This is a test message ONLY for \"The Cyber Canary\", please ignore!<br/>You should receive<br/>1) A full screen notification.<br/>2) An email.", this.state.emailAddresses, () => {
      this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Message sent!");
    }, (error) => {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
    });
  }

  render = () => {
    return (
      <React.Fragment>
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogEditName}>
            <EditName {...this.props} rule={this.props.rule} drive={this.props.drive} onUpdateRule={this.updateRule} />
          </Case>
          <Case value={dialogEditFolders}>
            <EditFolders {...this.props} rule={this.props.rule} drive={this.props.drive} onUpdateRule={this.updateRule} singleFolderOnly="true" />
          </Case>
          <Case value={dialogEditApplications}>
            <EditApplications {...this.props} rule={this.state.blockingRule ? encodeRule(this.state.blockingRule) : null} drive={this.props.drive} onUpdateRule={this.updateRule} />
          </Case>
          <Case value={dialogEditEmail}>
            <EditEmail {...this.props} email={this.state.emailAddresses} onUpdateEmail={this.updateEmailAddresses} />
          </Case>
        </Switch>
        <h3 className="text-primary">
          <i className="far fa-shield" />&nbsp;&nbsp;<span className="h4 text-primary">{this.state.ruleObj.name}</span>
          &nbsp;
          <sup className="h5">
            <Link to="#" onClick={(e) => this.editName()} style={{ color: "#e14eca" }}>
              <i className="far fa-edit" />
            </Link>
          </sup>
        </h3>
        <h5 className="text-muted">
          <ExpandingText text={this.state.ruleObj.metadata.DESC} startLength="165" linkStyle="text-muted" />
        </h5>
        <h5>
          <span className="text-success-2">
            Monitored folders
            &nbsp;&nbsp;
            <span className="text-success">{arrayToFormattedStr(this.state.ruleObj.folders)}</span>
            &nbsp;&nbsp;
            <Link to="#" onClick={(e) => this.editFolders()}>
              <span className="text-success-2">
                <i className="far fa-edit" />
              </span>
            </Link>
          </span>
        </h5>
        <h5>
          <span className="text-success-2">
            Notify email addresses
            &nbsp;&nbsp;
            <span className="text-success">{this.state.emailAddresses ? this.state.emailAddresses : ""}</span>
            &nbsp;&nbsp;
            {this.state.emailAddressesEdit ?
              <Link to="#" onClick={(e) => this.editEmailAddresses()}>
                <span className="text-success-2">
                  <i className={this.state.emailAddresses !== null ? "far fa-edit" : "far fa-spinner"}/>
                </span>
              </Link> : null}
          </span>
        </h5>
        <h5>
          <span className="text-success-2">
            Display alert
            &nbsp;&nbsp;
            <span className="text-success">{this.state.displayAlert !== null ? (this.state.displayAlert ? "Enabled" : "Disabled") : ""}</span>
            &nbsp;&nbsp;
            {this.state.displayAlertEdit ?
              <Link to="#" onClick={(e) => this.toggleDisplayAlert()}>
                <span className="text-success-2">
                  <i className={this.state.displayAlert !== null ? (this.state.displayAlert ? "far fa-toggle-on" : "far fa-toggle-off") : "far fa-spinner"}/>
                </span>
              </Link> : null}
          </span>
        </h5>
        <h5>
          <span className="text-success-2">
            Terminate process
            &nbsp;&nbsp;
            <span className="text-success">{this.state.terminateProcess !== null ? (this.state.terminateProcess ? "Enabled" : "Disabled") : ""}</span>
            &nbsp;&nbsp;
            {this.state.terminateProcessEdit ?
              <Link to="#" onClick={(e) => this.toggleTerminateProcess()}>
                <span className="text-success-2">
                  <i className={this.state.terminateProcess !== null ? (this.state.terminateProcess ? "far fa-toggle-on" : "far fa-toggle-off") : "far fa-spinner"}/>
                </span>
              </Link> : null}
          </span>
        </h5>
        <h5>
          <span className="text-success-2">
            Hide canary files from
            {this.state.blockingRule ?
              <React.Fragment>
                  &nbsp;&nbsp;
                  <span className="text-success">
                      <ExpandingText items={this.state.blockingRule.processes} startItems="2" linkStyle="text-success" replaceArray={Consts.PROCESS_REPLACE_ARRAY} />
                  </span>
                  &nbsp;&nbsp;
                  <Link to="#" onClick={(e) => this.editApplications()}>
                      <span className="text-success-2">
                          <i className="far fa-edit" />
                      </span>
                  </Link>
              </React.Fragment>
            :
              <React.Fragment>
                  &nbsp;&nbsp;
                  <span className="text-success">
                      Option not implemented (you will need to re-create the rule to add this option)
                  </span>
              </React.Fragment>
            }
          </span>
        </h5>
        {this.state.intrusionDetectionEdit ?
          <h5>
            <span className="text-success-2">
              Intrusion detection
              &nbsp;&nbsp;
              <span className="text-success">{this.state.intrusionDetection !== null ? (this.state.intrusionDetection === 0 ? "Disabled" : "Enabled") : ""}</span>
              &nbsp;&nbsp;
              <Link to="#" onClick={(e) => this.toggleIntrusionDetection()}>
                <span className="text-success-2">
                  <i className={this.state.intrusionDetection !== null ? (this.state.intrusionDetection ? "far fa-toggle-on" : "far fa-toggle-off") : "far fa-spinner"}/>
                </span>
              </Link>
            </span>
          </h5> : null }
        {this.state.shutdownServerEdit ?
          <h5>
            <span className="text-success-2">
              Shutdown server
              &nbsp;&nbsp;
              <span className="text-success">{this.state.shutdownServer !== null ? (this.state.shutdownServer ? "Enabled" : "Disabled") : ""}</span>
              &nbsp;&nbsp;
              <Link to="#" onClick={(e) => this.toggleServerShutdown()}>
                <span className="text-success-2">
                  <i className={this.state.shutdownServer !== null ? (this.state.shutdownServer ? "far fa-toggle-on" : "far fa-toggle-off") : "far fa-spinner"}/>
                </span>
              </Link>
            </span>
          </h5> : null }
        {Consts.SYSTEM.PRODUCT_ID !== "tcc" ?
          <React.Fragment>
            <ValidateRule mountPointId={this.props.drive.rootPath} rule={encodeRule(this.state.ruleObj)} ruleId={this.state.ruleObj.id} />
          </React.Fragment> : null
        }
        <hr style={{ borderColor: "#444444" }} />
        {this.state.showVirtualFiles ?
          <React.Fragment>
            <small><Link to="#" onClick={(e) => this.setState({ showVirtualFiles: false })}><span className="text-success-2">Hide canary files</span></Link></small><br/>
            <code>
              {this.virtualFiles.map((vf, i) => {
                return(
                    <React.Fragment>
                      <small key={i}>{vf}</small><br/>
                    </React.Fragment>
                )})
              }
            </code>
            <hr style={{ borderColor: "#444444" }} />
          </React.Fragment>
        :
          <React.Fragment>
            <h5>
              <small>
                You can <Link to="#" onClick={(e) => this.setState({ showVirtualFiles: true })}><span className="text-success-2">display the canary files</span></Link> that are installed.
              </small>
            </h5>
          </React.Fragment>
        }
        <h5>
          <small>
            You can send a <Link to="#" onClick={(e) => this.sendTestMessage()}><span className="text-success-2">test notification</span></Link> to see if all is working, and what to expect should the worst occur.
          </small>
        </h5>
        <h5>
          <small>
            For details on the above options, see <a href="https://support.division-m.com/hc/en-us/articles/360001690036" target="_blank" rel="noopener noreferrer"><span className="text-success-2">The Cyber Canary documentation</span></a>.
          </small>
        </h5>
        <div className="text-right" style={{ marginTop: "20px" }}>
          <Link to="#">
            <span className="text-primary" style={{ marginRight: "20px" }} onClick={(e) => this.deleteRule(this.state.ruleObj)}>
              <i className="far fa-trash-alt" />
            </span>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}