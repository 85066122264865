import React from "react";
//import { Link } from "react-router-dom";
import { decodeRule, encodeRule } from "config/utilities";
import Switch, { Case } from "react-switch-case";
import { RuleEditFixed } from "components/dialogs/rule-edit-fixed";
import { Consts } from "config/consts";

const dialogsHidden = 0;
const dialogEditRule = 1;

export class RuleFixed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleObj: decodeRule(this.props.rule),
      displayDialog: dialogsHidden
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (decodeRule(props.rule) !== state.ruleObj) {
      state.ruleObj = decodeRule(props.rule);
      return state;
    }
    return null;
  }

  editRule = () => {
    this.setState({
      displayDialog: dialogEditRule
    });
  }

  deleteRule = () => {
    // check if we can delete
    if (this.state.ruleObj.metadata.FIXED) {
      this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "ANVIL error", "This rule is fixed and cannot be deleted here. If you do wish to delete it (not recommended), do so via the advanced interface.");
      return;
    }

    if (this.state.ruleObj.Id === "{0}") {
      this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "ANVIL error", "This rule is the result of an an issue with your rules. Check the rules description for further information.");
      return;
    }

    if (this.props.onDeleteRule)
      this.props.onDeleteRule(this.state.ruleObj);
  }

  updateRule = (ruleObj) => {
    if (ruleObj) {
      if (this.props.onUpdateDriveRules) {
        // need to pass as an array
        let rules = [encodeRule(ruleObj)];
        this.props.onUpdateDriveRules(rules);
        this.setState({ ruleObj: ruleObj });
      }
    }
    this.setState({ displayDialog: dialogsHidden })
  }

  render = () => {
    let title = this.state.ruleObj.access;
    if (this.props.rule.indexOf("[L+R+W+]|[*]|[*]") !== -1) title = "Full";
    if (this.props.rule.indexOf("[L+R+W?]|[*]|[*]") !== -1) title = "Full (request write)";
    if (this.props.rule.indexOf("[L+R+W1]|[*]|[*]") !== -1) title = "Full (immutable)";
    if (this.props.rule.indexOf("[L+R+W-]|[*]|[*]") !== -1) title = "Read only";
    if (this.props.rule.indexOf("[L+R-W-]|[*]|[*]") !== -1) title = "Listing only";
    if (this.props.rule.indexOf("[L-R-W-]|[*]|[*]") !== -1) title = "No";
    if (this.props.drive.rules.length === 1) {
      title = title + " access to the entire drive";
    } else {
      title = title + " access to the remaining folders";
    }
    return (
      <React.Fragment>
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogEditRule}>
            <RuleEditFixed {...this.props} onClose={this.updateRule} />
            <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
          </Case>
        </Switch>
        <h3 className="text-primary">
          <i className="far fa-link" />&nbsp;&nbsp;<span className="h4 text-primary">{title}</span>
        </h3>
        <p className="h5 text-muted">
          <strong>{this.state.ruleObj.name}</strong><br />
          {this.state.ruleObj.metadata ? this.state.ruleObj.metadata.DESC : null}
        </p>
        {/* <div className="text-right" style={{ marginTop: "20px" }}>
          <Link to="#">
            <span className="text-primary" style={{ marginRight: "20px" }} onClick={() => this.editRule()}>
              <i className="fa fa-edit" />
            </span>
          </Link>
          <Link to="#">
            <span className="text-primary" style={{ marginRight: "20px" }} onClick={() => this.deleteRule()}>
              <i className="fa fa-trash-alt" />
            </span>
          </Link>
        </div> */}
      </React.Fragment>
    );
  }
}