import React from "react";
import { Button, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Input, Row, Col, Label } from "reactstrap";
import { AuthenticationContext } from "context/authentication";
import { userModel } from "context/user"
import { Consts } from "config/consts";
import { Redirect } from "react-router-dom";
import Switch, { Case } from "react-switch-case";
import { TermsAndConditions } from "components/dialogs/terms-and-conditions"

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.props.setCleanPage();
    this.state = {
      name: "",
      email: "",
      password: "",
      terms: false,
      validated: true,
      allowAutoLogin: true,
      redirect: false,
      showTsAndCs: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    // update state... ignore checkbox
    if (e.target.type === "checkbox")
      return;
    this.setState({ [e.target.name]: e.target.value });
  }

  authCreateUserWithEmailAndPassword = (e, authContext) => {
    e.preventDefault();

    if (!this.state.name) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You must enter your full name.");
      return;
    }

    if (!this.state.email) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You must enter an email address.");
      return;
    }

    if (!this.state.password) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You must enter a password.");
      return;
    }

    if (!this.state.terms) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You must agree to the terms and conditions.");
      return;
    }

    // we are manually logging in... make sure state change event ignored
    this.props.setLoader(true);
    this.setState({ allowAutoLogin: false }, () => {
      let userObject = userModel;
      userObject.name_first = this.state.name.split(' ').slice(0, -1).join(' ');
      userObject.name_last = this.state.name.split(' ').slice(-1).join(' ');

      authContext.registerUser(this.state.email, this.state.password, userModel).then((user) => {
        console.log("User successfully created and signed in with email: %s", this.state.email);
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Registration successful, you need to verify your email address, please wait...");
        setTimeout(() => {
          this.setState({ redirect: true });
        }, 2000);
      }).catch((error) => {
        console.log(error.toString());
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.toString());
        this.props.setLoader(false);
      })
    });
  }

  authStateChanged = (user) => {
    if (this.state.allowAutoLogin && user) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Auto login processed, please wait...");
      this.props.setLoader(true);
      setTimeout(() => {
        this.setState({ redirect: true });
      }, 2000);
    }
  }

  registerOAuth = (oauth, e, authContext) => {
    e.preventDefault();

    if (!this.state.terms) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You must agree to the terms and conditions.");
      return;
    }

    this.props.setLoader(true);
    this.setState({ allowAutoLogin: false }, () => {
      authContext.authenticateOAuth(oauth).then((user) => {
        console.log("User successfully registered and signed in with Google/Facebook");
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Registration successful, please wait...");
        setTimeout(() => {
          this.setState({ redirect: true });
        }, 2000);
      }).catch((error) => {
        console.log(error.toString());
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.toString());
        this.props.setLoader(false);
      })
    });
  }

  showTsAndCs = () => {
    if (this.state.terms) {
      this.setState({
        terms: false
      })
    } else {
      this.setState({
        showTsAndCs: true
      })
    }
  }

  closeTsAndCs = (agree) => {
    this.setState({
      showTsAndCs: false,
      terms: agree
    })
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to={Consts.USER.DEFAULT_HOME} />
      )
    }

    return (
      <React.Fragment>
        <AuthenticationContext.Consumer>
          {authContext =>
            authContext.monitorStateChange(this.authStateChanged.bind(this))
          }
        </AuthenticationContext.Consumer>
        <Switch condition={this.state.showTsAndCs}>
          <Case value={true}>
            <TermsAndConditions {...this.props} onClose={(agree) => this.closeTsAndCs(agree)} />
          </Case>
        </Switch>
        <div className="content-full-page">
          <div className="register">
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <h5 className="title">Register with email and password</h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label>Name</Label>
                            <Input name="name" placeholder="Required" type="text" onChange={(e) => this.handleChange(e)} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label>Email</Label>
                            <Input name="email" placeholder="Required" type="email" onChange={(e) => this.handleChange(e)} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label>Password</Label>
                            <Input name="password" placeholder="Required" type="password" onChange={(e) => this.handleChange(e)} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroup check>
                            <Label check>
                              <Input name="terms" type="checkbox" checked={this.state.terms} onClick={(e) => this.showTsAndCs()} onChange={(e) => this.handleChange(e)} />{' '}
                              Terms and conditions
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <AuthenticationContext.Consumer>
                      {authContext =>
                        <div className="text-right">
                          <Button className="btn-fill" color="primary" onClick={e => this.authCreateUserWithEmailAndPassword(e, authContext)}>
                            Register
                          </Button>
                        </div>
                      }
                    </AuthenticationContext.Consumer>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  <h4>-OR-</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <h5 className="title">Register with</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="text-center">
                      <AuthenticationContext.Consumer>
                        {authContext =>
                          <Row>
                            <Col md={12}>
                              <Button className="btn-fill" color="primary" style={{ width: "100%" }} onClick={(e) => this.registerOAuth(Consts.OAUTH_PROVIDERS.GOOGLE, e, authContext)}>
                                <i className="fab fa-google"/>&nbsp;&nbsp;Google
                              </Button>
                            </Col>
                          </Row>
                        }
                      </AuthenticationContext.Consumer>
                      <AuthenticationContext.Consumer>
                        {authContext =>
                          <Row>
                            <Col md={12}>
                              <Button className="btn-fill" color="primary" style={{ width: "100%" }} onClick={(e) => this.registerOAuth(Consts.OAUTH_PROVIDERS.FACEBOOK, e, authContext)}>
                                <i className="fab fa-facebook"/>&nbsp;&nbsp;Facebook
                              </Button>
                            </Col>
                          </Row>
                        }
                      </AuthenticationContext.Consumer>
                      <Row>
                        <Col md={12}>
                          <FormGroup check>
                            <Label check>
                              <Input name="terms" type="checkbox" checked={this.state.terms} onClick={(e) => this.showTsAndCs()} onChange={(e) => this.handleChange(e)} />{' '}
                              Terms and conditions
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Register;
