import React from "react";
import Switch, { Case } from "react-switch-case";
import { decodeRule, encodeRule } from "config/utilities.js";

export class RuleText extends React.Component {

  getRulesCountAsText = (drive) => {
    if (drive.rules.length === 0)
      return "There are no rules for this drive"
    if (drive.rules.length === 1)
      return "There is 1 rule for this drive"
    return "There are " + drive.rules.length + " rules for this drive";
  }

  getRuleNameAsText = (name, link) => {
    // create base links
    let l1 = link ? "<a href=\"#name\"style=\"text-decoration: underline; color: " + this.props.color + "\"><strong>" : "<strong>";
    let l2 = link ? "</strong></a>" : "</strong>";

    return l1 + name + l2;
  }

  getRuleAccessAsText = (access, link) => {
    // all access
    let l1 = link ? "<a href=\"#access\" style=\"text-decoration: underline; color: " + this.props.color + "\"><strong>" : "<strong>";
    let l2 = link ? "</strong></a>" : "</strong>";

    if (access === "[L+R+W+]")
      return l1 + "Full access" + l2;

    // read only
    if (access === "[L+R+W-]")
      return l1 + "Read only access" + l2;

    // no access
    if (access === "[L+R-W-]")
      return l1 + "Listing only" + l2 + " (no file reads or writes) access";

    // no access no listing
    if (access === "[L-R-W-]")
      return l1 + "No access at all" + l2 + " (no file listing, reads or writes)";

    // prompt
    if (access === "[L+R+W?]")
      return l1 + "Read only access, prompt the user to allow writes" + l2;

    // immutable
    if (access === "[L+R+W1]")
      return l1 + "Read only access, immutable writes" + l2 + " (file written once, cannot be modified)";

    // encryption
    if (access === "[L+R*W*]")
      return l1 + "Read decrypted, write encrypted";

    return "Cannot resolve access";
  }

  getLinkWithArray = (openTag, closeTag, array, index) => {
    return (openTag + array[index] + closeTag).replace("_n", "_" + index);
  }

  getRuleProcessesAsText = (processes, link) => {
    // create base links
    let l1 = link ? "<a href=\"#processes_n\"style=\"text-decoration: underline; color: " + this.props.color + "\"><strong>" : "<strong>";
    let l2 = link ? "</strong></a>" : "</strong>";

    // split processes
    let p = processes.replace("[", "").replace("]", "").split(",");

    // for wildcard
    if (processes === "[*]")
      return this.getLinkWithArray(l1, l2, ["all applications"], 0);

    // for no processes
    if (processes === "[-]")
      return this.getLinkWithArray(l1, l2, ["no applications"], 0);

    // for inside
    if (processes === "[^]")
      return this.getLinkWithArray(l1, l2, ["all applications inside the target folder"], 0);

    // for inside wildcard
    if (processes === "[^*]")
      return this.getLinkWithArray(l1, l2, ["all applications inside the target and sub folders"], 0);

    // for inside wildcard
    if (processes === "[<*>]")
      return this.getLinkWithArray(l1, l2, ["all signed applications"], 0);

    // based on length, return text
    if (p.length === 1)
      return "the application<br/> " + this.getLinkWithArray(l1, l2, p, 0);

    if (p.length === 2)
      return "the applications<br/> " + this.getLinkWithArray(l1, l2, p, 0) + " and<br/> " + this.getLinkWithArray(l1, l2, p, 1);

    let r = "";
    for (let i = 0; i < p.length; i++) {
      r = r + (r.length > 0
        ? i < p.length - 1 ? ",<br/> " + this.getLinkWithArray(l1, l2, p, i) : " and<br/> " + this.getLinkWithArray(l1, l2, p, i)
        : this.getLinkWithArray(l1, l2, p, i))
    }
    return "the processes<br/> " + r;
  }

  getRuleFoldersAsText = (folders, link) => {
    // create base links
    let l1 = link ? "<a href=\"#folders_n\"style=\"text-decoration: underline; color: " + this.props.color + "\"><strong>" : "<strong>";
    let l2 = link ? "</strong></a>" : "</strong>";

    // split folders
    let f = folders.replace("[", "").replace("]", "").split(",");

    // check for reference
    if (folders.indexOf("@") === 1) {
      let id = folders.toUpperCase().replace("@", "").replace("[", "").replace("]", "").split(",");
      return "Using folder(s) from rule Id " + id;
    }

    // for wildcard
    if (folders === "[*]")
      return this.getLinkWithArray(l1, l2, ["all paths"], 0);

    // no folders
    if (f.length === 0 || (f.length === 1 && f[0] === ""))
      return "the path<br/> " + this.getLinkWithArray(l1, l2, ["NO PATHS DEFINED"], 0);

    // based on length, return text
    if (f.length === 1)
      return "the path<br/> " + this.getLinkWithArray(l1, l2, f, 0);

    if (f.length === 2)
      return "the paths<br/> " + this.getLinkWithArray(l1, l2, f, 0) + " and<br/> " + this.getLinkWithArray(l1, l2, f, 1);

    let r = "";
    for (let i = 0; i < f.length; i++) {
      r = r + (r.length > 0
        ? i < f.length - 1 ? ",<br/> " + this.getLinkWithArray(l1, l2, f, i) : " and<br/> " + this.getLinkWithArray(l1, l2, f, i)
        : this.getLinkWithArray(l1, l2, f, i))
    }
    return "the paths " + r;
  }

  getRuleAsText = (rule) => {
    // no rule
    if (!rule)
      return "There is no rule, this is an error!"

    // make sure string
    if (typeof (rule) === "object")
      rule = encodeRule(rule);

    let ruleObj = decodeRule(rule);
    let r =
      this.getRuleAccessAsText(ruleObj.access, false) +
      "<br/> for " +
      this.getRuleProcessesAsText(ruleObj.processesStr, false) +
      "<br/> on " +
      this.getRuleFoldersAsText(ruleObj.foldersStr, false);

    if (!this.props.listView)
      r = r.replace(/<br\/>/g, "");

    r = "<span style=\"color: " + this.props.color + "; font-family:  monospace; font-size: 1.3rem !important; font-weight: 400\">Name - <strong>" + this.getRuleNameAsText(ruleObj.name, false) + "</strong><br/>Rule - " + r + "</span>";
    return (
      <span dangerouslySetInnerHTML={{ __html: r}} />
    );
  }

  getRuleAsLinks = (rule) => {
    // no rule
    if (!rule)
      return "There is no rule, this is an error!"

    // make sure string
    if (typeof(rule) === "object")
      rule = encodeRule(rule);

    let ruleObj = decodeRule(rule);
    let r =
      this.getRuleAccessAsText(ruleObj.access, true) +
      "<br/> for " +
      this.getRuleProcessesAsText(ruleObj.processesStr, true) +
      "<br/> on " +
      this.getRuleFoldersAsText(ruleObj.foldersStr, true);

    if (!this.props.listView)
      r = r.replace(/<br\/>/g, "");

    //r = "<span style=\"color: " + this.props.color + "; font-family:  monospace; font-size: 1.3rem !important; font-weight: 400\">" + r + "</span>";
    r = "<span style=\"color: " + this.props.color + "; font-family:  monospace; font-size: 1.3rem !important; font-weight: 400\">Name - <strong>" + this.getRuleNameAsText(ruleObj.name, true) + "</strong><br/>Rule - " + r + "</span>";
    return (
      <div dangerouslySetInnerHTML={{ __html: r }} />
    );
  }

  getDescription = (rule) => {
    // make sure string
    if (typeof (rule) === "object")
      rule = encodeRule(rule);

    let ruleObj = decodeRule(rule);
    if (ruleObj.metadata) {
      return (
        <React.Fragment>
          <br/>
          <span style={{ color: this.props.color, fontFamily: "monospace", fontSize: "1.3rem !important", fontWeight: "400" }}>Description - {ruleObj.metadata.DESC}<br/>Rule Id - {ruleObj.id}</span>
        </React.Fragment>
      );
    }
    return null;
  }

  render() {
    return (
      <React.Fragment>
        <Switch condition={this.props.type}>
          <Case value="text">
            {this.getRuleAsText(this.props.rule)}
            {this.getDescription(this.props.rule)}
          </Case>
          <Case value="links">
            {this.getRuleAsLinks(this.props.rule)}
            {this.getDescription(this.props.rule)}
          </Case>
        </Switch>
      </React.Fragment>
    );
  }
}