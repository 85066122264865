import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, FormGroup, Input } from "reactstrap";

export class EditFileName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: this.props.fileName ? this.props.fileName : "",
      valid: false,
      modified: false
    }
  }

  handleChange = (e) => {
    this.setState({
      fileName: e.target.value,
      modified: true
    }, () => {
      // check for file
      console.log("Checking file: ", this.props.drive.mountName + this.props.baseFolder + "\\" + this.state.fileName);
      window.roFolderWall.GetFileDetails(this.props.drive.mountName + this.props.baseFolder + "\\" + this.state.fileName, (valid, fileDetails) => {
        this.setState({
          valid: valid
        })
      });
    });
  }

  closeDialog = (save) => {
    if (!save) {
      this.props.onUpdateFileName(null);
    } else {
      let fn = this.state.fileName;
      let modified = this.state.modified;
      this.props.onUpdateFileName(modified ? fn : null)
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="edit-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <ModalHeader close={closeBtn}>
            <Row>
              <Col xs={12}>
                <h3 className="text-primary">
                  <i className="far fa-folders" />&nbsp;Edit file name
                </h3>
                {this.state.fixedName ?
                  <h5 className="text-primary-2">
                    Edit folder for <span className="text-primary">{this.state.fixedName}</span>
                  </h5>
                  : null
                }
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody id="classic-modal-slide-description">
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <h5 className="text-success"><i className="fal fa-folder" />&nbsp;&nbsp;Base folder&nbsp;&nbsp;<span className="text-success-2">{this.props.baseFolder}</span></h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                  <h5 className="text-success"><i className="fal fa-pen-square" />&nbsp;&nbsp;Edit file name&nbsp;&nbsp;<span className="text-success-2"></span></h5>
                    <FormGroup>
                      <Input name="name" type="text" value={this.state.name} onChange={(e) => this.handleChange(e)} />
                      {this.state.valid ?
                        <small className="text-success">The file name is valid</small>
                      :
                        <small className="text-danger">The file name is invalid</small>
                      }
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right">
              <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
              <Button color="default" onClick={(e) => this.closeDialog(true)} disabled={!this.state.valid}>Apply</Button>
            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}