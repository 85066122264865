import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { decodeRule, createGuid } from "config/utilities";
import { ApplicationItem } from "components/common/application-item";
import { EditRuleApplication } from "components/common/edit-rule-application.jsx"

export class EditApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processes: this.props.rule ? decodeRule(this.props.rule).processes : this.props.processes,
      modified: false,
      applicationSelected: null,
      runningProcesses: false,
      reloadContext: null,
      fixedName: this.props.rule ? decodeRule(this.props.rule).name : null
    }
  }

  deleteApplication = (app) => {
    let p = this.state.processes;
    for (let i = 0; i < p.length; i++) {
      if (p[i] === app) {
        p.splice(i, 1);
        break;
      }
    }
    this.setState({
      processes: p,
      modified: true,
    })
  }

  addApplication = (saveAndCloseOnAdd) => {
    let p = this.state.processes;
    // if wilcard, clear... or clear if was *
    if (this.state.applicationSelected === "*" || this.state.applicationSelected === "-") {
      p = [];
    } else {
      if (p.length === 1 && (p[0] === "*" || p[0] === "-"))
        p = [];
    }

    if (p === []) {
      // reset
      this.setState({
        processes: p,
      }, () => {
          p.push(this.state.applicationSelected.indexOf("<") !== 0 ? this.state.applicationSelected.toLowerCase() : this.state.applicationSelected);
          this.setState({
            processes: p,
            modified: true,
            applicationSelected: null
          }, () => {
            if (saveAndCloseOnAdd)
              this.closeDialog(true);
          })
      })
    } else {
      p.push(this.state.applicationSelected.indexOf("<") !== 0 ? this.state.applicationSelected.toLowerCase() : this.state.applicationSelected);
      this.setState({
        processes: p,
        modified: true,
        applicationSelected: null
      }, () => {
        if (saveAndCloseOnAdd)
          this.closeDialog(true);
      })
    }
  }

  renderApplications = () => {
    return (
      <React.Fragment>
        {this.state.processes.map((app, i) => {
          return (
            <ApplicationItem process={app} color="default" isOpen={true} onDelete={(e) => this.deleteApplication(app)} drive={this.props.drive} key={i} />
          )
        })}
      </React.Fragment>
    );
  }

  applicationSelected = (newApplication) => {
    this.setState({
      applicationSelected: newApplication
    })
  }

  toggleApplications = () => {
    this.setState({
      runningProcesses: !this.state.runningProcesses,
      reloadContext: createGuid()
    }, () => {
        this.props.setGlobalState("runningProcessSelect", this.state.runningProcesses);
    })
  }

  closeDialog = (save) => {
    if (!save) {
      if (this.props.rule) {
        this.props.onUpdateRule(null);
      } else {
        this.props.onUpdateProcesses(null);
      }
    } else {
      // if we have any pending applications, ask
      if (this.state.applicationSelected) {        
        // add
        this.addApplication(true);
        return;
      }

      if (this.props.rule) {
        // output rule
        let ro = decodeRule(this.props.rule);
        ro.processes = this.state.processes;
        
        // update rule
        this.props.onUpdateRule(this.state.modified ? ro : null);
      } else {
        // output processes
        let p = this.state.processes;

        // update processes
        this.props.onUpdateProcesses(this.state.modified ? p : null);
      }
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="edit-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <ModalHeader close={closeBtn}>
            <Row>
              <Col xs={12}>
                <h3 className="text-primary">
                  <i className="far fa-browser" />&nbsp;Edit applications
                </h3>
                {this.state.fixedName ?
                  <h5 className="text-primary-2">
                    Edit applications for <span className="text-primary">{this.state.fixedName}</span>
                  </h5>
                  : null
                }
                <h5 className="text-primary-2">
                <a href="https://support.division-m.com/hc/en-us/articles/360001053275" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">Click here</a> for details on how to add or remove applications, and how to manually add values not in the list.
                </h5>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody id="classic-modal-slide-description">
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <h5 className="text-success"><i className="fal fa-minus-square" />&nbsp;&nbsp;Remove existing applications<span className="text-success-2"></span></h5>
                    {this.renderApplications()}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <hr style={{ borderColor: "#444444" }} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <h5 className="text-success"><i className="fal fa-plus-square" />&nbsp;&nbsp;Add a new application<span className="text-success-2"></span></h5>
                    <EditRuleApplication process={this.state.applicationSelected} onChange={(newApplication) => { this.applicationSelected(newApplication) }} runningProcesses={this.state.runningProcesses} reloadContext={this.state.reloadContext} placeHolder="Select an application" />
                    <Row>
                      <Col xs={6}>
                        <div className="text-left">
                          <Button className="btn-link" color="success" onClick={(e) => this.toggleApplications()}>
                            {this.state.runningProcesses ? "Select from installed applications" : "Select from running applications"}
                          </Button>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="text-right">
                          <Button className="btn-link" color="success" onClick={(e) => this.addApplication()} disabled={!this.state.applicationSelected}>
                            Add this application
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right">
              <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
              <Button color="default" onClick={(e) => this.closeDialog(true)} disabled={this.state.applicationSelected === null && this.state.processes.length === 0}>Apply</Button>
            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}