import React from "react";
import { FirebaseFuncts } from "config/firebase";
import { Button, Card, CardBody, FormGroup, Form, Input, Row, Col, Label } from "reactstrap";
import { Redirect } from "react-router-dom"
import Switch, { Case, Default } from "react-switch-case";
import { Authentication } from "context/authentication";
import { Consts } from "config/consts";
import { LicenseAgreement } from "components/dialogs/license-agreement";
import { Installer } from "components/dialogs/installer";
import { Link } from 'react-router-dom'

const dialogsHidden = 0;
const dialogLicenseAgreement = 1;
const dialogInstaller = 2;

const modeWaiting = 0;
const modeAuthenticated = 1;
const modeAuthenticateAccessCode = 2;
const modeInstallationComplete = 3;
const modeError = 10;

const defaultInstallScript = "dynamic_snapshot_drive_";

class Install extends React.Component {
  constructor(props) {
    super(props);
    this.props.setCleanPage();

    // grab key if available
    this.state = {
      authContext: null,
      isAdminUser: false,
      displayDialog: dialogsHidden,
      redirect: null,
      installKey: this.props.location.pathname !== "/install" ? this.props.location.pathname.replace("/install/", "") : null,
      manageMode: modeWaiting,
      lastManageMode: null,
      errorMessage: null,
      authType: null,
      linkUid: null,
      accessCode: null,
      userDefinedVarsNames: "USER_ID;USER_EMAIL;USER_LICENSE_KEY;DEFAULT_INSTALL_SCRIPT;DEFAULT_INSTALL_SCRIPT_DISABLED",
      userDefinedVarsValues: null,
      licenseKey: null,
      availableLicenses: null,
      defaultSnapshot: null
    };
  }

  componentDidMount = () => {
    // nothing
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  checkInstallKey = () => {
    if (this.state.installKey) {
      this.props.setLoader(true);
      let linkInstallType = FirebaseFuncts.httpsCallable("getLinkInstallAuthType");
      linkInstallType({
        key: this.state.installKey
      }).then((result) => {
        // set mode
        let mm = modeWaiting;

        // access code?
        if (result.data.authType === "ACCESS_CODE")
          mm = modeAuthenticateAccessCode;

        // finally, if we are authenticated, then see if owner same and set auth logged in
        if (this.state.authContext && result.data.uid === this.state.authContext.state.uid)
          mm = modeAuthenticated;

        this.setState({
          manageMode: mm,
          authType: result.data.authType,
          linkUid: result.data.uid
        }, () => {
          // if authenticated then validate details
          if (this.state.manageMode === modeAuthenticated) {
            this.validateInstallerLoggedInUser();
          } else {
            this.props.setLoader(false);
          }
        });
      }).catch((error) => {
        console.log(error);
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message)
        this.props.setLoader(false);
        this.setState({
          errorMessage: error.message,
          lastManageMode: this.state.manageMode,
          manageMode: modeError
        })
      });
    }
  }

  onAuthenticated = (authContext) => {
    if (!this.state.authContext) {
      console.log("Updating authentication for user %s", authContext.state.uid);
      this.setState({
        authContext: authContext,
      }, () => {
        this.checkInstallKey();
      })
    }
  }

  onNotAuthenticated = (authContext) => {
    console.log("Removing authentication");
    this.setState({
      authContext: null
    }, () => {
      this.checkInstallKey();
    })
  }

  showLicenseAgreement = () => {
    this.setState({
      displayDialog: dialogLicenseAgreement
    })
  }

  closeLicenseAgreement = (agree) => {
    this.setState({
      displayDialog: agree ? dialogInstaller : dialogsHidden
    })
  }

  showInstaller = () => {
    this.setState({
      displayDialog: dialogInstaller
    })
  }

  closeInstaller = (installed) => {
    if (installed) {
      this.setState({
        displayDialog: dialogsHidden,
        manageMode: modeInstallationComplete
      })
    } else {
      this.setState({
        displayDialog: dialogsHidden
      })
    }
  }

  validateInstallerLoggedInUser = () => {
    // start loader
    this.props.setLoader(true);

    // get link
    let linkInstall = FirebaseFuncts.httpsCallable("getLinkInstall");
    linkInstall({
      authenticatedUid: this.state.linkUid,
      key: this.state.installKey,
      authType: "NONE",
      authentication: "NONE"
    }).then((result) => {
      // check to make sure product correct
      if (result.data.licenseType.indexOf(Consts.SYSTEM.PRODUCT_ID) !== 0) {
        // wrong product
        let err = "This install key is not for this product, please check your link.";
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, err)
        this.props.setLoader(false);
        this.setState({
          errorMessage: err,
          lastManageMode: this.state.manageMode,
          manageMode: modeError
        })
        return;
      }

      // set installer params
      this.setState({
        isAdminUser: true,
        manageMode: modeAuthenticated,
        userDefinedVarsValues: result.data.uid + ";" + result.data.licenseEmail + ";" + result.data.licenseKey + ";" + defaultInstallScript + result.data.defaultSnapshot.mount_name.substring(0, 1).toLowerCase() + "<%DISD%>" + this.state.installKey + ";0",
        licenseKey: result.data.licenseKey,
        licenseType: result.data.licenseType,
        availableLicenses: result.data.availableLicenses,
        defaultSnapshot: result.data.defaultSnapshot
      }, () => { this.props.setLoader(false) })

    }).catch((error) => {
      console.log(error);
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message)
      this.props.setLoader(false);
      this.setState({
        errorMessage: error.message,
        lastManageMode: this.state.manageMode,
        manageMode: modeError
      })
    });
  }

  validateInstallerAccessCode = () => {
    // validate
    if (!this.state.accessCode) {
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You must enter an access code!")
      return;
    }

    // start loader
    this.props.setLoader(true);

    // get link
    let linkInstallType = FirebaseFuncts.httpsCallable("getLinkInstall");
    linkInstallType({
      key: this.state.installKey,
      authType: this.state.authType,
      authentication: this.state.accessCode
    }).then((result) => {
      // check to make sure product correct
      if (result.data.licenseType.indexOf(Consts.SYSTEM.PRODUCT_ID) !== 0) {
        // wrong product
        let err = "This install key is not for this product, please check your link.";
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, err)
        this.props.setLoader(false);
        this.setState({
          errorMessage: err,
          lastManageMode: this.state.manageMode,
          manageMode: modeError
        })
        return;
      }

      // set installer params
      this.setState({
        manageMode: modeAuthenticated,
        userDefinedVarsValues: result.data.uid + ";" + result.data.licenseEmail + ";" + result.data.licenseKey,
        licenseKey: result.data.licenseKey,
        licenseType: result.data.licenseType,
        availableLicenses: result.data.availableLicenses,
        defaultSnapshot: result.data.defaultSnapshot
      }, () => { this.props.setLoader(false); })

    }).catch((error) => {
      console.log(error);
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message)
      this.props.setLoader(false);
      this.setState({
        errorMessage: error.message,
        lastManageMode: this.state.manageMode,
        manageMode: modeError
      })
    });
  }

  renderAuthenticated = () => {
    return (
      <div>
        <h4>{Consts.WEB_APP_RELEASE.FULL_NAME} installation</h4>
        {this.state.userDefinedVarsValues ?
          <React.Fragment>
            <h5>You are authenticated and ready to install, click the install button to proceed. <span className="text-danger">Important, you will need admin access for your machine to proceed.</span></h5>
            {this.state.isAdminUser ?
              <React.Fragment>
                <h5 className="text-primary">License key: {this.state.licenseKey}</h5>
                <h5 className="text-primary">Available licenses: {this.state.availableLicenses}</h5>
              </React.Fragment>: null }
            <h5 className="text-primary">Will install default snapshot: {this.state.defaultSnapshot ? "Yes" : "No"}</h5>
            {this.state.defaultSnapshot ?
              <h5 className="text-primary">Dynamic refresh of snapshot: {this.state.defaultSnapshot.dynamicRefresh !== "0" ? "Yes" : "No"}</h5>: null }
            <Button color="success" size="lg" onClick={e => this.showLicenseAgreement()} disabled={!this.state.userDefinedVarsValues}>
              Install
            </Button>
          </React.Fragment>
        :
          <h1>Getting detail for install key, please wait....</h1>
        }
      </div>
    );
  }

  renderAuthenticateAccessCode = () => {
    return (
      <div>
        <h4>{Consts.WEB_APP_RELEASE.FULL_NAME} installation</h4>
        <h5>To process with the installation, you require an access code. You can find the access code in the license information.</h5>
        <Form>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Access code</Label>
                <Input name="accessCode" placeholder="Required" type="text" onChange={(e) => this.handleChange(e)}/>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <Button color="success" size="lg" onClick={e => this.validateInstallerAccessCode()}>
          Validate
        </Button>
      </div>
    );
  }

  renderInstallationComplete = () => {
    return (
      <div>
        <h4>{Consts.WEB_APP_RELEASE.FULL_NAME} installation</h4>
        <h5>The {Consts.WEB_APP_RELEASE.FULL_NAME} installation has completed. If you are authorized, you can manage this installation from the <a href="/" style={{ textDecoration: "underline" }}>{Consts.WEB_APP_RELEASE.NAME} dashboard</a>.</h5>
      </div>
    );
  }

  render = () => {
    if (this.state.redirect === "home") {
      return (
        <Redirect to={Consts.USER.HOME} />
      )
    }

    return (
      <React.Fragment>
        <Authentication onAuthenticate={this.onAuthenticated} onNotAuthenticated={this.onNotAuthenticated} />
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogLicenseAgreement}>
            <LicenseAgreement {...this.props} onClose={(agree) => this.closeLicenseAgreement(agree)} />
          </Case>
          <Case value={dialogInstaller}>
            <Installer {...this.props} userDefinedVarsNames={this.state.userDefinedVarsNames} userDefinedVarsValues={this.state.userDefinedVarsValues} onClose={(installed) => this.closeInstaller(installed)} />
          </Case>
        </Switch>
        <div className="content-full-page">
          <div className="install">
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Switch condition={this.state.manageMode}>
                      <Case value={modeAuthenticated}>
                        {this.renderAuthenticated()}
                      </Case>
                      <Case value={modeAuthenticateAccessCode}>
                        {this.renderAuthenticateAccessCode()}
                      </Case>
                      <Case value={modeInstallationComplete}>
                        {this.renderInstallationComplete()}
                      </Case>
                      <Case value={modeError}>
                        <div className="text-center" style={{ marginLeft: "25px", marginRight: "100px", marginTop: "50px", marginBottom: "50px" }}>
                            <h1><span className="text-warning"><i class="far fa-exclamation-triangle"/></span>&nbsp;&nbsp;{this.state.errorMessage}</h1>
                            <h5 className="text-warning">Check the link, and any authentication required. <Link to="#" onClick={(e) => this.setState({ manageMode: this.state.lastManageMode }) }>Try again?</Link></h5>
                          </div>
                        </Case>
                      <Default>
                        <div className="text-center" style={{ marginLeft: "25px", marginRight: "100px", marginTop: "50px", marginBottom: "50px" }}>
                          <h1>Checking installation state...</h1>
                        </div>
                      </Default>
                    </Switch>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Install;