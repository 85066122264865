import React from "react";
import { Button, Row, Col } from "reactstrap";
import { SelectFilter } from "components/common/select-filter.jsx";
import Switch, { Case } from "react-switch-case";
import { encodeRuleArray } from "config/utilities.js";

const moreProcesses = "... more applications";

const ROInstalled = 0;
const RORunningProcesses = 1;

export class EditRuleApplication extends React.Component {
  constructor(props) {
    super(props);

    // resolve process
    let process;
    if (this.props.rule !== undefined) {
      // rule
      if (this.props.processIndex >= 0 && this.props.rule.processes[this.props.processIndex] !== "*") {
        process = this.props.rule.processes[this.props.processIndex];

      } else {
        process = null;
      }
    } else {
      // use process
      if (this.props.process) {
        process = this.props.process;
      } else {
        process = null;
      }
    }

    this.state = {
      process: process,
      modified: false,
      processList: [],
      closed: false,
      error: null,
      confirm: null,
      forceReload: this.props.reloadContext,
      loading: false
    }
    this.getMoreProcesses = false;
  }

  static getDerivedStateFromProps(props, state) {
    // reloadContext don't match, force a reload. this is so we can force a reload from the parent... crappy fix but will do for the minute (also see componentDidMount/Update)
    if (props.reloadContext !== state.reloadContext) {
      state.reloadContext = props.reloadContext;
      state.forceReload = true;
      return state;
    }
    return null;
  }

  componentDidMount() {
    if (this.state.forceReload) {
      this.setState({
        forceReload: false
      }, () => {
        this.reloadProcessList();
      })
    }
  }

  componentDidUpdate() {
    if (this.state.forceReload) {
      this.setState({
        forceReload: false
      }, () => {
        this.reloadProcessList();
      })
    }
  }

  handleChanged = (newValue) => {
    this.setState({
      modified: this.state.process !== newValue,
      process: newValue
    }, () => {
      if (this.props.onChange)
        this.props.onChange(newValue);
    });
  };

  updateRule = (save) => {
    if (save) {
      let rule = this.props.rule;
      let process = this.state.process === "<DELETED>" ? "" : this.state.process;

      // if no process, then remove if multple processes, otherwise set to wildcard
      if (!process) {
        if (rule.processes.length > 1) {
          // remove
          rule.processes.splice(this.props.processIndex, 1);
        } else {
          // wildcard
          process = "*";
          rule.processes[this.props.processIndex] = process;
        }
      } else {
        // new item?
        if (this.props.processIndex === -1) {
          // yes, is there just 1 item?
          if (rule.processes.length === 1) {
            // if existing wildcard, update
            if (rule.processes[0] === "*") {
              rule.process[0] = process;
            } else {
              // add new
              rule.processes.push(process);
            }
          } else {
            // add new
            rule.processes.push(process);
          }
        } else {
          // no
          rule.processes[this.props.processIndex] = process;
        }
      }

      // make sure encoded processestr correct
      rule.processesStr = encodeRuleArray(rule.processes);

      // done
      this.setState({
        rule: rule,
        closed: true
      }, () => {
        this.props.onClose(rule);
      })
    } else {
      this.props.onClose(null);
    }
  }

  getProcesses = () => {
    this.setState({ loading: true });
    this.getMoreProcesses = false;
    this.state.processListContextId ? console.log("Appending processes...") : console.log("Getting processes...")
    window.roFolderWall.GetApplications(this.props.runningProcesses ? RORunningProcesses : ROInstalled, '', false, 1000, this.state.processListContextId, (count, processes, moreItemsAvailable, contextId) => {
      let f = this.state.processList;
      let proc = this.state.process;
      let selected = null;
      let error = null;

      // add default values
      if (f.length === 0) {
        f.push({
          label: "Allow all signed applications",
          value: "<*>",
          image64: null
        });
        f.push({
          label: "Allow any applications",
          value: "*",
          image64: null
        });
        f.push({
          label: "Block all applications",
          value: "-",
          image64: null
        });
        f.push({
          label: "Any application inside the rule folder(s) (excluding child folders)",
          value: "^",
          image64: null
        });
        f.push({
          label: "Any application inside the rule folder(s) or child folder(s)",
          value: "^*",
          image64: null
        });
      }

      // remove more processes
      if (f.length > 0 && f[f.length - 1] === moreProcesses)
        f.splice(-1, 1);

      // add new items (comes back as "proc id,full name,exe name" )
      processes.items.forEach((process) => {
        let o = {
          label: process.name.value,
          value: process.path.value,
          image64: process.icon64.value
        }

        if (this.state.process && this.state.process === o.value) {
          selected = o;
        }

        if (!proc || o.value !== proc.value)
          f.push(o)
      })

      // if more available, add entry
      if (moreItemsAvailable) {
        let o = { label: moreProcesses, value: moreProcesses }
        f.push(o);
      }

      // did we find selected
      if (this.state.process && !selected && this.state.process !== "*" && this.state.process !== "-" && this.state.process !== "<*>") {
        window.roFolderWall.GetFileDetails(this.state.process, (valid, fileDetails) => {
          let o = {
            label: valid ? fileDetails.name.value : this.state.process,
            value: valid ? fileDetails.path.value : this.state.process,
            image64: valid ? fileDetails.icon64.value : ""
          }
          f.unshift(o);
          this.setState({
            defaultValue: f[0],
            processList: f,
            process: proc ? proc : null,
            processListContextId: moreItemsAvailable ? contextId : "",
            error: error,
            loading: false
          }, () => {
            console.log("Processes loaded, added selected");
          })
        }, (error) => {
          console.log(error);
        })
      } else {
        // done...
        this.setState({
          defaultValue: selected,
          processList: f,
          process: proc ? proc : null,
          processListContextId: moreItemsAvailable ? contextId : "",
          error: error,
          loading: false
        }, () => {
            console.log("Processes loaded with selected");
        })
      }
    }, (msg) => {
      console.log("GetProcesses Error!")
    });
  }

  deleteProcess = () => {
    this.setState({
      confirm: "Are you sure you want to remove this application?"
    })
  }

  confirmDeleteProcess = () => {
    this.setState({
      confirm: null,
      process: "<DELETED>",
      modified: true
    }, () => {
      this.updateRule(true);
    })
  }

  cancelDeleteProcess = () => {
    this.setState({
      confirm: null
    })
  }

  reloadProcessList = () => {
    this.setState({
      processList: [],
      processListContextId: null
    }, () => {
      this.getProcesses();
    })
  }

  render = () => {
    if (this.state.closed)  // we were getting a setstate error from parent when it was hidding this... lets do it here just before... issue seems to be resolved
      return null;

    return (
      <div>
        {this.state.error ?
          <h4>
            <span className="text-danger">
              {this.state.error}
            </span>
          </h4>
          : ""}
        {this.state.confirm ?
          <h4>
            <span className="text-danger">{this.state.confirm}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button size="sm" color="info" style={{ height: "30px", lineHeight: "0.6" }} onClick={() => { this.confirmDeleteProcess() }}>Y</Button>
            <Button size="sm" color="info" style={{ height: "30px", lineHeight: "0.6" }} onClick={() => { this.cancelDeleteProcess() }}>N</Button>
          </h4>
          : ""}
        { this.state.process === "<DELETED>" ? <span className="text-danger">{"<DELETED>"}</span> :
          <Row>
            <Col xs={8}>
              <div>
                <SelectFilter readonly={false} options={this.state.processList} defaultValue={this.state.defaultValue} onChange={(newValue) => { this.handleChanged(newValue) }} placeHolder="Select an application" truncateLength={55} />
              </div>
            </Col>
            <Col xs={3}>
              <div className="text-light">
                <Button color="info" style={{ marginTop: "0px" }} onClick={() => { this.reloadProcessList() }} disabled={ this.state.loading }>Reload list</Button>
              </div>
            </Col>
          </Row>
        }
        <Row>
          <Col xs={8}>
            <Switch condition={this.props.rule !== undefined && this.state.process !== null}>
              <Case value={true}>
                <Button color="danger" size="sm" onClick={() => { this.deleteProcess() }} disabled={this.state.loading}>Remove this application</Button>
              </Case>
            </Switch>
          </Col>
          <Switch condition={this.props.rule !== undefined}>
            <Case value={true}>
              <Col xs={4}>
                <div className="text-right">
                  <Switch condition={this.state.modified}>
                    <Case value={true}>
                      <Button color="secondary" size="sm" onClick={() => { this.updateRule(false) }}>Cancel</Button>
                      <Button color="info" size="sm" onClick={() => { this.updateRule(true) }} disabled={this.state.loading}>Update rule</Button>
                    </Case>
                    <Case value={false}>
                      <Button color="secondary" size="sm" onClick={() => { this.updateRule(false) }}>Close</Button>
                    </Case>
                  </Switch>
                </div>
              </Col>
            </Case>
          </Switch>
        </Row>
      </div>
    );
  }
}