import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import Loadable from "react-loading-overlay";
import { Consts } from "config/consts";

export class NewRulesSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      showHomeFolderProtection: this.props.drive.systemDrive
    }
  }

  closeDialog = (complete) => {
    if (!this.props.onClose)
      return;

    this.props.onClose(false);
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal isOpen={true} size="lg" centered={true} backdrop={true}>
          <Loadable active={this.state.loader} spinner text="Working, please wait...">
            <ModalHeader close={closeBtn}>
              <Row>
                <Col xs={12}>
                  <h3 className="text-primary">
                    <i className="far fa-shield-check"></i>&nbsp;Adding protection
                  </h3>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody id="classic-modal-slide-description">
              <Row>
                <Col xs={6}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs={12}>
                          <h5 className="text-success">
                            <i className="far fa-shield" />&nbsp;&nbsp;The Cyber Canary
                          </h5>
                          <h5 className="text-success-2" style={{ height: "65px" }}>
                            The Cyber Canary provides one stop, set and forget ransomware and malware protection.
                          </h5>
                          <small><a href="https://support.division-m.com/hc/en-us/articles/360001690036" style={{ textDecoration: "underline", color: "#00f2c3" }} target="_blank" rel="noopener noreferrer">More details</a></small>
                          <footer className="card-footer">
                            <div className="text-center">
                              <Button color="default" onClick={(e) => this.props.onNewTheCyberCanary()}>
                                Add
                              </Button>
                            </div>
                          </footer>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                {Consts.SYSTEM.PRODUCT_ID === "anvil" ?
                  <Col xs={6}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xs={12}>
                            <h5 className="text-success">
                              <i className="far fa-folder-plus" />&nbsp;&nbsp;Protect a folder
                            </h5>
                            <h5 className="text-success-2" style={{ height: "65px" }}>
                              Add file protection to a folder, with optional intrusion detection.
                            </h5>
                            <small><a href="https://support.division-m.com/hc/en-us/articles/360001134476" style={{ textDecoration: "underline", color: "#00f2c3" }} target="_blank" rel="noopener noreferrer">More details</a></small>
                            <footer className="card-footer">
                              <div className="text-center">
                                <Button color="default" onClick={(e) => this.props.onNewFolderApp()}>
                                  Add
                                </Button>
                              </div>
                            </footer>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col> : null}
                {Consts.SYSTEM.PRODUCT_ID === "anvil" && this.state.showHomeFolderProtection ?
                  <Col xs={6}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xs={12}>
                            <h5 className="text-success">
                              <i className="far fa-user-shield" />&nbsp;&nbsp;Protect a home folder
                            </h5>
                            <h5 className="text-success-2" style={{ height: "65px" }}>
                              Add file protection to a users home folder, with optional intrusion detection. The folders are dynamically assigned based on the version of Windows.
                            </h5>
                            <small><a href="https://support.division-m.com/hc/en-us/articles/360001134476" style={{ textDecoration: "underline", color: "#00f2c3" }} target="_blank" rel="noopener noreferrer">More details</a></small>
                            <footer className="card-footer">
                              <div className="text-center">
                                <Button color="default" onClick={(e) => this.props.onNewHomeFolder()}>
                                  Add
                                </Button>
                              </div>
                            </footer>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col> : null }
                {1 === 2 ?
                  <Col xs={6}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xs={12}>
                            <h5 className="text-success">
                              <i className="far fa-user-secret" />&nbsp;&nbsp;Add intrusion detection to a folder
                            </h5>
                            <h5 className="text-success-2" style={{ height: "65px" }}>
                              Add stand alone intrusion detection (i.e. no file protection) to a folder.
                            </h5>
                            <small><a href="https://support.division-m.com/hc/en-us/articles/360001130776" style={{ textDecoration: "underline", color: "#00f2c3" }} target="_blank" rel="noopener noreferrer">More details</a></small>
                            <footer className="card-footer">
                              <div className="text-center">
                                <Button color="default" onClick={(e) => this.props.onNewImmutableFolder()}>
                                  Add
                                </Button>
                              </div>
                            </footer>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col> : null }
                {Consts.SYSTEM.PRODUCT_ID === "anvil" ?
                  <Col xs={6}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xs={12}>
                            <h5 className="text-success">
                              <i className="far fa-flag-checkered" />&nbsp;&nbsp;Create an immutable folder
                            </h5>
                            <h5 className="text-success-2" style={{ height: "65px" }}>
                              Create an immutable folder that only allows files when they are first written.
                            </h5>
                            <small><a href="https://support.division-m.com/hc/en-us/articles/360001001276" style={{ textDecoration: "underline", color: "#00f2c3" }} target="_blank" rel="noopener noreferrer">More details</a></small>
                            <footer className="card-footer">
                              <div className="text-center">
                                <Button color="default" onClick={(e) => this.props.onNewImmutableFolder()}>
                                  Add
                                </Button>
                              </div>
                            </footer>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col> : null }
                <Col xs={6}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs={12}>
                          <h5 className="text-success">
                            <i className="far fa-crow" />&nbsp;&nbsp;Canary file
                          </h5>
                          <h5 className="text-success-2" style={{ height: "65px" }}>
                            Select a "canary" file, that, when accessed, will send an email notification.
                          </h5>
                          <small><a href="https://support.division-m.com/hc/en-us/articles/360001002816" style={{ textDecoration: "underline", color: "#00f2c3" }} target="_blank" rel="noopener noreferrer">More details</a></small>
                          <footer className="card-footer">
                            <div className="text-center">
                              <Button color="default" onClick={(e) => this.props.onNewCanaryFile()}>
                                Add
                              </Button>
                            </div>
                          </footer>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                {Consts.SYSTEM.PRODUCT_ID === "anvil" ?
                  <Col xs={6}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xs={12}>
                            <h5 className="text-success">
                              <i className="far fa-key" />&nbsp;&nbsp;Encrypted folder
                            </h5>
                            <h5 className="text-success-2" style={{ height: "65px" }}>
                              Create a folder that encrypts all files placed within it.
                            </h5>
                            <small><a href="https://support.division-m.com/hc/en-us/articles/360001415576" style={{ textDecoration: "underline", color: "#00f2c3" }} target="_blank" rel="noopener noreferrer">More details</a></small>
                            <footer className="card-footer">
                              <div className="text-center">
                                <Button color="default" onClick={(e) => this.props.onNewEncryptedFolder()}>
                                  Add
                                </Button>
                              </div>
                            </footer>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col> : null }
              </Row>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Loadable>
        </Modal>
      </React.Fragment>
    );
  }
}