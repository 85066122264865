import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Form, FormGroup, Label } from "reactstrap";
import { Link } from "react-router-dom";
import Switch, { Case } from "react-switch-case";
import Loadable from "react-loading-overlay";
import { Consts } from "config/consts";
import { arrayToFormattedStr, encodeRuleArray } from "config/utilities";
import { EditName } from "components/dialogs/edit-name";
import { EditFolders } from "components/dialogs/edit-folders";

const ruleSetTypeId = "immutable_folder";
const dialogsHidden = 0;
const dialogEditName = 1;
const dialogEditFolder = 2;

export class NewImmutableFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      ruleSet: null,
      params: {
        TITLE: "Immutable folder protection",
        FOLDER: [],
      },
      displayDialog: dialogsHidden
    }
  }

  componentDidMount() {
    window.roFolderWall.GetAllRuleSets(this.props.drive.id, (rs) => {
      try {
        let ruleSets = JSON.parse(rs);
        let rso = ruleSets["RULE_SETS"];
        for (let key in rso) {
          if (rso.hasOwnProperty(key)) {
            if (rso[key].TYPE_ID === ruleSetTypeId) {
              this.setState({
                loader: false,
                params: { ...this.state.params, TITLE: rso[key].DETAILS.NAME }
              });
              break;
            }
          }
        }
      } catch (err) {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "There was an error loading the required content! (error code 104)");
        console.log(err)
        console.log(rs);
      }
    }, (error) => {
      this.setState({ loader: false });
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
    })
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ params: { ...this.state.params, [e.target.name]: e.target.checked } });
    } else {
      this.setState({ params: { ...this.state.params, [e.target.name]: e.target.value } });
    }
  }

  editName = () => {
    this.setState({
      displayDialog: dialogEditName
    })
  }

  editFolder = () => {
    this.setState({
      displayDialog: dialogEditFolder
    })
  }

  updateName = (newName) => {
    this.setState({
      params: newName ? { ...this.state.params, TITLE: newName } : this.state.params,
      displayDialog: dialogsHidden,
      nameModified: true
    })
  }

  updateFolders = (newFolder) => {
    let p = this.state.params;
    p.FOLDER = newFolder ? newFolder : p.FOLDER;

    // update name??
    if (newFolder && !this.state.nameModified && newFolder.length === 1)
      p.TITLE = this.state.params.TITLE + newFolder[0];

    this.setState({
      params: p,
      nameModified: true,
      displayDialog: dialogsHidden
    })
  }


  closeDialog = (complete) => {
    if (!this.props.onClose)
      return;

    if (complete) {
      this.setState({ loader: true });

      // folders and apps must be sent as string not array
      let p = {...this.state.params};
      p.FOLDER = encodeRuleArray(p.FOLDER).replace("[", "").replace("]", "");

      window.roFolderWall.AddUpdateRuleSet(this.props.drive.id, ruleSetTypeId, JSON.stringify(p), true, (updatedRules, stateValue) => {
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Immutable folder protection applied!");
        this.props.onClose(true);
      }, (error) => {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
      })
    } else {
      this.props.onClose(false);
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        {this.state.displayDialog === dialogsHidden ?
          <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
          : null
        }
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogEditName}>
            <EditName {...this.props} name={this.state.params.TITLE} drive={this.props.drive} onUpdateName={this.updateName} />
          </Case>
          <Case value={dialogEditFolder}>
            <EditFolders {...this.props} folders={this.state.params.FOLDER} drive={this.props.drive} onUpdateFolders={this.updateFolders} singleFolder={true} />
          </Case>
        </Switch>
        <Modal isOpen={true} size="lg" centered={true} backdrop={true}>
        <Loadable active={this.state.loader || this.state.displayDialog !== dialogsHidden} spinner text="Working, please wait...">
            <ModalHeader close={closeBtn}>
              <Row>
                <Col xs={12}>
                  <h3 className="text-primary">
                    <i className="far fa-flag-checkered"></i>&nbsp;New immutable folder protection
                  </h3>
                  <h5 className="text-primary-2">
                    The immutable folder protection wizard will create a rule that will prevent any file modification or deletion.
                  </h5>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody id="classic-modal-slide-description">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12}>
                      <h5 className="primary">
                        This rule covers any application on your system.
                        However, you can add an additional rule to explicitly allow one or more applications to modify files, <a href="https://support.division-m.com/hc/en-us/articles/360001001276" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">click here</a> for more details.
                      </h5>
                    </Col>
                  </Row>
                  <Form>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            Rule name
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editName()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.params.TITLE}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            Immutable folder
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editFolder()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.params.FOLDER.length !== 0 ? arrayToFormattedStr(this.state.params.FOLDER) : <span className="h6 text-danger">No folder selected</span>}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <div className="text-right">
                <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
                <Button color="default" onClick={(e) => this.closeDialog(true)}>Apply</Button>
              </div>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Loadable>
        </Modal>
      </React.Fragment>
    );
  }
}