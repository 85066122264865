import React from "react";
import { Button, Input } from "reactstrap";
import Switch, { Case } from "react-switch-case";

export class EditRuleName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.rule.name,
      modified: false
    }
  }

  handleChange = (e) => {
    this.setState({
      name: e.target.value,
      modified: true,
      closed: false
    }, () => {
      if (this.props.onChange)
        this.props.onChange(this.state.name);
    });
  };

  updateRule = (save) => {
    if (save) {
      let rule = this.props.rule;
      rule.name = this.state.name;
      this.setState({
        rule: rule,
        closed: true
      }, () =>{
        this.props.onClose(rule);
      })
    } else {
      this.props.onClose(null);
    }
  }

  render = () => {
    if (this.state.closed)  // we were getting a setstate error from parent when it was hidding this... lets do it here just before... issue seems to be resolved
      return null;

    return (
      <React.Fragment>
        <div>
          <Input name="email" placeholder="Rule name" type="email" defaultValue={this.state.name} onChange={(e) => this.handleChange(e)} />
        </div>
        <div>
          <Switch condition={!this.props.onChange}>
            <Case value={true}>
              <Switch condition={this.state.modified}>
                <Case value={true}>
                  <Button color="secondary" onClick={() => { this.updateRule(false) }}>Cancel</Button>
                  <Button color="info" onClick={() => { this.updateRule(true) }}>
                    Update rule
                  </Button>
                </Case>
                <Case value={false}>
                  <Button color="secondary" onClick={() => { this.updateRule(false) }}>Close</Button>
                </Case>
              </Switch>
            </Case>
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}