/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import { Container, Row, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container fluid>
          <Nav>
            <NavItem>
              <NavLink href="https://support.division-m.com" target="_blank">Support</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://community.division-m.com" target="_blank">Community</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://blog.division-m.com" target="_blank">Blog</NavLink>
            </NavItem>
          </Nav>
          <div className="copyright">
            © {new Date().getFullYear()} made with{" "}
            <i className="tim-icons icon-heart-2" /> by{" "}
            <a href="https://www.division-m.com" rel="noopener noreferrer" target="_blank">
              Division-M, Sydney, Australia
            </a>{" "}
            for a more secure{" "}
            <i className="tim-icons icon-laptop" />
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;