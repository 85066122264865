import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardBody, Form, FormGroup, Input, Badge, Label } from "reactstrap";
import { CardElement } from "@stripe/react-stripe-js";
import { Authentication } from "context/authentication";
import { Consts } from "config/consts";
import { FirebaseFuncts } from "config/firebase";
import Loadable from "react-loading-overlay";
import { getWebAppValue } from "config/utilities";

import anvilBox from "assets/img/anvil_box1_800x800.png";
import tccBox from "assets/img/canary_box1_800x800.png";

const stripeOptions = {
  style: {
    base: {
      fontSize: "18px",
      color: "#eeeeee",
      letterSpacing: "0.025em",
      fontFamily: "Source Code Pro, monospace",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#9e2146"
    }
  }
};

export class StripePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      plan: Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_PREMIUM_1 : Consts.STRIPE.DEV.PLAN_PREMIUM_1,
      agree: false      
    }
  }  

  handleChange = (e) => {
    // update state
    this.setState({
      [e.target.name]: e.target.value }, () => {
    });
  }

  handleSubmitStripe = async (e) => {
    e.preventDefault();
    this.setState({ loader: true });

    const {stripe, elements} = this.props;
    const cardElement = elements.getElement('card');
    const {error, token} = await stripe.createToken(cardElement, {
      email: this.props.authContext.state.email
    });
    
    // error?
    if (error) {
      this.setState({ loader: false });
      this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "Payment error", error.message);
      console.log(error.message);
      return;
    }

    // all good, set up license
    if (token) {
      let subscription = FirebaseFuncts.httpsCallable("createStripeSubscription");
      subscription({
        dev: !Consts.SYSTEM.RELEASE,
        apiKey: Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.API_KEY : Consts.STRIPE.DEV.API_KEY,
        tokenId: token.id,
        planId: this.state.plan[Consts.STRIPE.FIELD.PLAN_ID],
        email: this.props.authContext.state.email,
        licenseType: this.state.plan[Consts.STRIPE.FIELD.PLAN_TYPE],
        machineKey: this.props.machineKey,
        machineName: this.props.machineName,
        licenseKey: "",
        licenseDays: 365,
        licenseDevices: this.state.plan[Consts.STRIPE.FIELD.PLAN_DEVICES],
        value: this.state.plan[Consts.STRIPE.FIELD.PLAN_PRICE_YEAR]
      }).then((result) => {
        this.setState({ loader: false });
        console.log(result.data.text + " License key: " + result.data.licenseKey);
        this.props.displayDialog(Consts.MESSAGE_TYPE.INFO, "Payment complete", result.data.text + " License key: " + result.data.licenseKey, () => {
          // done!
          this.props.onClose({
            planId: result.data.planId,
            licenseKey: result.data.licenseKey,
            email: result.data.email
          });
        });        
      }).catch((error) => {
        this.setState({ loader: false });
        let err = error.message.indexOf("Cannot read property 'id' of undefined") !== -1 ? "There was an error processing the payment, please check the fields!" : error.message;
        this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "Payment error", err);
        console.log(error.message);
      });        
    } else {
      this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "Payment error", "There is a server issue!");
      console.log("There is a server issue!");
    }
  };  

  receiveToken = (token, addresses) => {
    console.log(token);
  }

  closeDialog = (e, paymentMade) => {
    if (!this.props.onClose)
      return;
    this.props.onClose(paymentMade);
  }

  changePlan = (e) => {
    let np = null;
    if (Consts.SYSTEM.RELEASE) {
      if (e.target.name === "1devices")
        np = Consts.STRIPE.REL.PLAN_PREMIUM_1;
      if (e.target.name === "2devices")
        np = Consts.STRIPE.REL.PLAN_PREMIUM_2;
      if (e.target.name === "3devices")
        np = Consts.STRIPE.REL.PLAN_PREMIUM_3;
    } else {
      if (e.target.name === "1devices")
        np = Consts.STRIPE.DEV.PLAN_PREMIUM_1;
      if (e.target.name === "2devices")
        np = Consts.STRIPE.DEV.PLAN_PREMIUM_2;
      if (e.target.name === "3devices")
        np = Consts.STRIPE.DEV.PLAN_PREMIUM_3;
    }

    if (np)
      this.setState({ plan: np })
  }

  resolveDevices = (plan) => {
    let d = "1";
    if (Consts.SYSTEM.RELEASE) {
      if (plan === "1")
        d = Consts.STRIPE.REL.PLAN_PREMIUM_1[Consts.STRIPE.FIELD.PLAN_DEVICES];
      if (plan === "2")
        d = Consts.STRIPE.REL.PLAN_PREMIUM_2[Consts.STRIPE.FIELD.PLAN_DEVICES];
      if (plan === "3")
        d = Consts.STRIPE.REL.PLAN_PREMIUM_3[Consts.STRIPE.FIELD.PLAN_DEVICES];
    } else {
      if (plan === "1")
        d = Consts.STRIPE.DEV.PLAN_PREMIUM_1[Consts.STRIPE.FIELD.PLAN_DEVICES];
      if (plan === "2")
        d = Consts.STRIPE.DEV.PLAN_PREMIUM_2[Consts.STRIPE.FIELD.PLAN_DEVICES];
      if (plan === "3")
        d = Consts.STRIPE.DEV.PLAN_PREMIUM_3[Consts.STRIPE.FIELD.PLAN_DEVICES];    
    }
  
    return d;
  }

  renderLicenseTypes = () => {
    return (
      <React.Fragment>
        <Row>
          <Col xs={6}>
            <img src={ Consts.SYSTEM.PRODUCT_ID === "tcc" ? tccBox : anvilBox } alt={getWebAppValue(Consts.WEB_APP_VALUE.NAME)} />
          </Col>
          <Col xs={6}>
            <Card>
              <CardHeader style={{ backgroundColor: "#1d8cf8" }}>
                <div className="text-center">
                  <Badge color="default">
                    Premium license
                  </Badge>
                  <h2>${ this.state.plan[Consts.STRIPE.FIELD.PLAN_PRICE_MONTH] }/Month<br /><span><h5>${ this.state.plan[Consts.STRIPE.FIELD.PLAN_PRICE_YEAR] } paid annually</h5></span></h2>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12}>                    
                    <hr style={{ borderColor: "#444444" }} />
                    <p className="text-muted"><i className="fas fa-desktop" />&nbsp;{this.state.plan[Consts.STRIPE.FIELD.PLAN_DEVICES]} Devices</p>
                    <hr style={{ borderColor: "#444444" }} />
                    <p className="text-muted"><i className="fas fa-edit" />&nbsp;12 months subscription</p>                    
                    <hr style={{ borderColor: "#444444" }} />
                    <p className="text-muted"><i className="fas fa-money-check-alt" />&nbsp;30 day money back guarantee</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="login">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h5 className="title">Purchase (powered by <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe, Inc</a>)</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md={12}>
                        <FormGroup check inline>
                          <Label>
                            Devices
                          </Label>
                        </FormGroup>
                          <FormGroup check inline>                          
                            <Label check>
                              <Input type="checkbox" name="1devices" checked={ this.state.plan[Consts.STRIPE.FIELD.PLAN_DEVICES] === this.resolveDevices("1") } onChange={(e) => this.changePlan(e) } /> { this.resolveDevices("1") }
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                            <Input type="checkbox" name="2devices" checked={ this.state.plan[Consts.STRIPE.FIELD.PLAN_DEVICES] === this.resolveDevices("2") } onChange={(e) => this.changePlan(e) } /> { this.resolveDevices("2") }
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                            <Input type="checkbox" name="3devices" checked={ this.state.plan[Consts.STRIPE.FIELD.PLAN_DEVICES] === this.resolveDevices("3") } onChange={(e) => this.changePlan(e) } /> { this.resolveDevices("3") }
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                      </Col>
                    </Row>                   
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>Card</Label>
                          <CardElement
                            options={ stripeOptions }
                            onReady={() => {
                              // console.log("CardElement [ready]");
                            }}
                            onChange={event => {
                              // console.log("CardElement [change]", event);
                            }}
                            onBlur={() => {
                              // console.log("CardElement [blur]");
                            }}
                            onFocus={() => {
                              // console.log("CardElement [focus]");
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <div className="text-right">
                          <Button color="info" onClick={(e) => this.handleSubmitStripe(e)}>
                            Purchase
                          </Button>&nbsp;&nbsp;
                          <Button color="danger" onClick={(e) => this.closeDialog(e, null) }>
                            Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(e)}>&times;</button>;
    return (
      <React.Fragment>
        <Authentication onAuthenticate={this.onAuthenticated} allowRedirect={true} />
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="payment-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <Loadable active={this.state.loader} spinner text="Processing payment, please wait...">
            <ModalHeader close={closeBtn}>
              <Row>                
                <Col xs={12}>
                  <h3 className="text-info" style={{ marginTop: "15px" }}>{getWebAppValue(Consts.WEB_APP_VALUE.NAME)} payment</h3>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody id="classic-modal-slide-description">
              {this.renderLicenseTypes()}
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Loadable>
        </Modal>
      </React.Fragment>
    );
  }
}