import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, FormGroup, Input } from "reactstrap";
import { decodeRule } from "config/utilities";

export class EditName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.rule ? decodeRule(this.props.rule).name : this.props.name,
      modified: false,
      fixedName: this.props.rule ? decodeRule(this.props.rule).name : this.props.name
    }
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
        modified: true
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        modified: true
      });
    }
  }

  closeDialog = (save) => {
    if (!save) {
      if (this.props.rule) {
        this.props.onUpdateRule(null);
      } else {
        this.props.onUpdateName(null);
      }
    } else {
      if (this.props.rule) {
        // output rule
        let ro = decodeRule(this.props.rule);
        ro.name = this.state.name;
        this.props.onUpdateRule(this.state.modified ? ro : null);
      } else {
        // output name
        this.props.onUpdateName(this.state.modified ? this.state.name : null);
      }
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="edit-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <ModalHeader close={closeBtn}>
            <Row>
              <Col xs={12}>
                <h3 className="text-primary">
                  <i className="far fa-edit" />&nbsp;Edit name
                </h3>
                <h5 className="text-primary-2">
                  Edit name for <span className="text-primary">{this.state.fixedName}</span>
                </h5>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody id="classic-modal-slide-description">
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <h5 className="text-success"><i className="fal fa-pen-square" />&nbsp;&nbsp;Edit name<span className="text-success-2"></span></h5>
                    <FormGroup>
                      <Input name="name" type="text" value={this.state.name} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right">
              <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
              <Button color="default" onClick={(e) => this.closeDialog(true)}>Apply</Button>
            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}