import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Form, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import Loadable from "react-loading-overlay";
import { Consts } from "config/consts";

const ruleSetTypeId = "home_folder_apps";

export class NewHomeFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      ruleSet: null,
      params: {
        TITLE: "Home folder protection",
        APPS: "<*>",
        LOCK_STARTUP_FOLDER: true,
        OPTIONS: "none",
        DISPLAY_ALERT: true,
        KILL_PROCESS: true,
        EMAIL_ADDRESSES: this.props.getAuthContext().state.email
      }
    }
  }

  componentDidMount() {
    window.roFolderWall.GetAllRuleSets(this.props.drive.id, (rs) => {
      try {
        let ruleSets = JSON.parse(rs);
        let rso = ruleSets["RULE_SETS"];
        for (let key in rso) {
          if (rso.hasOwnProperty(key)) {
            if (rso[key].TYPE_ID === ruleSetTypeId) {
              this.setState({
                loader: false,
                params: { ...this.state.params, TITLE: rso[key].DETAILS.NAME }
              });
              break;
            }
          }
        }
      } catch (err) {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "There was an error loading the required content!  (error code 103)");
        console.log(err)
        console.log(rs);
      }
    }, (error) => {
      this.setState({ loader: false });
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
    })
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      if (e.target.name.indexOf("OPTIONS|") === 0) {
        if (e.target.checked) {
          this.setState({ params: { ...this.state.params, "OPTIONS": e.target.name.toLowerCase().split("|")[1] }});
        } else {
          this.setState({ params: { ...this.state.params, "OPTIONS": "none" }});
        }
      } else {
        this.setState({ params: { ...this.state.params, [e.target.name]: e.target.checked }});
      }
    } else {
      this.setState({ params: { ...this.state.params, [e.target.name]: e.target.value }});
    }
  }

  closeDialog = (complete) => {
    if (!this.props.onClose)
      return;

    if (complete) {

      // validate
      if (this.state.params.OPTIONS === "it_detect") {
        // check for email address
        if (!this.state.params.EMAIL_ADDRESSES || this.state.params.EMAIL_ADDRESSES.indexOf("@") === -1) {
          this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You need to supply one or more email addresses");
          return;
        }
      }

      this.setState({ loader: true });
      window.roFolderWall.AddUpdateRuleSet(this.props.drive.id, ruleSetTypeId, JSON.stringify(this.state.params), true, (updatedRules, stateValue) => {
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Home folder protection applied!");
        this.props.onClose(true);
      }, (error) => {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
      })
    } else {
      this.props.onClose(false);
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <Modal isOpen={true} size="lg" centered={true} backdrop={true}>
        <Loadable active={this.state.loader} spinner text="Working, please wait...">
          <ModalHeader close={closeBtn}>
            <Row>
              <Col xs={12}>
                <h3 className="text-primary">
                  <i className="far fa-user-shield"></i>&nbsp;Home folder protection
                </h3>
                <h5 className="text-primary-2">
                  The add home folder protection wizard will create rules that will protect your personal files from being modified by unauthorized applications. The home folders protected are dynamically set based on the Windows version and applications installed.&nbsp;
                  <a href="https://support.division-m.com/hc/en-us/articles/360001134476" style={{ textDecoration: "underline", color: "#8a337b" }} target="_blank" rel="noopener noreferrer">More details</a>
                </h5>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody id="classic-modal-slide-description">
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <h5 className="primary">
                      All certified (i.e. signed) applications and home folders are pre-set, this level of protection is high, while remaining user friendly.
                      You can modify the whitelisted applications to allow tighter (or looser) control, at a later date if needed. If you wish to target specific folders, <Link to="#" style={{ textDecoration: "underline" }} onClick={(e) => this.props.onNewFolderApp()}>click here.</Link>
                    </h5>
                  </Col>
                </Row>
                <Form>
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Label>Rule name</Label>
                        <Input name="TITLE" type="text" value={this.state.params.TITLE} onChange={(e) => this.handleChange(e)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup check>
                        <Label check>
                          <Input name="LOCK_STARTUP_FOLDER" type="checkbox" checked={this.state.params.LOCK_STARTUP_FOLDER} onChange={(e) => this.handleChange(e)} />{' '}Protect the Windows startup folder?&nbsp;
                            <span className="form-check-sign">
                            <span className="check"></span>
                          </span>
                          <div className="text-muted">This is often an entry point for malware, and there are very few "friendly" applications that write to this folder (recommended).</div>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                      <Col xs={12}>
                        <FormGroup check>
                          <Label check>
                            <Input name="OPTIONS|PROMPT" type="checkbox" checked={this.state.params.OPTIONS === "prompt"} onChange={(e) => this.handleChange(e)} />{' '}Enable request mode?&nbsp;
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                            <div className="text-muted">If an unauthorized application attempts to write to any protected folder, a full screen confirmation will be displayed to allow or deny access.&nbsp;
                            <a href="https://support.division-m.com/hc/en-us/articles/360001126955" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">More details</a></div>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        &nbsp;&nbsp;<span className="text-primary">-OR-</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup check>
                          <Label check>
                            <Input name="OPTIONS|IT_DETECT" type="checkbox" checked={this.state.params.OPTIONS === "it_detect"} onChange={(e) => this.handleChange(e)} />{' '}Enable intrusion detection?
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                            <div className="text-muted">If an unauthorized application attempts to write to any protected folder, intrusion detection will trigger.&nbsp;
                            <a href="https://support.division-m.com/hc/en-us/articles/360001130776" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">More details</a></div>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {this.state.params.OPTIONS === "it_detect" ?
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Label>Notification email address(es) (multiple addresses can be separated with a ",")</Label>
                                <Input name="EMAIL_ADDRESSES" type="text" value={this.state.params.EMAIL_ADDRESSES} onChange={(e) => this.handleChange(e)} />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup check>
                                <Label check>
                                  <Input name="DISPLAY_ALERT" type="checkbox" checked={this.state.params.DISPLAY_ALERT} onChange={(e) => this.handleChange(e)} />{' '}Display full screen alert (recommended)?
                                  <span className="form-check-sign">
                                    <span className="check"></span>
                                  </span>
                                  <div className="text-muted">If intrusion detection is triggered, this will display a full screen alert.</div>
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup check>
                                <Label check>
                                  <Input name="KILL_PROCESS" type="checkbox" checked={this.state.params.KILL_PROCESS} onChange={(e) => this.handleChange(e)} />{' '}Terminate the unauthorized process (recommended)?
                                  <span className="form-check-sign">
                                    <span className="check"></span>
                                  </span>
                                  <div className="text-muted">If intrusion detection is triggered, the process that caused the trigger will be terminated.</div>
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      : null
                    }
                </Form>
              </CardBody>
            </Card>
            <div className="text-right">
              <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
              <Button color="default" onClick={(e) => this.closeDialog(true)}>Apply</Button>
            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Loadable>
      </Modal>
    );
  }
}