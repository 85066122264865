import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Form, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import Switch, { Case } from "react-switch-case";
import { arrayToFormattedStr, encodeRuleArray } from "config/utilities";
import Loadable from "react-loading-overlay";
import { Consts } from "config/consts";
import { EditName } from "components/dialogs/edit-name";
import { EditFolders } from "components/dialogs/edit-folders";
import { EditFileName } from "components/dialogs/edit-filename";

const ruleSetTypeId = "canary_file";
const dialogsHidden = 0;
const dialogEditName = 1;
const dialogEditFolder = 2;
const dialogEditFileName = 3;

export class NewCanaryFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      ruleSet: null,
      params: {
        TITLE: "",
        FOLDER: [],
        TARGET_FILE: "",
        WRITE_PROTECT: Boolean,
        EMAIL_ADDRESSES: this.props.getAuthContext().state.email,
        PERCENT_READ: 50,
        MESSAGE: ""
      },
            nameModified: false,
      maxProcessesToShow: 4,
      displayDialog: dialogsHidden
    }
  }

  componentDidMount() {
    window.roFolderWall.GetAllRuleSets(this.props.drive.id, (rs) => {
      try {
        let ruleSets = JSON.parse(rs);
        let rso = ruleSets["RULE_SETS"];
        for (let key in rso) {
          if (rso.hasOwnProperty(key)) {
            if (rso[key].TYPE_ID === ruleSetTypeId) {
              this.setState({
                loader: false,
                params: { ...this.state.params, TITLE: rso[key].DETAILS.NAME }
              });
              break;
            }
          }
        }
      } catch (err) {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "There was an error loading the required content! (error code 101)");
        console.log(err)
        console.log(rs);
      }
    }, (error) => {
      this.setState({ loader: false });
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
    })
  }

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ params: { ...this.state.params, [e.target.name]: e.target.checked }});
    } else {
      this.setState({ params: { ...this.state.params, [e.target.name]: e.target.value }});
    }
  }

  editName = () => {
    this.setState({
      displayDialog: dialogEditName
    })
  }

  editFolder = () => {
    this.setState({
      displayDialog: dialogEditFolder
    })
  }

  editFileName = () => {
    // we need a base folder
    if (this.state.params.FOLDER.length === 0){
      this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "Before selecting a target file, select the base folder!");
      return;
    }

    this.setState({
      displayDialog: dialogEditFileName
    })
  }

  updateName = (newName) => {
    this.setState({
      params: newName ? { ...this.state.params, TITLE: newName } : this.state.params,
      displayDialog: dialogsHidden,
      nameModified: true
    })
  }

  updateFolders = (newFolder) => {
    this.setState({
      params: newFolder ? { ...this.state.params, FOLDER: newFolder } : this.state.params,
      displayDialog: dialogsHidden
    })
  }

  updateFileName = (newFileName) => {
    let p = this.state.params;
    p.TARGET_FILE = newFileName ? newFileName : p.TARGET_FILE;

    // update name??
    if (newFileName && !this.state.nameModified)
      p.TITLE = this.state.params.TITLE + " for - " + newFileName;

    this.setState({
      params: p,
      nameModified: true,
      displayDialog: dialogsHidden
    })
  }

  closeDialog = (complete) => {
    if (!this.props.onClose)
      return;

    if (complete) {
      // do any validation
      if (!this.state.params.EMAIL_ADDRESSES || this.state.params.EMAIL_ADDRESSES.indexOf("@") === -1) {
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You need to supply one or more email addresses");
        return;
      }
      if (!this.state.params.MESSAGE) {
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, "You need to have a valid message to be sent");
        return;
      }

      // all good
      this.setState({ loader: true });

      // folders and apps must be sent as string not array
      let p = {...this.state.params};
      p.FOLDER = encodeRuleArray(p.FOLDER).replace("[", "").replace("]", "");

      // do it
      window.roFolderWall.AddUpdateRuleSet(this.props.drive.id, ruleSetTypeId, JSON.stringify(p), true, (updatedRules, stateValue) => {
        this.props.displayNotification(Consts.MESSAGE_TYPE.INFO, "Folder protection applied!");
        this.props.onClose(true);
      }, (error) => {
        this.setState({ loader: false });
        this.props.displayNotification(Consts.MESSAGE_TYPE.ERROR, error.message);
      })
    } else {
      this.props.onClose(false);
    }
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
        <React.Fragment>
        {this.state.displayDialog === dialogsHidden ?
          <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
          : null
        }
        <Switch condition={this.state.displayDialog}>
          <Case value={dialogEditName}>
            <EditName {...this.props} name={this.state.params.TITLE} drive={this.props.drive} onUpdateName={this.updateName} />
          </Case>
          <Case value={dialogEditFolder}>
            <EditFolders {...this.props} folders={this.state.params.FOLDER} drive={this.props.drive} onUpdateFolders={this.updateFolders} singleFolder={true} />
          </Case>
          <Case value={dialogEditFileName}>
            <EditFileName {...this.props} baseFolder={this.state.params.FOLDER} fileName={this.state.params.TARGET_FILE} drive={this.props.drive} onUpdateFileName={this.updateFileName} />
          </Case>
        </Switch>
        <Modal isOpen={true} size="lg" centered={true} backdrop={true}>
          <Loadable active={this.state.loader || this.state.displayDialog !== dialogsHidden} spinner text="Working, please wait...">
            <ModalHeader close={closeBtn}>
              <Row>
                <Col xs={12}>
                  <h3 className="text-primary">
                    <i className="far fa-crow"></i>&nbsp;Adding a canary file to your file system
                  </h3>
                  <h5 className="text-primary-2">
                    The canary file wizard will create a new rule that will monitor read access on a targeted "canary" file. If read access is detected, an email is sent reporting the access.
                  </h5>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody id="classic-modal-slide-description">
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            Rule name
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editName()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.params.TITLE}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            Target file folder (the folder location of the target file)
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editFolder()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.params.FOLDER.length !== 0 ? arrayToFormattedStr(this.state.params.FOLDER) : <span className="h6 text-danger">No folder selected</span>}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                      <FormGroup>
                          <Label>
                            Target file name
                            &nbsp;&nbsp;
                            <Link to="#" onClick={(e) => this.editFileName()}>
                              <i className="far fa-edit" />
                            </Link>
                          </Label>
                          <h5>
                            &nbsp;&nbsp;
                            <span className="text-primary">{this.state.params.TARGET_FILE.length !== 0 ? this.state.params.TARGET_FILE : <span className="h6 text-danger">No file name selected</span>}</span>
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup check>
                          <Label check>
                            <Input name="WRITE_PROTECT" type="checkbox" checked={this.state.params.WRITE_PROTECT} onChange={(e) => this.handleChange(e)} />{' '}Write protect this file?
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                            <div className="text-muted">Any changes made to this file will be silently blocked.</div>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>Notification email address (multiple addresses can be separated with a ",")</Label>
                          <Input name="EMAIL_ADDRESSES" type="text" value={this.state.params.EMAIL_ADDRESSES} onChange={(e) => this.handleChange(e)} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>Message to include in notification</Label>
                          <Input name="MESSAGE" type="text" value={this.state.params.MESSAGE} onChange={(e) => this.handleChange(e)} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <div className="text-right">
                <Button color="default" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
                <Button color="default" onClick={(e) => this.closeDialog(true)}>Apply</Button>
              </div>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Loadable>
        </Modal>
      </React.Fragment>
    );
  }
}