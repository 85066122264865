import React from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import Loadable from "react-loading-overlay";
import Switch, { Case } from "react-switch-case";
import { Consts } from "config/consts";
import axios from "axios";
import { getWebAppValue } from "config/utilities";
import { Link } from 'react-router-dom'

const installerConnectorCheck = 1;
const installerDownloadConnector = 2;
const installerWaitingInstalledConnector = 3;
const installerWaitingConnector = 4;
const installerRunning = 5;
const installerComplete = 6;
const installerCompleteError = 7;

const launchInstaller = 0;

export class Installer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      installerMode: installerConnectorCheck,
      installerId: null,
      progress: "Waiting...",
      status: "",
      error: false,
      logTrace: []
    }
    this.visible = false;
  }

  componentDidMount() {
    console.log('Mount installer components')
    this.visible = true;
    this.checkForExistingConnector();
  }

  componentWillUnmount() {
    console.log('Unmount installer components')
    this.visible = false;
  }

  waitOnEvent = (method, timeout) => {
    // make sure visible
    if (!this.visible)
      return;

    // monitor...
    setTimeout(() => {
      if (this.visible)
        method();
    }, timeout);
  }

  checkForExistingConnector = () => {
    window.roFolderWall.LaunchApplication(launchInstaller, Consts.SYSTEM.MIN_INSTALLER_VERSION, () => {
      // found
      console.log("Installer found...")
      this.setState({
        installerMode: installerWaitingInstalledConnector
      }, () => {
        setTimeout(() => {
          this.checkConnector();
        }, 1000);
      })
    }, (error) => {
      console.log("No installer found, error: %s", error)
      setTimeout(() => {
        this.checkConnector();
      }, 1000);
    });
  }

  checkConnector = () => {
    // try and connect... if fails, then try again until connected
    this.setState({
      installerMode: this.state.installerMode === installerWaitingInstalledConnector ? installerWaitingInstalledConnector : this.state.installerMode === installerWaitingConnector ? installerWaitingConnector : installerDownloadConnector
    });
    window.roDMUniversalInstaller.CheckServiceConnection(() => {
      console.log("Connector connected")
      this.runInstallation();
    }, () => {
      this.props.setLoader(false);
      console.log("Connector connection not found, waiting")

      // wait again
      if (this.state.installerMode !== installerComplete)  // under dev, install can be flagged complete
        this.waitOnEvent(this.checkConnector, 1000);
    });
  }

  downloadConnector = () => {
    this.setState({
      installerMode: installerWaitingConnector
    });
  }

  runInstallation = () => {
    console.log("Running installer...");

    // first add some user defined vars
    let userDefinedVarsNames = "";
    let userDefinedVarsValues = "";
    if (this.props.userDefinedVarsNames && this.props.userDefinedVarsValues) {
      userDefinedVarsNames = this.props.userDefinedVarsNames;
      userDefinedVarsValues = this.props.userDefinedVarsValues;
    } else {
      userDefinedVarsNames = "USER_ID;USER_EMAIL";
      userDefinedVarsValues = this.props.getAuthContext().state.uid + ";" + this.props.getAuthContext().state.email;
    }
    window.roDMUniversalInstaller.AddUserDefinedVar(userDefinedVarsNames, userDefinedVarsValues, () => {
      // run installer
      window.roDMUniversalInstaller.InstallPackage(getWebAppValue(Consts.WEB_APP_VALUE.FULL_NAME), getWebAppValue(Consts.WEB_APP_VALUE.INSTALL_PACKAGE), (installerId) => {
      if (installerId) {
        console.log("Installer starting, id: %s", installerId);
        this.setState({
          installerMode: installerRunning,
          installerId: installerId
        }, () => {
              this.checkInstallation();
        })
      } else {
        // we did not get an id... installer could already be running
        console.log("We did not obtain an installer id... installer could already be running");
      }
      }, (error) => {
      // error??
      console.log("Error running installer, try again?")
        this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Installer error", "There was an error running the installer, try again?", () => {
          this.checkConnector();
        });
    });
    }, (error) => {
      // error??
      console.log("Error adding user defined vars?")
      this.props.displayDialog(Consts.MESSAGE_TYPE.CONFIRM, "Installer error", "There was an error running the installer, try again?", () => {
        this.checkConnector();
      });
    });
  }

  checkInstallation = () => {
    // check for connection
    window.roDMUniversalInstaller.GetProgressState(this.state.installerId, (status, progress, complete, error) => {
      this.setState({
        progress: !complete ? status + ", " + progress + "% complete..." : error ? "Installation failed!" : "Installation complete!",
        status: status,
        error: error
      }, () => {
        if (!complete) {
          this.waitOnEvent(this.checkInstallation, 500);
        } else {
          this.completeInstallation();
        }
      })
    }, () => {
      // installer gone
      this.setState({
        error: true,
        status: "Installer is not longer available, it may have been manually closed!",
        installerMode: installerCompleteError
      }, () => {
        console.log(this.state);
        this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "Installer error", "Error: " + this.state.status + " - Installer id: " + this.state.installerId);
      });
    });
  }

  completeInstallation = () => {
    window.roDMUniversalInstaller.InstallComplete(this.state.installerId, (status, error, logTrace) => {
      this.setState({
        progress: status,
        error: error,
        logTrace: logTrace,
        installerMode: error ? installerCompleteError : installerComplete
      }, () => {
        console.log("Installation complete -> %o", this.state);
      });

      if (error) {
        console.log(this.state);
        this.props.displayDialog(Consts.MESSAGE_TYPE.ERROR, "Installer error", "Error: " + status + " - Installer id: " + this.state.installerId);
      }
    }, () => {
      // error
    })
  }

  closeDialog = () => {
    if (!this.props.onClose)
      return;
    this.props.onClose(this.state.installerMode === installerComplete);
  }

  startConnectorDownload = () => {
    axios({
      url: getWebAppValue(Consts.WEB_APP_VALUE.DMUI),
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', 'file.exe'); //or any other extension
       document.body.appendChild(link);
       link.click();
    });
  }

  render = () => {
    const closeBtn = <button className="close" onClick={this.closeDialog}>&times;</button>;
    return (
      <React.Fragment>
        <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
        <Modal className="installer-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
          <ModalHeader close={closeBtn}>
            <Row>
              <Col xs={12}>
                <h3 className="text-info" style={{ marginTop: "15px" }}>{getWebAppValue(Consts.WEB_APP_VALUE.FULL_NAME)} installer</h3>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody id="classic-modal-slide-description">
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <Switch condition={this.state.installerMode}>
                      <Case value={installerConnectorCheck}>
                        <h5 className="text-info">
                          We are checking the existing {getWebAppValue(Consts.WEB_APP_VALUE.NAME)} installer to see if it is the latest version<br/>
                          <small className="text-info">Minimum installer version {Consts.SYSTEM.MIN_INSTALLER_VERSION}</small>
                        </h5>
                        <Loadable active={true} spinner text="Checking for an existing installer..." styles={{ overlay: (base) => ({ ...base, background: '#27293D' }) }}>
                          <div style={{ height: "150px" }}></div>
                        </Loadable>
                      </Case>
                      <Case value={installerWaitingInstalledConnector}>
                        <h5 className="text-info">{getWebAppValue(Consts.WEB_APP_VALUE.FULL_NAME)} installer found.</h5>
                        <h5 className="text-danger">Important - You will be prompted to elevate the installer, please do so.</h5>
                        <h5 className="text-info">If there is an issue starting the installer, <a href={getWebAppValue(Consts.WEB_APP_VALUE.DMUI)} className="text-info" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer" download>click here to manually download the {getWebAppValue(Consts.WEB_APP_VALUE.NAME)} installer (exe format)</a></h5>
                        <h5 className="text-info">If downloading an "exe" is not allowed, <a href={getWebAppValue(Consts.WEB_APP_VALUE.DMUI_ZIP)} className="text-info" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer" download>you can download the {getWebAppValue(Consts.WEB_APP_VALUE.NAME)} installer zip file</a></h5>
                        <Loadable active={true} spinner text="Waiting on the installer to run..." styles={{ overlay: (base) => ({ ...base, background: '#27293D' }) }}>
                          <div style={{ height: "150px" }}></div>
                        </Loadable>
                      </Case>
                      <Case value={installerDownloadConnector}>
                        <h5 className="text-info">Click the "Download" button to download the {getWebAppValue(Consts.WEB_APP_VALUE.FULL_NAME)} installer.</h5>
                        <h5 className="text-info">If you are unable to download and run an "exe" file, <a href={getWebAppValue(Consts.WEB_APP_VALUE.DMUI_ZIP)} className="text-info" style={{ textDecoration: "underline" }} onClick={(e) => this.downloadConnector() } target="_blank" rel="noopener noreferrer" download>you can download the {getWebAppValue(Consts.WEB_APP_VALUE.NAME)} installer as a zip file.</a></h5>
                        <h5 className="text-info">If you have manually downloaded and run the install, <Link to="#" className="text-info" style={{ textDecoration: "underline" }} onClick={(e) => this.downloadConnector() }>skip this step.</Link></h5>
                        <h5 className="text-danger">Important - The installer requires elevate privileges.</h5>
                      </Case>
                      <Case value={installerWaitingConnector}>
                      <h5 className="text-info">Waiting on the {getWebAppValue(Consts.WEB_APP_VALUE.FULL_NAME)} installer to run.</h5>
                        <h5 className="text-info">Once downloaded, run the installer to start the {getWebAppValue(Consts.WEB_APP_VALUE.NAME)} installation process. See a video on <a href="https://www.youtube.com/watch?v=w3YIRIP1rrk&t=2s" className="text-info" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">installing {getWebAppValue(Consts.WEB_APP_VALUE.NAME)}</a></h5>
                        <h5 className="text-danger">Important - You will be prompted to elevate the installer, please do so.</h5>
                        <h5 className="text-danger">If the installer starts on your computer, but you are still waiting here, <a href="https://support.division-m.com/hc/en-us/requests/new" className="text-danger" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">contact support</a></h5>
                        <Loadable active={true} spinner text="Waiting on the installer to run..." styles={{ overlay: (base) => ({ ...base, background: '#27293D' })}}>
                          <div style={{ height: "150px" }}></div>
                        </Loadable>
                        {!Consts.SYSTEM.RELEASE ?
                          <Button onClick={(e) => this.setState({ installerMode: installerComplete })}>Simulate (DEV)</Button> : null
                        }
                      </Case>
                      <Case value={installerRunning}>
                        <h5 className="text-info">Installing, please wait...</h5>
                        <Loadable active={true} spinner text={this.state.progress} styles={{ overlay: (base) => ({ ...base, background: '#27293D' }) }}>
                          <div style={{ height: "150px" }}></div>
                        </Loadable>
                      </Case>
                      <Case value={installerComplete}>
                        <h5 className="text-info">Installation complete!</h5>
                        <h5 className="text-info">{this.state.status}<br/>To get started, check out the <a href="https://support.division-m.com/hc/en-us/articles/360000861716" className="text-info" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">Getting started with {getWebAppValue(Consts.WEB_APP_VALUE.NAME)}</a> guide.</h5>
                        <h5 className="text-muted">If for some reason there is an issue, see the <a href="http://support.division-m.com" className="text-muted" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">Troubleshooting {getWebAppValue(Consts.WEB_APP_VALUE.NAME)} installation issues</a></h5>
                      </Case>
                      <Case value={installerCompleteError}>
                        <h5 className="text-info">Installation failed!</h5>
                        <h5 className="text-danger">The installation failed with the error: <strong>{this.state.status}</strong></h5>
                        <h5 className="text-muted">If for some reason there is an issue, see the <a href="http://support.division-m.com" className="text-muted" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">Troubleshooting {getWebAppValue(Consts.WEB_APP_VALUE.NAME)} installation issues</a></h5>
                      </Case>
                    </Switch>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right">
              <Button color={this.state.installerMode < installerComplete ? "danger" : "secondary"} onClick={(e) => this.closeDialog()}>{this.state.installerMode < installerComplete ? "Cancel" : "Done"}</Button>
              {this.state.installerMode === installerDownloadConnector ?
                <React.Fragment>
                  &nbsp;&nbsp;<a className="btn btn-secondary" href={getWebAppValue(Consts.WEB_APP_VALUE.DMUI)} onClick={(e) => this.downloadConnector() }>Download</a>
                </React.Fragment>
              : null}
            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}