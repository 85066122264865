import React from "react";
import { Button } from "reactstrap";
import { SelectFilter } from "components/common/select-filter.jsx";
import Switch, { Case } from "react-switch-case";

export class EditRuleAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      access: this.props.rule.access,
      defaultValue: null,
      modified: false,
      closed: false,
      accessList: [
        { label: "Full access, allow listing, reads and writes", value: "[L+R+W+]" },
        { label: "Read only, allow listing and reads, no writes", value: "[L+R+W-]" },
        { label: "Listing only, no file reads or writes", value: "[L+R-W-]" },
        { label: "No access at all, no file listing, reads or writes", value: "[L-R-W-]" },
        { label: "Read only access, prompt the user to allow writes in realtime", value: "[L+R+W?]" },
        { label: "Read only access, immutable writes (files written once, cannot be modified)", value: "[L+R+W1]" }
      ]
    }
  }

  componentWillMount() {
    // determine initial value
    this.state.accessList.forEach((a) => {
      if (a.value === this.state.access) {
        this.setState({
          defaultValue: a
        })
      }
    })
  }

  handleChanged = (newValue) => {
    this.setState({
      access: newValue,
      modified: true
    }, () => {
        if (this.props.onChange)
          this.props.onChange(newValue);
    });
  };

  updateRule = (save) => {
    if (save) {
      let rule = this.props.rule;
      rule.access = this.state.access;
      this.setState({
        rule: rule,
        closed: true
      }, () => {
        this.props.onClose(rule);
      })
    } else {
      this.props.onClose(null);
    }
  }

  render = () => {
    if (this.state.closed)  // we were getting a setstate error from parent when it was hidding this... lets do it here just before... issue seems to be resolved
      return null;

    return (
      <React.Fragment>
        <div>
          <SelectFilter readonly={true} options={this.state.accessList} defaultValue={this.state.defaultValue} onChange={(newValue) => { this.handleChanged(newValue) }} placeHolder={this.state.listAction} />
        </div>
        <div className="text-right">
          <Switch condition={!this.props.onChange}>
            <Case value={true}>
              <Switch condition={this.state.modified}>
                <Case value={true}>
                  <Button color="secondary" onClick={() => { this.updateRule(false) }}>Cancel</Button>
                  <Button color="info" onClick={() => { this.updateRule(true) }}>
                    Update rule
                  </Button>
                </Case>
                <Case value={false}>
                  <Button color="secondary" onClick={() => { this.updateRule(false) }}>Cancel</Button>
                </Case>
              </Switch>
            </Case>
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}